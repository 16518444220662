import React from 'react';
import { Row } from 'react-bootstrap';
import { Playoff } from 'labb/types';
import { useTranslation } from 'react-i18next';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { CoachLink, TeamLink, Caption, LabbTable } from 'shared/components';

type Props = {
    items: Playoff[];
};

const PlayoffListItem = ({ items }: Props) => {
    const { t } = useTranslation('leagues');

    const gameColumns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'game_id',
            hidden: true,
        },
        {
            dataField: 'gamename',
            text: t('match'),
        },
        {
            dataField: 'teamUrl1',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t1.team.id} teamName={row.t1.team.name} />
            ),
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t1.coach.email} coachName={row.t1.coach.name} />
            ),
        },
        {
            dataField: 't1.td',
            text: t('td'),
            headerAlign: 'center',
            align: 'center',
        },
        {
            dataField: 't1.cas',
            text: t('cas'),
        },
        {
            dataField: 'teamUrl2',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t2.team.id} teamName={row.t2.team.name} />
            ),
        },
        {
            dataField: 'coachUrl2',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t2.coach.email} coachName={row.t2.coach.name} />
            ),
        },
        {
            dataField: 't2.td',
            text: t('td'),
        },
        {
            dataField: 't2.cas',
            text: t('cas'),
        },
    ];

    if (!items || items.length < 1) return null;

    return (
        <Row className="p-3 d-flex flex-column">
            <h3 className="font-bb text-center mb-3">{t('playoffs')}</h3>
            {items.map((item: Playoff) => (
                <LabbTable
                    key={item.id}
                    caption={<Caption text={item.name} />}
                    keyField="id"
                    data={item.games}
                    columns={gameColumns}
                />
            ))}
        </Row>
    );
};

export default PlayoffListItem;
