import React, { useMemo, useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAuth } from 'context/auth-context';
import { RuleItem, Headline } from 'shared/components';
import { teamInitialData, teamReducer } from './reducer';
import { fetchData } from 'labb/utils';
import {
    TeamNameRace,
    TeamPlayers,
    TeamStaff,
    TeamSummary,
    TeamModal,
    TeamManagement,
    TeamUndoHistory,
    TeamValues,
} from './components';
import {
    mapCreateFormToPost,
    calculateTeamValueAndCost,
    validateTeamCreation,
    calculateExclusives,
    loadTeamData,
    useTimeTravel,
    useRandomName,
    useTeamPositionalCount,
} from './helpers';
import { TEAM_CREATION_CONSTANTS } from './constants';

type Props = {
    edit?: boolean;
    teamId?: string;
    publicView?: boolean;
    editView?: boolean;
    createView?: boolean;
};

const TeamAdmin = ({ editView, teamId, publicView, createView }: Props) => {
    const history = useHistory();
    const { t } = useTranslation('teams');
    const auth = useAuth();
    const { state, dispatch, doUndo } = useTimeTravel(teamReducer, teamInitialData);
    const { pickRandomName } = useRandomName(state?.currentRoster?.id, state?.team?.players);
    const [modalData, setModalData] = useState({ type: null, data: null });
    const { status } = loadTeamData(teamId, editView, publicView, dispatch);
    const {
        teamValue,
        teamCost,
        currentTeamValue,
        treasury: calculatedTreasury,
    } = calculateTeamValueAndCost(state?.team, state?.currentRoster, state?.maxTeamTR);

    const positionalCount = useMemo(
        () => useTeamPositionalCount(state?.team?.players, state?.currentRoster, editView),
        [state?.team?.players, state?.currentRoster, editView]
    );

    useEffect(() => {
        if (editView && auth?.user?.code && state?.team?.coachId) {
            if (auth.user.code !== state.team.coachId) {
                history.push('/profile/teams');
            }
        }
    }, [editView, auth.user, state.team]);

    const onShowModal = (type, data) => {
        setModalData({ type, data });
    };

    const exclusivesCount = useMemo(() => {
        return calculateExclusives(state.team, state.currentRoster);
    }, [state.currentRoster, state.team]);

    const onUpdate = async () => {
        const requestBody = {
            ...state.team,
            treasury: Math.max(state.treasury, calculatedTreasury),
            teamValue,
            currentTeamValue,
        };
        const { status, data } = await fetchData(`/team/${state.team.id}`, 'put', requestBody);
        if (status === 'success') {
            toast.success(t('teamEditSuccess'));
            history.push('/profile/teams');
        } else if (status === 'error') {
            toast.error(t('teamEditError', { msg: data.msg }));
        }
    };

    const onCreate = async (): Promise<void> => {
        const validationErrors = validateTeamCreation(
            state.team,
            Math.max(state.treasury, calculatedTreasury),
            state.currentRoster
        );
        dispatch({ type: 'validate', payload: validationErrors });
        if (validationErrors.length) {
            validationErrors.map((item) => {
                toast.error(t(item, { count: TEAM_CREATION_CONSTANTS.minNumberOfPlayers }));
            });
            return;
        } else {
            const requestBody = mapCreateFormToPost(
                state.team,
                Math.max(state.treasury, calculatedTreasury),
                teamValue,
                state.maxTeamTR
            );
            const { status, data } = await fetchData(
                `/team/${auth.user.code}/`,
                'post',
                requestBody
            );
            if (status === 'success') {
                toast.success(t('creationSuccess'));
                history.push('/profile/teams');
            } else if (status === 'error') {
                toast.error(t('creationError', { msg: data.msg }));
            }
        }
    };

    if (!status) return null;

    return (
        <>
            <TeamModal
                team={state.team}
                games={state.games}
                modalData={modalData}
                setModalData={setModalData}
                currentRoster={state.currentRoster}
                dispatch={dispatch}
                exclusivesCount={exclusivesCount}
                pickRandomName={pickRandomName}
                positionalCount={positionalCount}
            />
            {createView ? (
                <TeamNameRace
                    dispatch={dispatch}
                    team={state.team}
                    rosters={state.rosters}
                    maxTeamTR={state.maxTeamTR}
                    errors={state.errors}
                />
            ) : (
                <Container fluid className="mb-1">
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col md={12}>
                            <Headline>{state.team.name}</Headline>
                        </Col>
                    </Row>
                </Container>
            )}
            {!!state.team.rosterId && (
                <>
                    <TeamPlayers
                        dispatch={dispatch}
                        players={state.team.players.filter((item) => item.status < 20)}
                        onShowModal={onShowModal}
                        currentRoster={state.currentRoster}
                        errors={state.errors}
                        editView={editView}
                        publicView={publicView}
                        createView={createView}
                        pickRandomName={pickRandomName}
                        positionalCount={positionalCount}
                    />
                    {(editView || publicView) && (
                        <Col className="text-right mb-3">
                            <Trans className="text-muted" t={t} i18nKey="infoBox">
                                infoBox_0
                            </Trans>
                        </Col>
                    )}
                    {editView && (
                        <Col className="text-center mb-3">
                            <TeamManagement onShowModal={onShowModal} />
                        </Col>
                    )}

                    <Container>
                        <Row>
                            <Col xs={12} md className="mb-3">
                                <TeamSummary
                                    dispatch={dispatch}
                                    state={state}
                                    userName={auth?.user?.usuario}
                                    editView={editView}
                                    publicView={publicView}
                                    createView={createView}
                                />
                            </Col>
                            <Col xs={12} md className="mb-3">
                                <TeamStaff
                                    dispatch={dispatch}
                                    team={state.team}
                                    currentRoster={state.currentRoster}
                                    exclusivesCount={exclusivesCount}
                                    editView={editView}
                                    publicView={publicView}
                                    createView={createView}
                                />
                            </Col>
                            <Col xs={12} md className="mb-3">
                                <TeamValues
                                    treasury={Math.max(state.treasury, calculatedTreasury)}
                                    teamCost={teamCost}
                                    teamValue={teamValue}
                                    teamCurrentValue={currentTeamValue}
                                    publicView={publicView}
                                    createView={createView}
                                />
                            </Col>
                            {editView && (
                                <>
                                    <Col xs={12} md className="mb-3">
                                        <TeamUndoHistory history={state.history} doUndo={doUndo} />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Container>
                </>
            )}
            {!publicView && (
                <Container>
                    <Row className="justify-content-end pr-5">
                        <Button
                            className="mr-3"
                            onClick={() => history.push('/profile/teams')}
                            variant="danger"
                        >
                            {t('back')}
                        </Button>
                        {!!state.team.rosterId && (
                            <Button
                                className="mr-3"
                                onClick={() => (editView ? onUpdate() : onCreate())}
                                disabled={
                                    Math.max(state.treasury, calculatedTreasury) < 0 ||
                                    state.history.length < 1
                                }
                                variant="success"
                            >
                                {t('save')}
                            </Button>
                        )}
                    </Row>
                </Container>
            )}
            {publicView && state?.team?.teamRules.length > 0 && (
                <Container className="my-3">
                    <Row className="mb-3">
                        <h3 className="font-bb text-primary">{t('rules')}</h3>
                    </Row>
                    <Row>
                        {state?.team?.teamRules?.map((rule) => (
                            <Col md={6} key={rule.id}>
                                <RuleItem title={rule.nameEs} description={rule.description} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
        </>
    );
};

export default TeamAdmin;
