import { TEAM_CREATION_CONSTANTS } from './constants';
import { teamReducerActions, TeamReducerState } from 'types';

const sortPlayersByNumber = (a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0);

export const teamInitialData: TeamReducerState = {
    history: [],
    team: {
        name: '',
        rosterId: 0,
        dedicatedFans: 1,
        rerolls: 0,
        assistants: 0,
        cheerleaders: 0,
        apothecary: false,
        players: [],
        teamRuleId: 0,
    },
    games: [],
    currentRoster: null,
    rosters: null,
    initialTeam: null,
    initialGames: [],
    treasury: TEAM_CREATION_CONSTANTS.initialTreasury,
    initialTreasury: TEAM_CREATION_CONSTANTS.initialTreasury,
    maxTeamTR: TEAM_CREATION_CONSTANTS.defaultMaxTR,
    errors: null,
};

export const teamReducer = (
    state: TeamReducerState,
    action: teamReducerActions
): TeamReducerState => {
    const updateHistory = () => [...state.history, action.type];
    switch (action.type) {
        case 'initialLoad':
            return {
                history: [],
                maxTeamTR: action.payload.maxTeamTR,
                initialTeam: action.payload.team,
                initialGames: action.payload.games,
                initialTreasury: action.payload.treasury,
                rosters: action.payload.rosters,
                errors: [],
                ...action.payload,
            };
        case 'reload': {
            return {
                ...state,
                team: action.payload.team,
                treasury: action.payload.treasury,
            };
        }
        case 'updateTeamName':
            return { ...state, team: { ...state.team, name: action.payload } };
        case 'updateTeamRoster':
            return {
                ...state,
                currentRoster: action.payload.currentRoster,
                team: {
                    ...state.team,
                    rosterId: action.payload.rosterId,
                    name: action.payload.name,
                },
            };
        case 'updateMaxTeamTR':
            return {
                ...state,
                maxTeamTR: action.payload.maxTeamTR,
            };
        case 'addPlayer': {
            const { number, name, positionalId } = action.payload;
            const updatedPlayers = [
                ...state.team.players,
                {
                    number,
                    name,
                    positionalId,
                    status: 0,
                    unspentSpp: 0,
                },
            ].sort(sortPlayersByNumber);
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, players: updatedPlayers },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removePlayer': {
            const updatedPlayers = state.team.players.filter(
                (item) => item.number !== action.payload.number
            );
            return {
                ...state,
                team: { ...state.team, players: [...updatedPlayers] },
                treasury: state.treasury + action.payload.cost,
            };
        }
        case 'firePlayer': {
            const updatedPlayers = state.team.players.filter(
                (item) => item.number !== action.payload.number
            );
            return {
                ...state,
                team: { ...state.team, players: [...updatedPlayers] },
                history: updateHistory(),
            };
        }
        case 'changePlayerName': {
            const updatedPlayers = state.team.players.map((item) => {
                if (item.number === action.payload.number) {
                    return {
                        ...item,
                        name: action.payload.name,
                    };
                }
                return { ...item };
            });
            return {
                ...state,
                team: { ...state.team, players: [...updatedPlayers] },
            };
        }

        case 'swapPlayers': {
            const [source, target] = action.payload.playersArray;
            const updatedPlayers = state.team.players
                .map((item) => {
                    const newNumber =
                        item.number === source
                            ? target
                            : item.number === target
                            ? source
                            : item.number;
                    return {
                        ...item,
                        number: newNumber,
                    };
                })
                .sort(sortPlayersByNumber);
            return {
                ...state,
                team: { ...state.team, players: [...updatedPlayers] },
                history: updateHistory(),
            };
        }
        case 'updateLeague':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, league: action.payload },
            };
        case 'addReroll': {
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, rerolls: state.team.rerolls + 1 },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removeReroll':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, rerolls: state.team.rerolls - 1 },
                treasury: state.treasury + action.payload.refund,
            };
        case 'addDedicatedFan': {
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, dedicatedFans: state.team.dedicatedFans + 1 },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removeDedicatedFan':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, dedicatedFans: state.team.dedicatedFans - 1 },
                treasury: state.treasury + action.payload.refund,
            };
        case 'addCheerleader': {
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, cheerleaders: state.team.cheerleaders + 1 },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removeCheerleader':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, cheerleaders: state.team.cheerleaders - 1 },
                treasury: state.treasury + action.payload.refund,
            };
        case 'addAssistant': {
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, assistants: state.team.assistants + 1 },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removeAssistant':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, assistants: state.team.assistants - 1 },
                treasury: state.treasury + action.payload.refund,
            };
        case 'addApothecary': {
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, apothecary: true },
                treasury: state.treasury - action.payload.cost,
            };
        }
        case 'removeApothecary':
            return {
                ...state,
                history: updateHistory(),
                team: { ...state.team, apothecary: false },
                treasury: state.treasury + action.payload.refund,
            };
        case 'selectTeamRule':
            return { ...state, team: { ...state.team, teamRuleId: action.payload } };
        case 'validate':
            return { ...state, errors: action.payload };
        case 'reset':
            return {
                ...teamInitialData,
                rosters: state.rosters,
            };
        default:
            return { ...state };
    }
};
