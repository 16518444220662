import React, { useState, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdReorder as OrderIcon, MdAssignment, MdEdit, MdList } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { CoachLink, LabbTable, Caption } from 'shared/components';
import { fetchData } from 'labb/utils';
import EditLeagueModal from './EditLeagueModal';
import { LEAGUE_STATUS } from './index';
import { League } from 'labb/types';
import TeamListModal from './TeamListModal';

type Props = {
    items: League[];
    isComissioner: boolean;
    onlySummary?: boolean;
    handleDataChange?: (arg) => void;
    caption?: string;
    classNames?: string;
};

const LeaguesListItem = ({
    items,
    onlySummary,
    isComissioner,
    handleDataChange,
    caption,
}: Props) => {
    const { t } = useTranslation('leagues');
    const history = useHistory();

    const [showEditLeagueModal, setShowEditLeagueModal] = useState(false);
    const [leagueSelected, setLeagueSelected] = useState(null);
    const [showTeamListModal, setShowTeamListModal] = useState(false);
    const [teamList, setTeamList] = useState(null);

    const typeToText = (type: number) => {
        switch (type) {
            case 0:
                return t('regularOnly');
            case 10:
                return t('regularAndPlayoff');
            case 20:
                return t('playoffOnly');
            default:
                return '';
        }
    };

    const renderLeagueLink = (id: number, name: string) => {
        return <Link to={`/leagues/${id}`}>{name}</Link>;
    };

    const renderSpots = (currentTeams: number, maxTeams: number) => {
        return `${currentTeams}/${maxTeams}`;
    };

    useEffect(() => {
        if (leagueSelected) {
            setShowEditLeagueModal(true);
        } else if (teamList && teamList.length > 0) {
            setShowTeamListModal(true);
        }
    }, [leagueSelected, teamList]);

    const onShowEditLeagueModal = (row) => {
        setLeagueSelected(row);
    };

    const onHideEditLeagueModal = () => {
        setShowEditLeagueModal(false);
        setLeagueSelected(null);
    };

    const fetchTeamList = async (leagueId) => {
        const response = await fetchData(`/league/${leagueId}/teams`);
        setTeamList(response?.data?.teams);
    };

    const onShowTeamListModal = (row) => {
        fetchTeamList(row.id);
    };

    const onHideTeamListModal = () => {
        setShowTeamListModal(false);
        setTeamList([]);
    };

    const onSuccessEditLeagueModal = (leaguesReponse: any) => {
        onHideEditLeagueModal();
        if (onlySummary) {
            handleDataChange(leaguesReponse);
        }
    };

    const onDeleteEditLeagueModal = async (id: number) => {
        if (!confirm(t('sureDeleteLeague?'))) {
            return;
        }
        const { status, data } = await fetchData(`/league/${id}`, 'delete');
        if (status === 'success') {
            toast.success(t('deleteLeagueSuccess'));
            onHideEditLeagueModal();
            if (onlySummary) {
                handleDataChange(data.leagues);
            } else {
                history.push('/leagues/'); // get out of the detail view as we just deleted the league
            }
        } else if (status === 'error') {
            toast.error(t('deleteLeagueError', { msg: data.msg }));
        }
    };

    const EditLeagueModalProps = {
        show: showEditLeagueModal,
        league: leagueSelected,
        onHide: onHideEditLeagueModal,
        onSuccess: onSuccessEditLeagueModal,
        onDelete: onDeleteEditLeagueModal,
    };

    const TeamListModalProps = {
        show: showTeamListModal,
        teams: teamList,
        onHide: onHideTeamListModal,
    };

    const renderActions = (row) => {
        return (
            <Row className="d-flex flex-nowrap justify-content-center mx-4">
                <Button
                    className="border-0"
                    size="sm"
                    onClick={() => onShowTeamListModal(row)}
                    variant="outline-primary"
                    title={t('teamList')}
                >
                    <MdList />
                </Button>
                {isComissioner && (
                    <>
                        <Button
                            className="border-0"
                            size="sm"
                            onClick={() => onShowEditLeagueModal(row)}
                            variant="outline-warning"
                            title={t('edit')}
                        >
                            <MdEdit />
                        </Button>
                        <Button
                            className="border-0"
                            size="sm"
                            onClick={() => history.push(`/leagues/${row.id}/draw`)}
                            variant="outline-primary"
                            title={t('openDraw')}
                        >
                            <OrderIcon />
                        </Button>
                    </>
                )}
                <Button
                    className="border-0"
                    size="sm"
                    onClick={() => history.push(`/leagues/${row.id}/fixtures`)}
                    variant="outline-primary"
                    title={t('openFixtures')}
                >
                    <MdAssignment />
                </Button>
            </Row>
        );
    };

    const renderLeagueStatus = (_cell, row) => <span>{t(LEAGUE_STATUS[row.status])}</span>;

    const leagueColumns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'nameUrl',
            text: t('name'),
            classes: 'text-nowrap',
            formatter: (_cell, row) => renderLeagueLink(row.id, row.name),
        },
        {
            dataField: 'comissioner',
            text: t('comissioner'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.comissionerEmail} coachName={row.comissionerName} />
            ),
        },
        {
            dataField: 'spotsOcuppied',
            text: t('spots'),
            formatter: (_cell, row) => renderSpots(row.currentTeams, row.maxTeams),
        },
        {
            dataField: 'description',
            text: t('description'),
        },
        {
            dataField: 'state',
            text: t('state'),
            formatter: renderLeagueStatus,
        },
        {
            dataField: 'type',
            text: t('type'),
            formatter: (_cell, row) => typeToText(row.type),
        },
        ...(!onlySummary
            ? [
                  {
                      dataField: 'cvmin',
                      text: t('cvmin'),
                  },
                  {
                      dataField: 'cvmax',
                      text: t('cvmax'),
                  },
                  {
                      dataField: 'ptwin',
                      text: t('ptwin'),
                  },
                  {
                      dataField: 'ptloss',
                      text: t('ptloss'),
                  },
                  {
                      dataField: 'ptdraw',
                      text: t('ptdraw'),
                  },
                  {
                      dataField: 'rulesVersionId',
                      text: 'rulesVersionId',
                      hidden: true,
                  },
                  {
                      dataField: 'rulesVersion',
                      text: t('rules'),
                  },
              ]
            : []),
        {
            dataField: 'actions',
            text: t('Actions'),
            isDummyField: true,
            formatter: (_cell, row) => renderActions(row),
        },
    ];

    return (
        <>
            <EditLeagueModal {...EditLeagueModalProps} />
            <TeamListModal {...TeamListModalProps} />
            <LabbTable
                keyField="id"
                data={items}
                columns={leagueColumns}
                caption={<Caption text={caption} />}
                classes="table-fixed"
            />
        </>
    );
};

export default LeaguesListItem;
