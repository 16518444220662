import { TeamState, Roster } from 'types';

const calculateExclusives = (team: TeamState, currentRoster: Roster): number => {
    if (!currentRoster || !team) return 0;
    const exclusivesInRoster = currentRoster.positionals
        .filter((item) => item.positional.exclusive)
        .map((item) => item.positional.id);

    return team.players
        .filter((item) => item.status < 20)
        .filter((player) => exclusivesInRoster.includes(player.positionalId)).length;
};

export default calculateExclusives;
