import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { LabbTable, Caption } from 'shared/components';
import { Roster } from 'labb/types';

type Props = {
    item: Roster;
};

const RostersItem = ({ item }: Props) => {
    const { t } = useTranslation('rosters');

    const rosterColumns: ColumnDescription[] = [
        {
            dataField: 'positional.id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'max',
            text: t('amount'),
        },
        {
            dataField: 'positional.nameEs',
            text: t('position'),
        },
        {
            dataField: 'positional.cost',
            text: t('cost'),
        },
        {
            dataField: 'positional.ma',
            text: t('MA'),
        },
        {
            dataField: 'positional.st',
            text: t('ST'),
        },
        {
            dataField: 'positional.ag',
            text: t('AG'),
        },
        {
            dataField: 'positional.pa',
            text: t('PA'),
        },
        {
            dataField: 'positional.av',
            text: t('AV'),
        },
        {
            dataField: 'positional.exclusive',
            text: t('exclusive'),
            formatter: (cell) => {
                return cell ? '♦ ' : '';
            },
        },
        {
            dataField: 'positional.lowcost',
            text: t('lowcost'),
            formatter: (cell) => {
                return cell ? '¤ ' : '';
            },
        },
        {
            dataField: 'positional.skills',
            text: t('skills'),
            formatter: (cell) => {
                if (!cell?.length) return '';
                return cell.map((item) => item.nameEs).join(', ');
            },
        },
        {
            dataField: 'positional.primary',
            text: t('primary'),
        },
        {
            dataField: 'positional.secondary',
            text: t('secondary'),
        },
    ];

    return (
        <LabbTable
            caption={<Caption text={item.nameEs} />}
            keyField="positional.id"
            data={item.positionals}
            columns={rosterColumns}
        >
            <Card.Footer>
                <ListGroup
                    horizontal="sm"
                    className="list-group-striped w-100 horizontal justify-content-center"
                >
                    <ListGroup.Item>
                        {t('reRoll', { max: item.maxRr, amount: item.rr })}
                    </ListGroup.Item>
                    <ListGroup.Item>{t('tier', { amount: item.tier })}</ListGroup.Item>
                    <ListGroup.Item>
                        {t('specialRules', {
                            rules: item.rules?.map(({ nameEs }) => nameEs).join(', '),
                        })}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        {t('medic', {
                            val: item.apothecary ? t('yes') : t('no'),
                        })}
                    </ListGroup.Item>
                </ListGroup>
            </Card.Footer>
        </LabbTable>
    );
};

export default RostersItem;
