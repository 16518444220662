import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Col, Row } from 'react-bootstrap';
import FixtureInput, { Props as FixtureInputProps } from './FixtureInput';
import { GAME_ONE_PLAYER_T1, GAME_ONE_PLAYER_T2 } from '.';

type Props = {
    df: { t1: number; t2: number };
    updateWinnings: any;
    winnings: { t1: number; t2: number };
    handleChange: any;
    fixtureData: any;
    values: any;
    errors: any;
    touched: any;
    autoComment: string;
    comissionerHandling: boolean;
    treasury: { t1: number; t2: number };
    freePositionals: { t1: boolean; t2: boolean };
};

const FixtureCommonData = ({
    df,
    updateWinnings,
    winnings,
    fixtureData,
    handleChange,
    values,
    touched,
    errors,
    autoComment,
    comissionerHandling,
    treasury,
    freePositionals,
}: Props) => {
    const { t } = useTranslation('fixtures');

    useEffect(() => {
        updateWinnings(
            values.team1.ff,
            values.team2.ff,
            values.team1.td,
            values.team2.td,
            values.type || fixtureData.game.type
        );
    }, [values.team1.ff, values.team2.ff, values.team1.td, values.team2.td, values.type]);

    const handleChangeWithLimit = (event) => {
        const value = Number(event.target.value);
        const max = Number(event.target.max);
        if (value > max) {
            event.target.value = event.target.max;
        }
        handleChange(event);
    };

    if (!values.team1 || !values.team2) return null;

    const inputs: FixtureInputProps[] = [
        {
            type: 'number',
            label: t('TDteam', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.td',
            value: values.team1.td,
            touched: touched.team1?.td,
            errors: errors.team1?.td,
            disabled:
                (fixtureData.closedRecord ||
                    fixtureData.game.type === GAME_ONE_PLAYER_T2 ||
                    values.type === GAME_ONE_PLAYER_T2) &&
                !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('TDteam', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.td',
            value: values.team2.td,
            touched: touched.team2?.td,
            errors: errors.team2?.td,
            disabled:
                (fixtureData.closedRecord ||
                    fixtureData.game.type === GAME_ONE_PLAYER_T1 ||
                    values.type === GAME_ONE_PLAYER_T1) &&
                !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('CASteam', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.cas',
            value: values.team1.cas,
            touched: touched.team1?.cas,
            errors: errors.team1?.cas,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('CASteam', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.cas',
            value: values.team2.cas,
            touched: touched.team2?.cas,
            errors: errors.team2?.cas,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('FFteam', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.ff',
            value: values.team1.ff,
            touched: touched.team1?.ff,
            errors: errors.team1?.ff,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('FFteam', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.ff',
            value: values.team2.ff,
            touched: touched.team2?.ff,
            errors: errors.team2?.ff,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('TreasurySpentTeam', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.treasurySpent',
            value: values.team1.treasurySpent,
            touched: touched.team1?.treasurySpent,
            errors: errors.team1?.treasurySpent,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange: handleChangeWithLimit,
            max: treasury.t1,
        },
        {
            type: 'number',
            label: t('TreasurySpentTeam', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.treasurySpent',
            value: values.team2.treasurySpent,
            touched: touched.team2?.treasurySpent,
            errors: errors.team2?.treasurySpent,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange: handleChangeWithLimit,
            max: treasury.t2,
        },
        {
            type: 'number',
            label: t('WinningsTeam', {
                team: fixtureData.team1.name,
            }),
            name: 'winnings1',
            value: winnings.t1,
            disabled: true,
        },
        {
            type: 'number',
            label: t('WinningsTeam', {
                team: fixtureData.team2.name,
            }),
            name: 'winnings2',
            value: winnings.t2,
            disabled: true,
        },
        {
            type: 'number',
            label: t('TVteam', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.tv',
            value: values.team1.tv,
            touched: touched.team1?.tv,
            errors: errors.team1?.tv,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('TVteam', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.tv',
            value: values.team2.tv,
            touched: touched.team2?.tv,
            errors: errors.team2?.tv,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
        },
        {
            type: 'number',
            label: t('DFteam', {
                team: fixtureData.team1.name,
            }),
            name: 'df1',
            value: df.t1,
            disabled: true,
        },
        {
            type: 'number',
            label: t('DFteam', {
                team: fixtureData.team2.name,
            }),
            name: 'df2',
            value: df.t2,
            disabled: true,
        },
        {
            type: 'text',
            label: t('freePositional', {
                team: fixtureData.team1.name,
            }),
            name: 'team1.freePositionalName',
            value: values.team1.freePositionalName,
            touched: touched.team1?.freePositionalName,
            errors: errors.team1?.freePositionalName,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
            hidden: !freePositionals.t1,
        },
        {
            type: 'text',
            label: t('freePositional', {
                team: fixtureData.team2.name,
            }),
            name: 'team2.freePositionalName',
            value: values.team2.freePositionalName,
            touched: touched.team2?.freePositionalName,
            errors: errors.team2?.freePositionalName,
            disabled: fixtureData.closedRecord && !comissionerHandling,
            handleChange,
            hidden: !freePositionals.t2,
        },
    ];

    return (
        <Card className="mb-3">
            <Card.Header className="font-weight-bold bg-light-blue">{t('commonData')}</Card.Header>
            <Card.Body className="bg-grey">
                <Row>
                    {inputs.map((inputProps) => (
                        <Col key={inputProps.name} md={6}>
                            <FixtureInput {...inputProps} />
                        </Col>
                    ))}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Form.Group as={Col}>
                    <Form.Label className="font-weight-bold">{t('autoComment')}</Form.Label>
                    <Form.Control
                        name="autoComment"
                        as="textarea"
                        autoComplete="off"
                        value={autoComment}
                        readOnly
                    />
                </Form.Group>
            </Card.Footer>
        </Card>
    );
};

export default FixtureCommonData;
