import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

const Instructions = () => {
    const { t } = useTranslation('fixtures');
    return (
        <Accordion className="cursor-pointer">
            <Card>
                <Accordion.Toggle className="text-primary" as={Card.Header} eventKey="0">
                    {t('instructions')}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Trans t={t} i18nKey="howtoGeneral">
                            howtoGeneral_0
                            <ol>
                                <li>howtoGeneral_1</li>
                                <li>howtoGeneral_2</li>
                                <li>howtoGeneral_3</li>
                                <li>howtoGeneral_4</li>
                                <li>howtoGeneral_5</li>
                                <li>howtoGeneral_6</li>
                            </ol>
                        </Trans>
                        <Trans t={t} i18nKey="howtoBothPlay" />
                        <Trans t={t} i18nKey="howtoConcede">
                            howtoConcede_0
                            <ol>
                                <li>howtoConcede_1</li>
                                <li>howtoConcede_2</li>
                                <li>howtoConcede_3</li>
                                <li>howtoConcede_4</li>
                                <li>howtoConcede_5</li>
                            </ol>
                        </Trans>
                        <Trans t={t} i18nKey="howtoGeneralGuideline">
                            howtoGeneralGuideline_0
                            <ol>
                                <li>howtoGeneralGuideline_1</li>
                                <li>howtoGeneralGuideline_2</li>
                                <li>howtoGeneralGuideline_3</li>
                                <li>howtoGeneralGuideline_4</li>
                                <li>howtoGeneralGuideline_5</li>
                            </ol>
                        </Trans>
                        <Trans t={t} i18nKey="howtoSpp">
                            howtoSpp_0
                            <ul>
                                <li>howtoSpp_1</li>
                                <li>howtoSpp_2</li>
                                <li>howtoSpp_3</li>
                                <li>howtoSpp_4</li>
                                <li>howtoSpp_5</li>
                                <li>howtoSpp_6</li>
                            </ul>
                        </Trans>
                        <Trans t={t} i18nKey="howtoAutomaticSummary">
                            howtoAutomaticSummary_0
                            <ul>
                                <li>howtoAutomaticSummary_1</li>
                                <li>howtoAutomaticSummary_2</li>
                                <li>howtoAutomaticSummary_3</li>
                                <li>howtoAutomaticSummary_4</li>
                                <li>howtoAutomaticSummary_5</li>
                                <li>howtoAutomaticSummary_6</li>
                            </ul>
                        </Trans>
                        <Trans t={t} i18nKey="howtoFreePositional" />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default Instructions;
