import React, { useState, useEffect } from 'react';
import { Container, Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Game } from 'labb/types';
import { FixturesView, LabbTable } from 'shared/components';
import { fetchData } from 'labb/utils';
import { ColumnDescription } from 'react-bootstrap-table-next';

type Props = {
    teamId: number;
    games: Game[];
    onHide: () => void;
};

const TeamRecordsModal = ({ teamId, games, onHide }: Props) => {
    const { t } = useTranslation(['teams', 'stats']);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        const { data } = await fetchData(`/statistic/team/${teamId}`);
        if (data.stats) {
            setStats(data.stats);
        }
    };

    const playerColumns: ColumnDescription[] = [
        {
            dataField: 'count',
            text: t('total'),
        },
        {
            dataField: 'player.name',
            text: t('player'),
        },
    ];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('teamRecords')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <FixturesView games={games} title={t('matchesPlayed')} />
                </Container>
                <Container>
                    <Row>
                        <Col>
                            {!!stats && (
                                <Row className="mb-5">
                                    {Object.keys(stats).map((item) => {
                                        if (!stats[item]?.length) return null;
                                        return (
                                            <Col
                                                key={item}
                                                md={6}
                                                xl={4}
                                                className="m-auto text-center"
                                            >
                                                <LabbTable
                                                    caption={
                                                        <h5 className="text-primary font-bb">
                                                            {t(`players.${item}`)}
                                                        </h5>
                                                    }
                                                    keyField="player.id"
                                                    data={stats[item]}
                                                    columns={playerColumns}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
                {
                    // TODO add perhaps in the future winners from league?
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    {t('back')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default TeamRecordsModal;
