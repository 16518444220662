import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, Image, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useAuth } from 'context/auth-context';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo.png';
import TokenStorage from 'labb/utils/tokenStorage';

const NavbarComponent = () => {
    const auth = useAuth();
    const history = useHistory();
    const { t } = useTranslation('nav');

    const handleSessionClose = () => {
        auth.logout?.();
        TokenStorage.clear();
        history.push('/login');
    };

    return (
        <Navbar bg="primary" variant="dark" expand="md">
            <Container fluid className="container-xxl">
                <Navbar.Brand as={Link} to="/">
                    <Image src={logo} height="40px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link active as={Link} to="/">
                            {t('main')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/leagues">
                            {t('leagues')}
                        </Nav.Link>
                        {auth.isUser && (
                            <NavDropdown title={t('coach')} id="profile-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/profile">
                                    {t('profile')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/profile/teams">
                                    {t('teams')}
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        <NavDropdown title={t('statistics')} id="profile-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/stats/coach">
                                {t('coaches')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/stats/league">
                                {t('leagues')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/stats/roster">
                                {t('rosters')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/stats/player">
                                {t('players')}
                            </NavDropdown.Item>
                        </NavDropdown>
                        {auth.isComissioner && (
                            <Nav.Link as={Link} to="/comissioner">
                                {t('comissioner')}
                            </Nav.Link>
                        )}
                        <NavDropdown title={t('help')} id="leagues-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/rules">
                                {t('rules')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/rosters">
                                {t('rosters')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tutorial">
                                {t('tutorial')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/downloads">
                                {t('downloads')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/terms">
                                {t('terms')}
                            </NavDropdown.Item>
                        </NavDropdown>
                        {auth.isComissioner && (
                            <Nav.Link href="/old/index.php">{t('labb3')}</Nav.Link>
                        )}
                        {auth.isUser ? (
                            <Nav.Link onClick={handleSessionClose}>{t('logout')}</Nav.Link>
                        ) : (
                            <Nav.Link as={Link} to="/login">
                                {t('login')}
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
