import React from 'react';
import { Container, Row } from 'react-bootstrap';
import useVersion from './use-version';

const Footer = () => {
    const version = useVersion();
    return (
        <Container
            fluid
            className="mt-auto navbar footer border-top p-4 bg-light justify-content-center"
        >
            <Row className="d-flex flex-column text-center">
                <div>{version}</div>
                <div>[FranFactory] [Muki&DarthBLU] [dotneko]</div>
            </Row>
        </Container>
    );
};

export default Footer;
