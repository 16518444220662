import React from 'react';
import { chunk } from 'underscore';
import { Card, Pagination, Row } from 'react-bootstrap';
import Carousel from 'nuka-carousel';
import WinnersSlide from '../WinnersSlide';
import GamesSlide from '../GamesSlide';
import PlayersSlides from '../PlayersSlides';
import './style.scss';

const StatisticsCarousel: React.FC<{
    title?: string;
    items: any;
    itemsPerSlide?: number;
    type: 'winners' | 'games' | 'players';
    disclaimer?: string;
}> = ({ items, itemsPerSlide = 1, type, title, disclaimer }) => {
    if (!items?.length) return null;

    const slides = chunk(items, itemsPerSlide);

    const renderSlides = (slide, index) => {
        switch (type) {
            case 'winners':
                return <WinnersSlide key={index} slide={slide} />;
            case 'games':
                return <GamesSlide key={index} slide={slide} />;
            case 'players':
                return <PlayersSlides key={index} slide={slide} disclaimer={disclaimer} />;
        }
    };

    return (
        <Card className="mb-3">
            <Card.Body className="pb-0">
                <Row className="d-flex align-items-center px-3">
                    {title && (
                        <Card.Title>
                            <h4 className="font-bb">{title}</h4>
                        </Card.Title>
                    )}
                </Row>
                <Carousel
                    className="pb-5"
                    autoplay
                    autoplayInterval={4000}
                    dragging={false}
                    wrapAround
                    renderCenterRightControls={null}
                    renderCenterLeftControls={null}
                    renderBottomCenterControls={({ previousSlide, nextSlide }) => (
                        <Pagination className="ml-auto p-absolute">
                            <Pagination.Prev onClick={previousSlide} />
                            <Pagination.Next onClick={nextSlide} />
                        </Pagination>
                    )}
                >
                    {slides?.map((slide, index) => renderSlides(slide, index))}
                </Carousel>
            </Card.Body>
        </Card>
    );
};

export default StatisticsCarousel;
