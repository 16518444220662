import React, { useMemo } from 'react';
import { ListGroup, Col, Row } from 'react-bootstrap';
import { Roster, TeamDispatch } from 'labb/types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type Props = {
    dispatch: TeamDispatch;
    currentRoster: Roster;
    error: boolean;
};

const TeamExclusiveRules = ({ dispatch, currentRoster, error }: Props) => {
    const { t } = useTranslation('teams');

    const rulesOptions = useMemo(
        () =>
            currentRoster.rules
                ?.filter((item) => item.exclusive)
                .map((item) => ({
                    value: item.id,
                    label: item.nameEs,
                })),
        [currentRoster]
    );
    const handleRuleSelect = (item) => {
        dispatch({
            type: 'selectTeamRule',
            payload: item.value,
        });
    };

    if (!currentRoster.rulesExclusive) return null;

    return (
        <ListGroup.Item className="d-flex align-items-center">
            <Row className="d-flex justify-content-between align-items-center w-100">
                <Col sm={12} className="mb-2 text-center">
                    <span className={error ? 'text-danger' : ''}>{t('selectTeamRule')}</span>
                </Col>
                <Col sm={12} className="d-flex justify-content-end w-100">
                    {
                        // if we want to handle more than 1 exclusive rules, add `multiple` to the Select if currentRoster.rulesExclusive > 1
                        // and handle it on `handleRuleSelect` and API
                    }
                    <Select
                        placeholder={t('select')}
                        className="w-100"
                        options={rulesOptions}
                        onChange={handleRuleSelect}
                        isSearchable
                    />
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default TeamExclusiveRules;
