import { TeamDispatch } from 'labb/types';
import React, { useState } from 'react';
import { Container, Modal, Row, InputGroup, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    editData: any;
    dispatch: TeamDispatch;
    onHide: () => void;
    pickRandomName: (arg0, arg1) => string;
};

const EditPlayerName = ({ editData, dispatch, onHide, pickRandomName }: Props) => {
    const { t } = useTranslation('teams');
    const [playerName, setPlayerName] = useState(editData.name);

    const onSavePlayerName = () => {
        dispatch({
            type: 'changePlayerName',
            payload: { number: editData.number, name: playerName },
        });
        onHide();
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('changePlayerName', { count: editData?.number })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <Row>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text className="bg-primary text-light">
                                    {t('playerName')}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Player Name"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                            />
                        </InputGroup>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() =>
                        setPlayerName(pickRandomName(editData.positionalId, editData.number))
                    }
                >
                    {t('randomName')}
                </Button>
                <Button variant="success" onClick={onSavePlayerName}>
                    {t('save')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default EditPlayerName;
