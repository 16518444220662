import React, { useState, useEffect } from 'react';
import { CoachRedux, Team } from 'labb/types';
import { fetchData } from 'labb/utils';
import { Button, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { Formik, ErrorMessage } from 'formik';
import { FormikSelect, FormikSwitch, LoadingSpinner } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useTeamsSchema } from './useSchema';

type Props = {
    team: Team;
    coachs: CoachRedux[];
    onClose: () => void;
};

const ComissionerTeamEdit = ({ team, coachs, onClose }: Props) => {
    const coachOptions = coachs?.map((item) => ({ value: item.id, label: item.name }));
    const [leagueOptions, setLeagueOptions] = useState([]);
    const { t } = useTranslation('comissioner');
    const teamSchema = useTeamsSchema(t);
    const [formData, setFormData] = useState({
        id: team.id,
        name: team.name,
        coachId: team.coachId,
        coachValue: coachOptions.find((i) => i.value == team.coachId),
        assistants: team.assistants,
        cheerleaders: team.cheerleaders,
        apothecary: team.apothecary,
        treasury: team.treasury,
        dedicatedFans: team.dedicatedFans,
        leagueId: team.league?.id,
        leagueValue: team.league ? team.league : null,
    });

    useEffect(() => {
        loadData();
    }, []);

    const createLeagueOptions = (team: Team, openLeagues: Array<{ name: string; id: number }>) => {
        const removeLeage = { id: null, name: t('noLeague'), color: 'red' };
        return team.league ? [...new Set([team.league, removeLeage, ...openLeagues])] : openLeagues;
    };

    const loadData = async () => {
        const response = await fetchData('/league/list/open');
        if (response.status === 'success') {
            const { leagues } = response.data;
            setLeagueOptions(createLeagueOptions(team, leagues));
        } else {
            toast.error(t('selectTeamError', { msg: response.data.msg }));
        }
    };

    const onSubmit = async (values: any) => {
        const formPost = {
            ...values,
        };

        const { status, data } = await fetchData(`/team/comissioner/${values.id}`, 'put', formPost);
        if (status === 'success') {
            toast.success(t('teamModifiedSuccess'));
            onClose();
        } else if (status === 'error') {
            toast.error(t('teamModifiedError', { msg: data.msg }));
        }
    };

    if (formData.id === -1) {
        return <LoadingSpinner />;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Alert variant="danger">{t('warningTeam')}</Alert>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Formik
                        validationSchema={teamSchema}
                        onSubmit={onSubmit}
                        initialValues={formData}
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            touched,
                            errors,
                        }) => (
                            <Row className="d-flex justify-content-around">
                                <Col>
                                    <Form>
                                        <Container
                                            className="border border-primary p-4"
                                            style={{ backgroundColor: '#f7f7f9' }}
                                        >
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('name')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="name"
                                                        type="text"
                                                        required
                                                        autoComplete="off"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={touched.name && !!errors.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <ErrorMessage name="name" />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('assistants')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="assistants"
                                                        type="number"
                                                        required
                                                        autoComplete="off"
                                                        value={values.assistants}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.assistants &&
                                                            !!errors.assistants
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <ErrorMessage name="assistants" />
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('cheerleaders')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="cheerleaders"
                                                        type="number"
                                                        required
                                                        autoComplete="off"
                                                        value={values.cheerleaders}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.cheerleaders &&
                                                            !!errors.cheerleaders
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <ErrorMessage name="cheerleaders" />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('treasury')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="treasury"
                                                        type="number"
                                                        required
                                                        autoComplete="off"
                                                        value={values.treasury}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.treasury && !!errors.treasury
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <ErrorMessage name="treasury" />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('dedicatedFans')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="dedicatedFans"
                                                        type="number"
                                                        required
                                                        autoComplete="off"
                                                        value={values.dedicatedFans}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.dedicatedFans &&
                                                            !!errors.dedicatedFans
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        <ErrorMessage name="dedicatedFans" />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold d-block">
                                                        {t('apothecary')}
                                                    </Form.Label>
                                                    <FormikSwitch
                                                        className="d-block"
                                                        name="apothecary"
                                                        value={values.apothecary}
                                                        width={85}
                                                        onlabel={t('yes')}
                                                        offlabel={t('no')}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('coach')}
                                                    </Form.Label>
                                                    <FormikSelect
                                                        name="coachId"
                                                        placeholder={t('select')}
                                                        value={values.coachValue}
                                                        onChange={(e: any) =>
                                                            setFormData({
                                                                ...formData,
                                                                coachId: e.value,
                                                                coachValue: e,
                                                            })
                                                        }
                                                        options={coachOptions}
                                                    />
                                                    {errors.coachId && touched.coachId && (
                                                        <div className="d-block invalid-feedback">
                                                            <ErrorMessage name="coachId" />
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('league')}
                                                    </Form.Label>
                                                    <Select
                                                        name="leagueId"
                                                        className="w-100"
                                                        options={leagueOptions}
                                                        value={values.leagueValue}
                                                        styles={{
                                                            option: (styles, { data }) => ({
                                                                ...styles,
                                                                color: data.color,
                                                            }),
                                                        }}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                leagueId: e.value,
                                                                leagueValue: e,
                                                            })
                                                        }
                                                        placeholder={t('noLeague')}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                    />
                                                    {errors.leagueId && touched.leagueId && (
                                                        <div className="d-block invalid-feedback">
                                                            <ErrorMessage name="leagueId" />
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                        <Container className="p-3">
                                            <Row className="d-flex justify-content-end">
                                                <Button
                                                    className="m-auto"
                                                    variant="primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onClose();
                                                    }}
                                                >
                                                    {t('back')}
                                                </Button>
                                                <Button
                                                    disabled={isSubmitting}
                                                    className="m-auto"
                                                    variant="success"
                                                    onClick={() => {
                                                        handleSubmit(values);
                                                    }}
                                                >
                                                    {t('save')}
                                                </Button>
                                            </Row>
                                        </Container>
                                    </Form>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ComissionerTeamEdit;
