import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, ListGroup, Tab, Tabs, Button, InputGroup, Badge } from 'react-bootstrap';
import { Layout, Headline } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { fetchMultiple, fetchData } from 'labb/utils';
import EditModal from './components/EditModal';
import Waitinglist from './components/Waitinglist';
import Fuse from 'fuse.js';
import './style.scss';

const ComissionerPage = () => {
    const { t } = useTranslation(['comissioner', 'nav']);
    const [input, setInput] = useState('');
    const [results, setResults] = useState(null);
    const [selected, setSelected] = useState(null);
    const [preusers, setPreusers] = useState(null);
    const fuse = useRef(null);
    const coachs = useRef(null);

    useEffect(() => {
        if (!fuse.current) {
            initialLoad();
        }
    }, []);

    const initialLoad = async () => {
        const response = await fetchMultiple([
            '/user/list',
            '/team/list',
            '/league/list',
            '/user/pre/list',
        ]);

        if (response.status === 'success') {
            const users = response.data[0].users.map((item) => ({
                ...item,
                type: 'coach',
                searchType: t('coach'),
            }));
            const teams = response.data[1].teams.map((item) => ({
                name: item.team.name,
                coachName: item.coach.name,
                id: item.team.id,
                type: 'team',
                searchType: t('team'),
            }));
            const leagues = response.data[2].leagues.map((item) => ({
                ...item,
                type: 'league',
                searchType: t('league'),
            }));
            coachs.current = users;
            fuse.current = new Fuse([...users, ...teams, ...leagues], {
                threshold: 0.5,
                minMatchCharLength: 2,
                keys: ['name', 'email', 'coachName', 'searchType'],
            });
            setPreusers(response.data[3].preusers);
        }
    };

    const handleChange = (event) => {
        setInput(event.target.value);
        if (!event.target.value) {
            setResults(null);
            return;
        }
        const results = fuse.current
            ?.search(event.target.value)
            ?.slice(0, 20)
            ?.map((result) => result.item);
        setResults(results);
    };

    const handleSelect = (item) => {
        setSelected(item);
    };

    const clearSelect = () => {
        setSelected(undefined);
        setResults(null);
        setInput('');
    };

    const closeModal = () => {
        setSelected(undefined);
    };

    const updateWaitingList = async () => {
        const response = await fetchData('/user/pre/list');
        if (response.status === 'success') {
            setPreusers(response.data.preusers);
        }
    };

    return (
        <Layout
            pageTitle={t('nav:comissioner')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:comissioner'), active: true },
            ]}
        >
            <EditModal
                onClose={closeModal}
                selected={selected}
                coachs={coachs}
                setSelected={setSelected}
            />

            <Headline>{t('title')}</Headline>

            <Tabs defaultActiveKey="search" id="search-tabs" className="mb-3">
                <Tab eventKey="search" title={t('search')}>
                    <Row className="pt-3 px-5">
                        <Form.Group as={Col} md={6}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    autoComplete="never"
                                    value={input}
                                    onChange={handleChange}
                                    placeholder={t('placeholder')}
                                />
                                {results && (
                                    <InputGroup.Append>
                                        <Button onClick={clearSelect} variant="outline-secondary">
                                            {'X'}
                                        </Button>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Col>
                            <ListGroup className="comissioner-list-group">
                                {results?.map((result) => (
                                    <ListGroup.Item
                                        className="d-flex justify-content-between"
                                        action
                                        onClick={() => handleSelect(result)}
                                        key={`${result.type}-${result.id}`}
                                    >
                                        <div>
                                            <div>
                                                <span className="font-weight-bold">
                                                    {result.name}
                                                </span>
                                                {result.email && ` - ${result.email}`}
                                            </div>
                                            <div className="text-muted">
                                                <span>{t(result.type)}</span>
                                                {result.coachName && (
                                                    <span>
                                                        {t('coachedBy', {
                                                            coachName: result.coachName,
                                                        })}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-muted text-small">
                                                {'id: '}
                                                {result.id}
                                            </span>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Tab>
                <Tab
                    eventKey="waitingList"
                    title={
                        <>
                            {t('waitinglist')}
                            <Badge variant="primary" className="ml-2 align-text-top">
                                {preusers !== null ? preusers.length : '-'}
                            </Badge>
                        </>
                    }
                >
                    {!!preusers?.length && (
                        <Waitinglist preusers={preusers} onUpdate={updateWaitingList} />
                    )}
                </Tab>
            </Tabs>
        </Layout>
    );
};

export default ComissionerPage;
