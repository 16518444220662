import React from 'react';
import { Modal } from 'react-bootstrap';
import AddPlayer from './AddPlayer';
import ChangeNumber from './ChangeNumber';
import HandleAdvancement from './HandleAdvancement';
import HistoricPlayers from './HistoricPlayers';
import HistoricOrders from './HistoricOrders';
import TeamRecords from './TeamRecords';
import PlayerRecord from './PlayerRecord';
import { TeamDispatch, TeamReducerState } from 'types';
import EditPlayerName from './editPlayerName';

type Props = {
    team: TeamReducerState['team'];
    games: TeamReducerState['games'];
    modalData: { type: string; data: any };
    setModalData: any;
    currentRoster: TeamReducerState['currentRoster'];
    dispatch: TeamDispatch;
    exclusivesCount: number;
    pickRandomName: (arg0, arg1) => string;
    positionalCount: any;
};

const TeamModal = ({
    modalData,
    setModalData,
    currentRoster,
    team,
    games,
    dispatch,
    exclusivesCount,
    pickRandomName,
    positionalCount,
}: Props) => {
    const onHide = () => {
        setModalData({ type: null, data: null });
    };

    const modalProps = {
        currentRoster,
        team,
        dispatch,
        editData: modalData.data,
        onHide,
    };

    const renderModal = () => {
        switch (modalData.type) {
            case 'addPlayer':
                return (
                    <AddPlayer
                        {...modalProps}
                        disableExclusives={exclusivesCount >= currentRoster.positionalsExclusive}
                        pickRandomName={pickRandomName}
                        positionalCount={positionalCount}
                    />
                );
            case 'editName':
                return <EditPlayerName {...modalProps} pickRandomName={pickRandomName} />;
            case 'changeNumber':
                return <ChangeNumber {...modalProps} players={team.players} />;
            case 'advancement':
                return <HandleAdvancement {...modalProps} />;
            case 'historicPlayers':
                return <HistoricPlayers {...modalProps} players={team.players} />;
            case 'historicOrders':
                return <HistoricOrders {...modalProps} />;
            case 'teamRecords':
                return <TeamRecords {...modalProps} teamId={team.id} games={games} />;
            case 'playerRecord':
                return <PlayerRecord {...modalProps} />;
            default:
                return null;
        }
    };

    if (!modalData.type) return null;

    return (
        <Modal
            show
            size="lg"
            className="modal-fullscreen-lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onHide}
            centered
        >
            {renderModal()}
        </Modal>
    );
};

export default TeamModal;
