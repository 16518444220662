import axios from 'axios';

axios.defaults.baseURL = process.env.API_HOST || 'http://localhost/api';

class TokenStorage {
    private static readonly LOCAL_STORAGE_TOKEN = 'token';
    private static readonly LOCAL_STORAGE_EXPIRATION = 'expires';
    public static isAuthenticated(): boolean {
        return this.getToken() !== null && !this.isTokenExpired();
    }

    public static getNewToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            axios
                .post('/token/', { labbsecret: process.env.LABBSECRET })
                .then((response) => {
                    this.storeToken(response.data.token, response.data.expires);

                    resolve(response.data.token);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private static storeToken(token: string, exp: string): void {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_EXPIRATION, exp);
    }

    private static isTokenExpired(): boolean {
        // Server sends expiration in unix time but we need to convert to milliseconds
        const exp = new Date(
            parseInt(localStorage.getItem(TokenStorage.LOCAL_STORAGE_EXPIRATION)) * 1000
        );
        const now = new Date();
        return exp < now;
    }

    public static clear(): void {
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_EXPIRATION);
    }

    public static getToken(): string | null {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    }
}

export default TokenStorage;
