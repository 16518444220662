import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { useField } from 'formik';

type Props = {
    name: string;
    options: any;
    disabled?: boolean;
} & SelectProps;

const FormikSelect = ({ name, options, disabled, ...props }: Props) => {
    const [field, _meta, helpers] = useField(name);
    const { setValue, setTouched, setError } = helpers;

    const setFieldProps = (selectedOption) => {
        setValue(Number(selectedOption.value));
        setTouched(true);
        setError(undefined);
    };

    return (
        <Select
            name={field.name}
            options={options}
            onChange={(selectedOption) => setFieldProps(selectedOption)}
            isDisabled={disabled}
            {...props}
        />
    );
};

export default FormikSelect;
