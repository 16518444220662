import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout, Headline } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { fetchData, fetchMultiple } from 'labb/utils';
import { CoachRedux, Coach, UserStatistics } from 'labb/types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { CoachLink, LabbTable, Caption, TeamLink, LeagueLink, MatchLink } from 'shared/components';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useAuth } from 'context/auth-context';

const StatCoachList = () => {
    const { t } = useTranslation(['coachlist', 'nav']);
    const [coachs, setCoachs] = useState<CoachRedux[]>(null);
    const [coach, setCoach] = useState<Coach>(null);
    const [coachOptions, setCoachOptions] = useState([]);
    const { isComissioner } = useAuth();
    const [statistics, setStatistics] = useState<UserStatistics>();

    useEffect(() => {
        if (!coachs) {
            fetchCoachs();
        }
    }, []);

    const fetchCoachs = async () => {
        const response = await fetchData('/user/list');
        if (response.status === 'success') {
            setCoachOptions(
                response.data.users?.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                })
            );
            setCoachs(response.data.users);
        }
    };

    const handleCoachSelect = async (item) => {
        const response = await fetchMultiple([
            `/user/${item.value}`,
            `/statistic/user/${item.value}`,
        ]);
        if (response.status === 'success') {
            setCoach(response.data[0].user);
            setStatistics(response.data[1].stats);
        } else {
            toast.error(t('selectCoachError', { msg: response.data.msg }));
        }
    };

    const renderUserLevel = (level) => {
        return level === 'c' ? t('comissioner') : t('coach');
    };

    const coachColumns: ColumnDescription[] = [
        {
            dataField: 'code',
            text: t('code'),
            hidden: !isComissioner,
        },
        {
            dataField: 'coachUrl',
            text: t('coachName'),
            formatter: (_cell, row) => <CoachLink coachEmail={row.email} coachName={row.usuario} />,
        },
        {
            dataField: 'nivel',
            text: t('level'),
            hidden: !isComissioner,
            formatter: (_cell, row) => renderUserLevel(row.nivel),
        },
        {
            dataField: 'gdpr',
            text: t('gdpr'),
            hidden: !isComissioner,
        },
    ];

    const teamColumns: ColumnDescription[] = [
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'league_name',
            text: t('league'),
            formatter: (_cell, row) => (
                <LeagueLink leagueId={row.league?.id} leagueName={row.league?.name} />
            ),
        },
        {
            dataField: 'totalGames',
            text: t('totalGames'),
            formatter: (_cell, row) => row.win + row.draw + row.loss,
        },
        {
            dataField: 'win',
            text: t('win'),
            formatter: (_cell, row) =>
                `${row.win} (${((100 * row.win) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'draw',
            text: t('draw'),
            formatter: (_cell, row) =>
                `${row.draw} (${((100 * row.draw) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'loss',
            text: t('loss'),
            formatter: (_cell, row) =>
                `${row.loss} (${((100 * row.loss) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
    ];

    const statUserColumns: ColumnDescription[] = [
        {
            dataField: 'totalGames',
            text: t('totalGames'),
        },
        {
            dataField: 'totalWin',
            text: t('win'),
            formatter: (_cell, row) =>
                `${row.totalWin} (${((100 * row.totalWin) / row.totalGames).toFixed(2)}%)`,
        },
        {
            dataField: 'totalDraw',
            text: t('draw'),
            formatter: (_cell, row) =>
                `${row.totalDraw} (${((100 * row.totalDraw) / row.totalGames).toFixed(2)}%)`,
        },
        {
            dataField: 'totalLoss',
            text: t('loss'),
            formatter: (_cell, row) =>
                `${row.totalLoss} (${((100 * row.totalLoss) / row.totalGames).toFixed(2)}%)`,
        },
    ];

    const teamParticipationColumns: ColumnDescription[] = [
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'league_name',
            text: t('league'),
            formatter: (_cell, row) => (
                <LeagueLink leagueId={row.league?.id} leagueName={row.league?.name} />
            ),
        },
        {
            dataField: 'position',
            text: t('position'),
            formatter: (_cell, row) => row.position || t('positionNA'),
        },
    ];

    const gamesColumns: ColumnDescription[] = [
        {
            dataField: 'index',
            text: t('#'),
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t1.coach.email} coachName={row.t1.coach.name} />
            ),
        },
        {
            dataField: 'teamUrl1',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t1.team.id} teamName={row.t1.team.name} />
            ),
        },
        {
            dataField: 't1.cas',
            text: t('CAS'),
        },
        {
            dataField: 't1.td',
            text: t('TD'),
        },
        {
            dataField: 't2.td',
            text: t('TD'),
        },
        {
            dataField: 't2.cas',
            text: t('CAS'),
        },
        {
            dataField: 'teamUrl2',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t2.team.id} teamName={row.t2.team.name} />
            ),
        },
        {
            dataField: 'coachUrl2',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t2.coach.email} coachName={row.t2.coach.name} />
            ),
        },
        {
            dataField: 'league_name',
            text: t('league'),
            formatter: (_cell, row) => (
                <LeagueLink leagueId={row.league.id} leagueName={row.league.name} />
            ),
        },
        {
            dataField: 'date',
            text: t('date'),
        },
        {
            dataField: 'fixtureUrl',
            text: t('fixture'),
            formatter: (_cell, row) => (
                <MatchLink leagueId={row.league.id} gameId={row.gameId} gamename={row.gamename} />
            ),
        },
    ];

    const rivalColumns: ColumnDescription[] = [
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
        },
        {
            dataField: 'played',
            text: t('played'),
            formatter: (_cell, row) => row.win + row.loss + row.draw,
        },
        {
            dataField: 'win',
            text: t('win'),
            formatter: (_cell, row) =>
                `${row.win} (${((100 * row.win) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'draw',
            text: t('draw'),
            formatter: (_cell, row) =>
                `${row.draw} (${((100 * row.draw) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'loss',
            text: t('loss'),
            formatter: (_cell, row) =>
                `${row.loss} (${((100 * row.loss) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
    ];

    const leagueColumns: ColumnDescription[] = [
        {
            dataField: 'leagueUrl',
            text: t('league'),
            formatter: (_cell, row) => (
                <LeagueLink leagueId={row.league.id} leagueName={row.league.name} />
            ),
        },
        {
            dataField: 'played',
            text: t('played'),
            formatter: (_cell, row) => row.win + row.loss + row.draw,
        },
        {
            dataField: 'win',
            text: t('win'),
            formatter: (_cell, row) =>
                `${row.win} (${((100 * row.win) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'draw',
            text: t('draw'),
            formatter: (_cell, row) =>
                `${row.draw} (${((100 * row.draw) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'loss',
            text: t('loss'),
            formatter: (_cell, row) =>
                `${row.loss} (${((100 * row.loss) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
    ];

    return (
        <Layout
            pageTitle={t('nav:coaches')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:statistics'), link: '#' },
                { label: t('nav:coaches'), active: true },
            ]}
        >
            <Container data-testid="coach-list" fluid className="mb-5 container-xxl">
                <Headline>{t('nav:coachlist')}</Headline>
                <Row>
                    <Col md={8} className="pb-4 m-auto text-center">
                        <h4>{t('selectCoach')}</h4>
                        <Select
                            name="coachListId"
                            placeholder={t('select')}
                            options={coachOptions}
                            onChange={handleCoachSelect}
                            isSearchable
                        />
                    </Col>
                </Row>
                <Row>
                    {!!coach && (
                        <Col md={8} className="m-auto">
                            <LabbTable
                                caption={<Caption text={coach.usuario} />}
                                keyField="code"
                                data={[coach]}
                                columns={coachColumns}
                            />
                        </Col>
                    )}
                    {!!statistics?.totalGames && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('results')} />}
                                keyField="userId"
                                data={[statistics]}
                                columns={statUserColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!statistics?.teamStatistics && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('teamsInLeague')} />}
                                keyField="team.id"
                                data={statistics.teamStatistics?.filter((item) => !!item.league)}
                                columns={teamColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!statistics?.teamParticipation && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('recordsList')} />}
                                keyField="index"
                                data={statistics.teamParticipation.map((row, index) => ({
                                    ...row,
                                    index: row.team.id + index,
                                }))}
                                columns={teamParticipationColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!statistics?.games?.length && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('games')} />}
                                keyField="index"
                                data={statistics.games.map((row, index) => ({
                                    ...row,
                                    index: index + 1,
                                }))}
                                columns={gamesColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!statistics?.rivalStatistics?.length && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('rivals')} />}
                                keyField="index"
                                data={statistics.rivalStatistics.map((row, index) => ({
                                    ...row,
                                    index: row.coach.id + index,
                                }))}
                                columns={rivalColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!statistics?.leagueStatistics?.length && (
                        <Col>
                            <LabbTable
                                caption={<Caption text={t('leagues')} />}
                                keyField="league.id"
                                data={statistics.leagueStatistics}
                                columns={leagueColumns}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </Layout>
    );
};

export default StatCoachList;
