import { Modal } from 'react-bootstrap';
import React, { PropsWithChildren } from 'react';

type Props = {
    show: boolean;
    onHide: () => void;
    title: string;
};

const TutorialModal = ({ show, onHide, title, children }: PropsWithChildren<Props>) => {
    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header className="pl-5" closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="tutorial-body p-5">{children}</Modal.Body>
        </Modal>
    );
};

export default TutorialModal;
