import React from 'react';
import { ListGroup, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TeamListItem } from '..';

type Props = {
    treasury: number;
    teamValue: number;
    teamCost: number;
    teamCurrentValue: number;
    publicView: boolean;
    createView: boolean;
};

const TeamValues = ({
    treasury,
    teamValue,
    teamCost,
    teamCurrentValue,
    publicView,
    createView,
}: Props) => {
    const { t } = useTranslation('teams');

    const summaryItems = [
        {
            label: t('treasury'),
            value: treasury
                ? t('value', { tr: treasury.toLocaleString('de-DE') })
                : t('value_zero'),
            error: treasury < 0,
        },
        {
            label: t('teamValue'),
            value: teamValue,
        },
        {
            label: t('currentTeamValue'),
            value: teamCurrentValue,
            hidden: createView,
        },
        {
            label: t('totalCost'),
            value: teamCost
                ? t('value', { tr: teamCost.toLocaleString('de-DE') })
                : t('value_zero'),
            hidden: publicView,
        },
    ];
    return (
        <Card className="shadow-sm">
            <ListGroup className="list-group-striped" variant="flush">
                {summaryItems.map((item) => (
                    <TeamListItem key={`summary-${item.label}`} item={item} />
                ))}
            </ListGroup>
        </Card>
    );
};

export default TeamValues;
