import { Roster, Team } from 'labb/types';
import { TEAM_EDIT_CONSTANTS } from '../constants';
import { getValuesForPosition } from '../helpers';

type CreateTable = (
    arg0: Team['players'],
    arg1: Roster,
    arg3: boolean
) => Array<Record<string, any>>;

const emptyRow = {
    positionalId: 0,
    name: '',
    skills: [],
    actions: {
        add: true,
        remove: false,
        change: false,
        advancement: false,
    },
};

const createTable: CreateTable = (players, currentRoster, fillWithEmpty) => {
    const playersData = players.map((player) => {
        const rosterPositional = getValuesForPosition(player.positionalId, currentRoster);
        return {
            ma: rosterPositional.ma,
            st: rosterPositional.st,
            ag: rosterPositional.ag,
            pa: rosterPositional.pa,
            av: rosterPositional.av,
            cv: rosterPositional.cost,
            hiringFee: rosterPositional.cost,
            ...TEAM_EDIT_CONSTANTS.player,
            ...player,
            skills: [
                ...(player.skills?.map((item) => ({ ...item, type: 'acquired' })) || []),
                ...(rosterPositional.skills?.map((item) => ({ ...item, type: 'base' })) || []),
            ],
        };
    });

    const table = [];

    if (fillWithEmpty) {
        for (let i = 0; i < 16; i++) {
            table.push({ ...emptyRow, number: i + 1 });
        }
    }

    playersData.forEach(
        (item) =>
            (table[item.number - 1] = {
                ...item,
                actions: { add: false, remove: true, change: true, advancement: true },
            })
    );

    return table;
};

export default createTable;
