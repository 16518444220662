import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { CoachLink, TeamLink, Caption, LabbTable } from 'shared/components';
import { Conference } from 'labb/types';

type Props = {
    conferences: Conference[];
    extraPoints: string;
    leagueStatus: number;
};

const ConferenceListItem = ({ conferences, extraPoints, leagueStatus }: Props) => {
    const { t } = useTranslation('leagues');

    const renderWinTieLoss = (win, tie, loss) => {
        return `${win}-${tie}-${loss}`;
    };

    const renderTotalGames = (win, tie, loss) => {
        return <div>{win + tie + loss}</div>;
    };

    const renderRowIndex = (id) => {
        return <div>{id + 1}</div>;
    };
    const renderExtraPoints = (cell) => {
        return <Row className="d-flex justify-content-center">{cell}</Row>;
    };

    const standingColumns: ColumnDescription[] = [
        {
            dataField: 'team.id',
            text: 'team_id',
            hidden: true,
        },
        {
            dataField: 'positionNumber',
            text: t('number'),
            formatter: (_cell, _row, rowIndex) => renderRowIndex(rowIndex),
            editable: false,
        },
        {
            dataField: 'teamUrl1',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
            editable: false,
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
            editable: false,
        },
        {
            dataField: 'w_t_l',
            text: t('results'),
            formatter: (_cell, row) => renderWinTieLoss(row.win, row.tie, row.loss),
            editable: false,
        },
        {
            dataField: 'played',
            text: t('gamesPlayed'),
            formatter: (_cell, row) => renderTotalGames(row.win, row.tie, row.loss),
            editable: false,
        },
        {
            dataField: 'points',
            text: t('points'),
            editable: false,
        },
        {
            dataField: 'tf',
            text: t('tf'),
            editable: false,
        },
        {
            dataField: 'ta',
            text: t('ta'),
            editable: false,
        },
        {
            dataField: 'cf',
            text: t('cf'),
            editable: false,
        },
        {
            dataField: 'ca',
            text: t('ca'),
            editable: false,
        },
        {
            dataField: 'tv',
            text: t('vae'),
            editable: false,
        },
        ...(extraPoints
            ? [
                  {
                      dataField: 'extraPoints',
                      text: extraPoints,
                      formatter: (cell) => renderExtraPoints(cell),
                  },
              ]
            : []),
    ];

    if (!conferences || conferences.length < 1 || leagueStatus === 0) return null;

    return (
        <Row className="p-3 d-flex flex-column">
            <h3 className="font-bb text-center mb-3">{t('conferences')}</h3>
            {conferences.map((item: Conference) => (
                <LabbTable
                    key={item.id}
                    caption={<Caption text={item.name} />}
                    keyField="team.id"
                    data={item.standings}
                    columns={standingColumns}
                />
            ))}
        </Row>
    );
};

export default ConferenceListItem;
