const getGameStatusText = (status: number): string => {
    switch (status) {
        case 0:
            return 'notPlayed';
        case 10:
            return 'recorded';
        case 20:
            return 'verified';
        default:
            return '';
    }
};

export default getGameStatusText;
