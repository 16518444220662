import React from 'react';
import { Col } from 'react-bootstrap';

const Headline = ({ children }) => (
    <Col className="mt-3 mb-3">
        <h3 className="font-bb">{children}</h3>
    </Col>
);

export default Headline;
