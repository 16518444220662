import React from 'react';
import { Jumbotron, Image, Container } from 'react-bootstrap';
import bannerImage from 'assets/images/banner.png';
import './styles.scss';

const JumbotronComponent = () => {
    return (
        <Jumbotron className="mb-0 bg">
            <Container fluid className="container-xxl">
                <Image src={bannerImage} alt="labb-banner" fluid />
            </Container>
        </Jumbotron>
    );
};

export default JumbotronComponent;
