import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rosters, Team } from 'labb/types';
import { fetchData } from 'labb/utils';
import { Button, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ConfirmModal } from 'shared/components';
import {
    MdDeleteForever as DeleteIcon,
    MdEdit as EditIcon,
    MdSearch as PublicLinkIcon,
} from 'react-icons/md';
import { LabbTable } from 'shared/components';
import { toast } from 'react-toastify';

type Props = {
    teams: Team[];
    setTeams: Dispatch<SetStateAction<Team[]>>;
    rosters: Rosters;
};

const TeamList = ({ teams, setTeams, rosters }: Props) => {
    const { t } = useTranslation('teams');
    const history = useHistory();
    const [confirmModal, setConfirmModal] = useState(null);

    const onSubmitConfirmModal = async () => {
        const { data, status } = await fetchData(`/team/${confirmModal}`, 'delete');
        if (status === 'success') {
            toast.success(t('deleteSuccess'));
            setTeams(data.teams);
        } else if (status === 'error') {
            toast.error(t('deleteError', { msg: data.msg }));
        }
        setConfirmModal(null);
    };

    const renderPublicViewLink = (id) => {
        return (
            <Link
                className="btn btn-outline-primary border-0"
                title={t('publicRoster', { count: id })}
                role="button"
                to={`/teams/${id}`}
                target="_blank"
            >
                <PublicLinkIcon />
            </Link>
        );
    };

    const getCurrentRoster = (rosterId) => rosters.find((roster) => roster.id === rosterId);

    const renderActions = (id) => {
        return (
            <Row className="d-flex flex-nowrap justify-content-center mx-4">
                <Button
                    className="border-0"
                    onClick={() => history.push(`/profile/teams/${id}/edit`)}
                    title={t('editTeam')}
                    variant="outline-success"
                >
                    <EditIcon />
                </Button>
                <Button
                    className="border-0"
                    onClick={() => setConfirmModal(id)}
                    title={t('deleteTeam', { count: id })}
                    variant="outline-danger"
                >
                    <DeleteIcon />
                </Button>
            </Row>
        );
    };

    const columns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'name',
            text: t('name'),
            classes: 'font-weight-bold',
        },
        {
            dataField: 'rosterName',
            text: t('roster'),
            formatter: (_cell, row) => getCurrentRoster(row.rosterId).nameEs,
        },
        {
            dataField: 'teamValue',
            text: t('teamValue'),
        },
        {
            dataField: 'league',
            text: t('league'),
            formatter: (cell) => cell?.name,
        },
        {
            dataField: 'teamPublicUrl',
            text: t('publicTeamRoster'),
            formatter: (_cell, row) => renderPublicViewLink(row.id),
        },
        {
            dataField: 'actions',
            text: t('Actions'),
            formatter: (_cell, row) => renderActions(row.id),
        },
    ];

    if (!teams || teams.length < 1) return null;

    return (
        <>
            <ConfirmModal
                text={t('sure?')}
                show={!!confirmModal}
                onHide={() => setConfirmModal(null)}
                onSubmit={onSubmitConfirmModal}
            />
            <LabbTable keyField="id" data={teams} columns={columns} />
        </>
    );
};

export default TeamList;
