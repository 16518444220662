import React, { createContext, useContext, useState } from 'react';
import { fetchData, useLocalStorage } from 'labb/utils';
interface AuthContext {
    user: { code: number; email: string; gdpr: string; nivel: string; usuario: string };
    status: 'success' | 'pending' | 'error';
    message: any;
    isUser: boolean;
    isComissioner: boolean;
    login: (username: string, password: string) => Promise<{ status: any; data: any }>;
    register: any;
    logout: any;
    recoverPassword: any;
    updateProfile: any;
    deleteUser: any;
}
interface useProvideState {
    status: AuthContext['status'];
    message: string;
    user: AuthContext['user'];
}

const authContext = createContext<Partial<AuthContext>>({});

const useProvideAuth = (): AuthContext => {
    const [storedUser, setStoredUser] = useLocalStorage('user', null);
    const [state, setState] = useState<useProvideState>(() => {
        if (storedUser) {
            return {
                status: 'success',
                message: 'autoLogin',
                user: storedUser,
            };
        }
        return {
            status: 'pending',
            message: null,
            user: null,
        };
    });

    const handleResponse = (response: any): { status: any; data: any } => {
        if (response.status === 'success') {
            setStoredUser(response.data.user);
            setState({
                status: response.status,
                message: null,
                user: response.data.user,
            });
        } else {
            setState({
                status: response.status,
                message: response.data,
                user: null,
            });
        }
        return response;
    };

    const login: AuthContext['login'] = async (username, passw) => {
        return handleResponse(await fetchData('/user/login/', 'post', { username, passw }));
    };

    const register = async (newuser, newemail) => {
        return handleResponse(await fetchData('/user/pre/', 'post', { newuser, newemail }));
    };

    const logout = () => {
        setStoredUser(null);
        setState({ status: 'pending', message: null, user: null });
    };

    const recoverPassword = async (username): Promise<{ message: string }> => {
        return (await fetchData(`/user/${username}/recover`))?.data?.msg;
    };

    const updateProfile = async (
        code,
        user,
        passw,
        email,
        userlevel
    ): Promise<{ status: any; data: any }> => {
        return handleResponse(
            await fetchData(`/user/${code}`, 'put', {
                user,
                passw,
                email,
                userlevel,
            })
        );
    };

    const deleteUser = async (code): Promise<{ status: any; data: any }> => {
        return handleResponse(await fetchData(`/user/${code}`, 'delete'));
    };

    return {
        user: state.user,
        status: state.status,
        message: state.message,
        isUser: state.user && state.user?.code !== 0,
        isComissioner: state.user && state.user?.nivel === 'c',
        login,
        register,
        logout,
        recoverPassword,
        updateProfile,
        deleteUser,
    };
};

export const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth as AuthContext}>{children}</authContext.Provider>;
};

export const useAuth = (): Partial<AuthContext> => {
    return useContext(authContext);
};
