import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AppProviders from './context';
import routes from './routes/routes';
import AppRoute from './routes/AppRoute';

const Routes = () => {
    return (
        <AppProviders>
            <Router basename={process.env.PUBLIC_PATH}>
                <Switch>
                    {routes.map((route) => (
                        <AppRoute
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            isPrivate={route.isPrivate}
                            exact={route.exact}
                        />
                    ))}
                </Switch>
            </Router>
        </AppProviders>
    );
};

export default Routes;
