import { useEffect, useState } from 'react';
import { fetchData } from 'labb/utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type News = { text: string; date: string; id: number };

const useNews = (
    url: string,
    initialNews?: Array<News>
): {
    news: Array<News>;
    fetchList: any;
    addNewsItem: any;
    deleteNewsItem: any;
    newsInput: string;
    setNewsInput: (value: string) => void;
    loading: boolean;
} => {
    const { t } = useTranslation('news');
    const [news, setNews] = useState(initialNews);
    const [newsInput, setNewsInput] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!news) {
            fetchList();
        }
    }, []);

    const fetchList = async () => {
        const response = await fetchData(`${url}/list`);
        if (response.status === 'success') {
            setNews(response.data.news);
            setLoading(false);
        }
    };

    const addNewsItem = async () => {
        const response = await fetchData(`${url}/`, 'post', { newstext: newsInput });
        if (response.status === 'success') {
            toast.success(t('newsAddedSuccess'));
            setNews(response.data.news);
            setNewsInput('');
        } else {
            toast.error(response?.data?.msg || t('newsAddedError'));
        }
    };

    const deleteNewsItem = async (id: number) => {
        const response = await fetchData(`${url}/${id}`, 'delete');
        if (response.status === 'success') {
            toast.success(t('newsDeleteSuccess'));
            setNews(response.data.news);
        } else {
            toast.error(response?.data?.msg || t('newsDeleteError'));
        }
    };

    return {
        news,
        fetchList,
        addNewsItem,
        deleteNewsItem,
        newsInput,
        setNewsInput,
        loading,
    };
};

export default useNews;
