import { League as LeagueType, Game, Conference, Playoff } from 'labb/types';
import React, { useMemo, useState } from 'react';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { flatten } from 'underscore';
import { Fixture, LoadingSpinner } from 'shared/components';

type Props = {
    league: LeagueType;
    onClose: () => void;
};

const getConferences = (league: LeagueType): Conference[] => {
    const conferences = [];
    if (league.conferences?.length > 0) {
        conferences.push(league.conferences);
    }
    return flatten(conferences);
};

const getPlayoffs = (league: LeagueType): Playoff[] => {
    const playoffs = [];
    if (league.playoffs?.length > 0) {
        playoffs.push(league.playoffs);
    }
    return flatten(playoffs);
};

const getFixtures = (league: LeagueType, conferenceId: number, playoffId: number): Game[] => {
    const fixtures = [];
    if (league.playoffs?.length > 0) {
        if (playoffId !== null) {
            fixtures.push(
                league.playoffs.filter((item) => item.id === playoffId).map((item) => item.games)
            );
        } else if (conferenceId === null) {
            fixtures.push(league.playoffs.map((item) => item.games));
        }
    }
    if (league.conferences?.length > 0) {
        if (conferenceId !== null) {
            fixtures.push(
                league.conferences
                    .filter((item) => item.id === conferenceId)
                    .map((item) => item.games)
            );
        } else if (playoffId === null) {
            fixtures.push(league.conferences.map((item) => item.games));
        }
    }
    return flatten(fixtures);
};

const Fixtures = ({ league, onClose }: Props) => {
    const { t } = useTranslation('comissioner');
    const [fixtureId, setFixtureId] = useState(null);
    const [conferenceId, setConferenceId] = useState(null);
    const [playoffId, setPlayoffId] = useState(null);

    const fixtureOptions = useMemo(() => {
        if (!league) return null;
        return getFixtures(league, conferenceId, playoffId).map((item) => ({
            value: item.id,
            label: `${item.gamename}: ${item.t1.team.name} VS ${item.t2.team.name}`,
        }));
    }, [league, playoffId, conferenceId]);

    const conferencesOptions = useMemo(() => {
        if (!league) return null;
        return getConferences(league).map((item) => ({
            value: item.id,
            label: `${item.name}`,
        }));
    }, [league]);

    const playoffsOptions = useMemo(() => {
        if (!league) return null;
        return getPlayoffs(league).map((item) => ({
            value: item.id,
            label: `${item.name}`,
        }));
    }, [league]);

    if (!league) return <LoadingSpinner />;

    return (
        <Container>
            <Row>
                <Col>
                    <Alert variant="danger">{t('warningTeam')}</Alert>
                </Col>
            </Row>
            {!!league.playoffs.length && (
                <Row>
                    <Col className="pb-4">
                        <h4>{t('selectPlayoff')}</h4>
                        <Select
                            placeholder={t('select')}
                            options={playoffsOptions}
                            onChange={(item) => setPlayoffId(item?.value)}
                            isSearchable
                            isClearable
                        />
                    </Col>
                </Row>
            )}
            {!!league.conferences.length && (
                <Row>
                    <Col className="pb-4">
                        <h4>{t('selectConference')}</h4>
                        <Select
                            placeholder={t('select')}
                            options={conferencesOptions}
                            onChange={(item) => setConferenceId(item?.value)}
                            isSearchable
                            isClearable
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col className="pb-4">
                    <h4>{t('selectFixture')}</h4>
                    <Select
                        placeholder={t('select')}
                        options={fixtureOptions}
                        onChange={(item) => setFixtureId(item.value)}
                        isSearchable
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {!!fixtureId && (
                        <Fixture
                            leagueId={`${league.id}`}
                            fixtureId={fixtureId}
                            onClose={onClose}
                            comissionerHandling
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Fixtures;
