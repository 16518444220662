import { Roster, Team, TeamCreationPostData } from 'labb/types';
import { TEAM_CREATION_CONSTANTS } from '../constants';
import { getPositionalById } from 'labb/utils';

const calculateTeamValueAndCost = (
    data: TeamCreationPostData | Team,
    currentRoster: Roster,
    maxTeamTR: number
): { teamValue: number; currentTeamValue: number; teamCost: number; treasury: number } => {
    if (!data || !currentRoster)
        return { teamValue: 0, currentTeamValue: 0, teamCost: 0, treasury: 0 };
    const staff = ['rerolls', 'assistants', 'cheerleaders', 'apothecary'];
    let staffCost = 0;

    staff.forEach((item) => {
        if (item === 'rerolls') {
            staffCost += currentRoster.rr * data[item];
            return;
        }
        staffCost += TEAM_CREATION_CONSTANTS[item].cost * data[item];
    });

    let playersCost = 0;
    let playersValue = 0;
    data.players.forEach((item) => {
        const { positional } = getPositionalById(currentRoster, item.positionalId);
        if (positional.lowcost) {
            playersCost += positional.cost;
        } else {
            playersCost += positional.cost;
            playersValue += positional.cost;
        }
    });

    if (Object.prototype.hasOwnProperty.call(data, 'treasury')) {
        playersValue = 0;
        (data as Team).players
            .filter(({ status }) => status != 30 && status != 20 && status != 60)
            .forEach((item) => {
                if (item.cv !== null && item.cv !== undefined) {
                    playersValue += item.cv;
                } else {
                    // hiring new players
                    const { positional } = getPositionalById(currentRoster, item.positionalId);
                    playersValue += positional.cost;
                }
            });
    }
    let injuredPlayersValue = 0;
    if (Object.prototype.hasOwnProperty.call(data, 'treasury')) {
        (data as Team).players
            .filter(({ status }) => status == 10)
            .forEach(({ cv }) => (injuredPlayersValue += cv));
    }

    const teamValue = staffCost + playersValue;
    const currentTeamValue = staffCost + playersValue - injuredPlayersValue;
    const dedicatedFansCost = TEAM_CREATION_CONSTANTS.dedicatedFans.cost * (data.dedicatedFans - 1);
    const teamCost = staffCost + playersCost + Math.max(dedicatedFansCost, 0);
    const treasury = Math.max(maxTeamTR, 1000) - teamCost;

    return { teamValue, currentTeamValue, teamCost, treasury };
};

export default calculateTeamValueAndCost;
