import React, { useMemo, useState } from 'react';
import { Container, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { Rosters, TeamReducerState } from 'labb/types';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'shared/components';
import { VALIDATION_ERRORS } from '../../constants';

type Props = {
    edit?: boolean;
    dispatch: any;
    team: TeamReducerState['team'];
    rosters: Rosters;
    maxTeamTR: number;
    errors: string[];
};

const TeamNameRace = ({ dispatch, team, rosters, maxTeamTR, errors }: Props) => {
    const { t } = useTranslation('teams');
    const [confirmModal, setConfirmModal] = useState({ show: false, value: null });
    const hasErrors = errors?.includes(VALIDATION_ERRORS.teamName);

    const rosterOptions = useMemo(() => {
        if (!rosters) return null;
        return rosters.map((item) => ({
            value: item.id,
            label: item.nameEs,
        }));
    }, [rosters]);

    const handleRosterChange = (option) => {
        if (team?.rosterId) {
            setConfirmModal({ show: true, value: option.value });
        } else {
            onChangeRoster(option.value);
        }
    };

    const handleTRChange = (tr) => {
        dispatch({
            type: 'updateMaxTeamTR',
            payload: {
                maxTeamTR: tr,
            },
        });
    };

    const onSubmitConfirmModal = () => {
        dispatch({ type: 'reset' });
        onChangeRoster(confirmModal.value);
        onHideConfirmModal();
    };

    const onChangeRoster = (id) => {
        dispatch({
            type: 'updateTeamRoster',
            payload: {
                rosterId: id,
                name: team?.name,
                currentRoster: rosters?.find((roster) => roster.id === id),
            },
        });
    };

    const onHideConfirmModal = () => {
        setConfirmModal({ show: false, value: null });
    };

    return (
        <Container>
            <ConfirmModal
                text={t('resetWarning')}
                show={confirmModal.show}
                onHide={onHideConfirmModal}
                onSubmit={onSubmitConfirmModal}
            />
            <Row className="p-3">
                <Col sm={6}>
                    <Form.Group>
                        <Form.Label>
                            <h3 className={hasErrors ? 'text-danger' : ''}>{t('teamName')}</h3>
                        </Form.Label>
                        <Form.Control
                            className={hasErrors ? 'border-danger' : ''}
                            name="teamName"
                            value={team?.name}
                            onChange={(e) =>
                                dispatch({ type: 'updateTeamName', payload: e.target.value })
                            }
                        />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <h3>{t('roster')}</h3>
                    <Select
                        className="p-1"
                        placeholder={t('select')}
                        value={rosterOptions?.find((option) => team?.rosterId === option.value)}
                        options={rosterOptions}
                        onChange={handleRosterChange}
                        isSearchable
                    />
                </Col>

                <Col sm={2}>
                    <h3>{t('maxTeamValue')}</h3>
                    <InputGroup>
                        <Form.Control
                            placeholder={t('defaultTR')}
                            value={maxTeamTR}
                            onChange={(e) => handleTRChange(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    );
};

export default TeamNameRace;
