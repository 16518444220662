import { TFunction } from 'i18next';
import { TeamLeague } from 'labb/types';
import { generateSchedule } from 'sports-schedule-generator';

const scheduleFixtures = (teams: TeamLeague[], returnGames: boolean, t: TFunction): any => {
    const teamsIndexes = teams.map((_, i) => String(i));
    const schedule = generateSchedule(teamsIndexes, returnGames);
    const fixtures = [];
    let pairingId = 0;
    for (let i = 0; i < schedule.length; i++) {
        const games = schedule[i].map((item) => {
            return {
                home: { ...teams[item.home] },
                away: { ...teams[item.away] },
                id: pairingId++,
            };
        });
        fixtures.push({
            title: t('round', { number: i + 1 }),
            games,
        });
    }
    return fixtures;
};

export default scheduleFixtures;
