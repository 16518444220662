import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout, Headline, LabbTable, TeamLink, Caption, CoachLink } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { fetchData } from 'labb/utils';
import { RosterStatistics, RosterRedux } from 'labb/types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ColumnDescription } from 'react-bootstrap-table-next';

const StatRosterList = () => {
    const { t } = useTranslation(['stats', 'nav']);
    const [rosters, setRosters] = useState<RosterRedux[]>(null);
    const [rosterOptions, setRosterOptions] = useState([]);
    const [rosterStats, setRosterStats] = useState<RosterStatistics>();

    useEffect(() => {
        if (!rosters) {
            fetchStats();
        }
    }, []);

    const fetchStats = async () => {
        const response = await fetchData('/roster/list');
        if (response.status === 'success') {
            setRosterOptions(
                response.data.rosters?.map((item) => {
                    return {
                        value: item.id,
                        label: item.nameEs,
                    };
                })
            );
            setRosters(response.data.rosters);
        }
    };

    const handleRosterSelect = async (item) => {
        const response = await fetchData(`/statistic/roster/${item.value}`);
        if (response.status === 'success') {
            setRosterStats(response.data.stats);
        } else {
            toast.error(t('selectRosterError', { msg: response.data.msg }));
        }
    };

    const wdlColumns: ColumnDescription[] = [
        {
            dataField: 'totalGames',
            text: t('totalGames'),
            formatter: (_cell, row) => row.win + row.draw + row.loss,
        },
        {
            dataField: 'win',
            text: t('win'),
            formatter: (_cell, row) =>
                `${row.win} (${((100 * row.win) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'draw',
            text: t('draw'),
            formatter: (_cell, row) =>
                `${row.draw} (${((100 * row.draw) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
        {
            dataField: 'loss',
            text: t('loss'),
            formatter: (_cell, row) =>
                `${row.loss} (${((100 * row.loss) / (row.win + row.draw + row.loss)).toFixed(2)}%)`,
        },
    ];

    const teamColumns: ColumnDescription[] = [
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'coachUrl',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
        },
    ];

    const playerColumns: ColumnDescription[] = [
        {
            dataField: 'player.name',
            text: t('player'),
        },
        {
            dataField: 'positional',
            text: t('positional'),
        },
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team?.id} teamName={row.team?.name} />,
        },
        {
            dataField: 'count',
            text: t('SPP'),
        },
    ];

    const bestPlayerColumns: ColumnDescription[] = [
        {
            dataField: 'player.name',
            text: t('player'),
        },
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team?.id} teamName={row.team?.name} />,
        },
        {
            dataField: 'completion',
            text: t('completion'),
        },
        {
            dataField: 'deflection',
            text: t('deflection'),
        },
        {
            dataField: 'interception',
            text: t('interception'),
        },
        {
            dataField: 'cas',
            text: t('CAS'),
        },
        {
            dataField: 'td',
            text: t('TD'),
        },
        {
            dataField: 'mvp',
            text: t('MVP'),
        },
        {
            dataField: 'spp',
            text: t('SPP'),
        },
    ];

    const rivalColumns: ColumnDescription[] = [
        {
            dataField: 'roster.name',
            text: t('roster'),
        },
    ];

    const tdcasColumns: ColumnDescription[] = [
        {
            dataField: 'tdF',
            text: t('tdF'),
            formatter: (_cell, row) => `${row.tdF} (${(row.tdF / (row.tdF + row.tdA)).toFixed(2)})`,
        },
        {
            dataField: 'tdA',
            text: t('tdA'),
            formatter: (_cell, row) => `${row.tdA} (${(row.tdA / (row.tdF + row.tdA)).toFixed(2)})`,
        },
        {
            dataField: 'casF',
            text: t('casF'),
            formatter: (_cell, row) =>
                `${row.casF} (${(row.casF / (row.casF + row.casA)).toFixed(2)})`,
        },
        {
            dataField: 'casA',
            text: t('casA'),
            formatter: (_cell, row) =>
                `${row.casA} (${(row.casA / (row.casF + row.casA)).toFixed(2)})`,
        },
    ];

    return (
        <Layout
            pageTitle={t('rosterlist')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:statistics'), link: '#' },
                { label: t('nav:rosters'), active: true },
            ]}
        >
            <Container data-testid="league-list" fluid="md" className="mb-5">
                <Headline>{t('nav:rosters')}</Headline>
                <Row>
                    <Col md={6} className="pb-4 m-auto text-center">
                        <h4>{t('selectRoster')}</h4>
                        <Select
                            name="rosterListId"
                            placeholder={t('select')}
                            options={rosterOptions}
                            onChange={handleRosterSelect}
                            isSearchable
                        />
                    </Col>
                </Row>
                <Row>
                    {!!rosterStats && (
                        <Col md={8} className="m-auto">
                            <LabbTable
                                caption={<Caption text={t('bestTeams')} />}
                                keyField="team.id"
                                data={rosterStats.teams}
                                columns={[...teamColumns, ...wdlColumns]}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!rosterStats &&
                        Object.keys(rosterStats.playerStatistics).map((item) => {
                            if (!rosterStats.playerStatistics[item]?.length) return null;
                            return (
                                <Col key={item} md={6} xl={4} className="m-auto">
                                    <LabbTable
                                        caption={<Caption text={t(`players10.${item}`)} />}
                                        keyField="player.id"
                                        data={rosterStats.playerStatistics[item]}
                                        columns={playerColumns}
                                    />
                                </Col>
                            );
                        })}
                </Row>
                <Row>
                    {!!rosterStats && (
                        <Col md={8} className="m-auto">
                            <LabbTable
                                caption={<Caption text={t('bestPlayer')} />}
                                keyField="player.id"
                                data={[rosterStats.bestPlayer]}
                                columns={bestPlayerColumns}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {!!rosterStats && (
                        <Col md={8} className="m-auto">
                            <LabbTable
                                caption={<Caption text={t('rivals')} />}
                                keyField="roster.id"
                                data={rosterStats.rivalStatistics}
                                columns={[...rivalColumns, ...wdlColumns, ...tdcasColumns]}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </Layout>
    );
};

export default StatRosterList;
