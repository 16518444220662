import { useEffect, useState } from 'react';
import { fetchData, fetchMultiple } from 'labb/utils';

const useFetch = (
    url: string | string[]
): {
    datalist: any;
    status: string;
    error: any;
} => {
    const [state, setState] = useState({
        status: 'pending',
        error: null,
        data: null,
    });

    useEffect(() => {
        if (!state.data) {
            fetchList(url);
        }
    }, []);

    const fetchList = async (url: string | string[]) => {
        let response;
        if (Array.isArray(url)) {
            response = await fetchMultiple(url);
        } else {
            response = await fetchData(url);
        }

        if (response.status === 'success') {
            setState({
                status: 'success',
                error: null,
                data: response.data,
            });
        } else {
            setState({
                status: response.status,
                error: response.data,
                data: null,
            });
        }
    };

    return {
        datalist: state.data,
        status: state.status,
        error: state.error,
    };
};

export default useFetch;
