import React, { useMemo } from 'react';
import { ListGroup, Col, Card } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { TEAM_EDIT_CONSTANTS, TEAM_CREATION_CONSTANTS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { TeamDispatch, TeamReducerState } from 'labb/types';
import TeamListItem from '../TeamListItem';
import { TeamDisplayValue } from '..';

type Props = {
    team: TeamReducerState['team'];
    currentRoster: TeamReducerState['currentRoster'];
    exclusivesCount: number;
    dispatch: TeamDispatch;
    editView?: boolean;
    publicView?: boolean;
    createView: boolean;
};

const TeamStaff = ({
    team,
    currentRoster,
    dispatch,
    exclusivesCount,
    editView,
    publicView,
    createView,
}: Props) => {
    const { t } = useTranslation('teams');

    const constants = useMemo(() => {
        if (editView) return TEAM_EDIT_CONSTANTS;
        return TEAM_CREATION_CONSTANTS;
    }, [editView]);

    const handleApothecaryChange = (checked) => {
        if (checked) {
            dispatch({ type: 'addApothecary', payload: { cost: constants.apothecary.cost } });
        } else {
            dispatch({
                type: 'removeApothecary',
                payload: { refund: editView ? 0 : constants.apothecary.cost },
            });
        }
    };

    const staffItems = [
        {
            label: t('rerolls', { cost: currentRoster.rr }),
            max: team.rerolls >= currentRoster.maxRr,
            min: team.rerolls < 1,
            addAction: 'addReroll',
            removeAction: (createView && 'removeReroll') || '',
            cost: editView ? currentRoster.rr * 2 : currentRoster.rr,
            refund: editView ? 0 : currentRoster.rr,
            value: team.rerolls,
            allowEdit: editView || createView,
        },
        {
            label: t('dedicatedFans'),
            max: team.dedicatedFans >= constants.dedicatedFans.max,
            min: team.dedicatedFans <= constants.dedicatedFans.min,
            addAction: 'addDedicatedFan',
            removeAction: 'removeDedicatedFan',
            cost: constants.dedicatedFans.cost,
            refund: constants.dedicatedFans.cost,
            value: team.dedicatedFans,
            allowEdit: createView,
        },
        {
            label: t('cheerleaders'),
            max: team.cheerleaders >= constants.cheerleaders.max,
            min: team.cheerleaders <= constants.cheerleaders.min,
            addAction: 'addCheerleader',
            removeAction: 'removeCheerleader',
            cost: constants.cheerleaders.cost,
            refund: editView ? 0 : constants.cheerleaders.cost,
            value: team.cheerleaders,
            allowEdit: editView || createView,
        },
        {
            label: t('assistants'),
            max: team.assistants >= constants.assistants.max,
            min: team.assistants <= constants.assistants.min,
            addAction: 'addAssistant',
            removeAction: 'removeAssistant',
            cost: constants.assistants.cost,
            refund: editView ? 0 : constants.assistants.cost,
            value: team.assistants,
            allowEdit: editView || createView,
        },
    ];

    return (
        <Card className="shadow-sm">
            <ListGroup className="list-group-striped" variant="flush">
                {!!currentRoster.positionalsExclusive && (
                    <ListGroup.Item className="d-flex align-items-center">
                        <Col className="pl-0">{t('exclusives')}</Col>
                        <TeamDisplayValue
                            value={t('exclusiveCount', {
                                current: exclusivesCount || 0,
                                max: currentRoster.positionalsExclusive,
                            })}
                        />
                    </ListGroup.Item>
                )}
                {staffItems.map((item) => (
                    <TeamListItem key={`staff-${item.label}`} item={item} dispatch={dispatch} />
                ))}
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                    <Col className="pl-0">
                        <span>{t('apothecary')}</span>
                    </Col>
                    <Col className="d-flex justify-content-end pr-0">
                        {!publicView ? (
                            <BootstrapSwitchButton
                                width={85}
                                checked={!!team.apothecary}
                                onlabel={t('yes')}
                                offlabel={t('no')}
                                disabled={!currentRoster.apothecary}
                                onChange={handleApothecaryChange}
                            />
                        ) : (
                            <TeamDisplayValue value={team.apothecary ? t('yes') : t('no')} />
                        )}
                    </Col>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
};

export default TeamStaff;
