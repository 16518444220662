import { useRef, useEffect, useState } from 'react';
import { fetchData } from 'labb/utils';

const useCoachName = (coachId: string, userName?: string): { coachName: string } => {
    const [coachName, setCoachName] = useState(null);
    const cache = useRef({});

    useEffect(() => {
        if (!coachId) {
            setCoachName(userName);
            return;
        }
        if (cache.current[coachId]) {
            setCoachName(cache.current[coachId]);
        } else {
            fetchCoachName(coachId);
        }
    }, []);

    const fetchCoachName = async (id) => {
        setCoachName('...');
        const response = await fetchData(`/user/${id}`);
        if (response.data) {
            cache.current[id] = response.data.user.usuario;
            setCoachName(response.data.user.usuario);
        }
    };

    return { coachName };
};

export default useCoachName;
