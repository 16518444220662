import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout, Headline, LabbTable, Caption, TeamLink, MatchLink } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { fetchData } from 'labb/utils';
import { LeagueRedux, LeagueStatistics } from 'labb/types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ColumnDescription } from 'react-bootstrap-table-next';

const StatLeagueList = () => {
    const { t } = useTranslation(['stats', 'nav']);
    const [leagues, setLeagues] = useState<LeagueRedux[]>(null);
    const [leagueOptions, setLeagueOptions] = useState([]);
    const [leagueStatistics, setLeagueStatistics] = useState<LeagueStatistics>();
    const { teams, players, games } = leagueStatistics || {};

    useEffect(() => {
        if (!leagues) {
            fetchLeagues();
        }
    }, []);

    const fetchLeagues = async () => {
        const response = await fetchData('/league/list');
        if (response.status === 'success') {
            setLeagueOptions(
                response.data.leagues?.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                })
            );
            setLeagues(response.data.leagues);
        }
    };

    const handleLeagueSelect = async (item) => {
        const response = await fetchData(`/statistic/league/${item.value}`);
        if (response.status === 'success') {
            setLeagueStatistics(response.data.stats);
        } else {
            toast.error(t('selectLeagueError', { msg: response.data.msg }));
        }
    };

    const playerColumns: ColumnDescription[] = [
        {
            dataField: 'count',
            text: t('total'),
        },
        {
            dataField: 'player.name',
            text: t('player'),
        },
        {
            dataField: 'team',
            text: t('team'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
    ];

    const teamColumns: ColumnDescription[] = [
        {
            dataField: 'ctv',
            text: t('total'),
        },
        {
            dataField: 'team',
            text: t('team'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
    ];

    const gameColumns: ColumnDescription[] = [
        {
            dataField: 'count',
            text: t('total'),
        },
        {
            dataField: 'game',
            text: t('game'),
            formatter: (_cell, row) => (
                <MatchLink
                    leagueId={row.game.leagueId}
                    gameId={row.game.id}
                    gamename={row.game.gamename}
                />
            ),
        },
        {
            dataField: 'team1',
            text: t('team1'),
            formatter: (_cell, row) => <TeamLink teamId={row.t1.id} teamName={row.t1.name} />,
        },
        {
            dataField: 'team2',
            text: t('team2'),
            formatter: (_cell, row) => <TeamLink teamId={row.t2.id} teamName={row.t2.name} />,
        },
    ];

    return (
        <Layout
            pageTitle={t('leaguelist')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:statistics'), link: '#' },
                { label: t('nav:leagues'), active: true },
            ]}
        >
            <Container data-testid="league-list" fluid="md" className="mb-5">
                <Headline>{t('nav:leagues')}</Headline>
                <Row>
                    <Col md={6} className="pb-4 m-auto text-center">
                        <h4>{t('selectLeague')}</h4>
                        <Select
                            name="leagueListId"
                            placeholder={t('select')}
                            options={leagueOptions}
                            onChange={handleLeagueSelect}
                            isSearchable
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {!!players && (
                            <Row className="mb-5">
                                {Object.keys(players).map((item) => {
                                    if (!players[item]?.length) return null;
                                    return (
                                        <Col key={item} md={6} xl={4} className="m-auto">
                                            <LabbTable
                                                caption={<Caption text={t(`players10.${item}`)} />}
                                                keyField="player.id"
                                                data={players[item]}
                                                columns={playerColumns}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                        {!!teams?.length && (
                            <Row className="mb-5">
                                <Col md={8} className="m-auto">
                                    <LabbTable
                                        caption={<Caption text={t('bestTeamsTV')} />}
                                        keyField="team.id"
                                        data={teams}
                                        columns={teamColumns}
                                    />
                                </Col>
                            </Row>
                        )}
                        {!!games && (
                            <Row className="mb-5">
                                {Object.keys(games).map((item) => {
                                    if (!games[item]?.length) return null;
                                    return (
                                        <Col key={item} md={6} className="m-auto">
                                            <LabbTable
                                                caption={<Caption text={t(`games.${item}`)} />}
                                                keyField="game.id"
                                                data={games[item]}
                                                columns={gameColumns}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default StatLeagueList;
