import React from 'react';
import { Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Layout } from 'shared/components';
import useFetch from 'labb/utils/useFetch';
import RostersItem from './item';

const Rosters = () => {
    const { t } = useTranslation(['rosters', 'nav']);
    const { datalist } = useFetch('/roster/list');
    return (
        <Layout
            pageTitle={t('nav:rosters')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:help'), link: '#' },
                { label: t('nav:rosters'), active: true },
            ]}
        >
            <Container data-testid="roster" fluid="lg" className="mt-3 mb-5">
                <Col>
                    <h2 className="font-bb">{t('title')}</h2>
                </Col>
                {datalist?.rosters?.map((item) => (
                    <RostersItem key={`Roster-${item.id}`} item={item} />
                ))}
            </Container>
        </Layout>
    );
};

export default Rosters;
