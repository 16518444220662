import React from 'react';

const ValueCostLabel = ({ cv, hiringFee }: { cv: number; hiringFee: number }) => {
    if (!hiringFee) return null;
    return (
        <div>
            {cv}
            <span className="text-small text-muted">{` (${hiringFee})`}</span>{' '}
        </div>
    );
};

export default ValueCostLabel;
