import { TFunction } from 'i18next';
import * as yup from 'yup';
import { es } from 'yup-locales';

const useProfileSchema = (t: TFunction): any => {
    yup.setLocale(es);
    return yup.object().shape({
        user: yup.string().max(15).required(),
        passw: yup.string().max(25).required().required(t('passwordRequired')),
        passw2: yup
            .string()
            .max(25)
            .required(t('passwordRequired'))
            .oneOf([yup.ref('passw'), null], t('passwordMatch')),
        email: yup.string().max(100).email().required(),
        userLevel: yup.string().required().oneOf(['c', 'e'], t('userLevelSelect')),
    });
};

export default useProfileSchema;
