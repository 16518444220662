import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { useTranslation } from 'react-i18next';
import { MatchesPlayer, getSppForPlayer } from '.';
import { injuryOptions } from 'shared/helpers';
import { GAME_ONE_PLAYER_T1, GAME_ONE_PLAYER_T2 } from '.';

type Props = {
    team: 'team1' | 'team2';
    players: { team1: MatchesPlayer[]; team2: MatchesPlayer[] };
    disable: boolean;
    setMatchPlayers: any;
    type: number;
};

const TeamSppInjuriesTable = ({ team, players, disable, setMatchPlayers, type }: Props) => {
    const { t } = useTranslation('fixtures');

    const updatePlayers = (_oldValue, newValue, row, cell) => {
        const teamPlayers = [...players[team]];
        const playerIndex = teamPlayers.findIndex((item) => item.number === row.number);
        const updatedPlayer = {
            ...teamPlayers[playerIndex],
            [cell.dataField]: newValue,
            totalSpp: getSppForPlayer(row),
        };
        teamPlayers[playerIndex] = updatedPlayer;
        setMatchPlayers({ ...players, [team]: teamPlayers });
    };

    const validateNumber = (newValue) => {
        if (isNaN(newValue) || newValue < 0) {
            return { valid: false, message: t('mustBeNumber') };
        }
        if (newValue > 20) {
            return { valid: false, message: t('mustBeMax20') };
        }
        return true;
    };

    const isDisableSPP = () => {
        return (
            (type === GAME_ONE_PLAYER_T1 && team === 'team2') ||
            (type === GAME_ONE_PLAYER_T2 && team === 'team1')
        );
    };

    const columns: any[] = [
        {
            dataField: 'id',
            text: '',
            hidden: true,
        },
        {
            dataField: 'number',
            text: t('number'),
            type: 'number',
            hidden: true,
        },
        {
            dataField: 'name',
            text: t('player'),
            editable: false,
            hidden: true,
        },
        {
            dataField: 'positional',
            text: t('positional'),
            editable: false,
            hidden: true,
        },
        {
            dataField: 'label',
            text: t('player'),
            editable: false,
            style: { width: '10rem' },
            formatter: (_cell, row) => (
                <div className="d-flex flex-column">
                    <div className="text-center">{`${row.number} - ${row.positional}`}</div>
                    <div className="text-center">
                        <em>{row.name}</em>
                    </div>
                </div>
            ),
        },
        {
            dataField: 'completion',
            text: t('completion'),
            editable: !disable,
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'deflection',
            text: t('deflection'),
            editable: !disable,
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'interception',
            text: t('interception'),
            editable: !disable,
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'cas',
            text: t('cas'),
            editable: !disable,
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'td',
            text: t('TD'),
            editable: !disable && !isDisableSPP(),
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'mvp',
            text: t('MVP'),
            editable: !disable && !isDisableSPP(),
            validator: (newValue) => validateNumber(newValue),
            type: 'number',
        },
        {
            dataField: 'totalSpp',
            text: t('totalSpp'),
            editable: false,
            type: 'number',
        },
        {
            dataField: 'injury',
            text: t('injury'),
            editable: !disable,
            style: { width: '8rem' },
            formatter: (cell) => {
                return injuryOptions(t).find((x) => x.value == cell)?.label;
            },
            editor: {
                type: Type.SELECT,
                placeholder: t('select'),
                options: injuryOptions(t),
            },
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive rounded"
            bootstrap4
            hover
            keyField="id"
            data={players[team]}
            classes="table-align-middle"
            bodyClasses="text-center"
            headerClasses="text-center"
            columns={columns}
            cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: updatePlayers,
            })}
        />
    );
};

export default TeamSppInjuriesTable;
