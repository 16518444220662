import { League as LeagueType, Conference } from 'labb/types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Caption, LabbTable, TeamLink, CoachLink } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetchData } from 'labb/utils';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { MdEdit as EditIcon } from 'react-icons/md';
import cellEditFactory from 'react-bootstrap-table2-editor';

type Props = {
    league: LeagueType;
    onClose: () => void;
};

const League = ({ league }: Props) => {
    const { t } = useTranslation('comissioner');

    const renderExtraPoints = (cell) => {
        return (
            <Row className="d-flex justify-content-center">
                <EditIcon className="text-muted mr-2" />
                {cell}
            </Row>
        );
    };

    const onExtraPointChange = async (oldValue, newValue, row) => {
        if (parseInt(oldValue) === parseInt(newValue)) return;
        const response = await fetchData(
            `league/comissioner/${league.id}/extra/${row.team.id}`,
            'put',
            {
                extraPoints: newValue,
            }
        );
        if (response.status === 'success') toast.success(t('extraPointsSuccess'));
        if (response.status === 'error') {
            toast.error(t('extraPointsFailed', { msg: response.data.msg }));
        }
    };

    const columns: ColumnDescription[] = [
        {
            dataField: 'team.id',
            text: 'team_id',
            hidden: true,
        },
        {
            dataField: 'teamUrl1',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
            editable: false,
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
            editable: false,
        },
        ...(league?.extraPoints
            ? [
                  {
                      dataField: 'extraPoints',
                      text: league?.extraPoints,
                      editable: true,
                      formatter: (cell) => renderExtraPoints(cell),
                      validator: (newValue) => {
                          if (isNaN(newValue)) {
                              return { valid: false, message: t('mustBeNumber') };
                          }
                          if (newValue > 100) {
                              return { valid: false, message: t('mustBeMax100') };
                          }
                          return true;
                      },
                  },
              ]
            : []),
    ];

    return (
        <Row>
            <Col md={12}>
                {league?.conferences?.map((item: Conference) => {
                    return (
                        <LabbTable
                            key={item.id}
                            caption={<Caption text={item.name} />}
                            keyField="team.id"
                            data={item.standings}
                            columns={columns}
                            cellEdit={cellEditFactory({
                                mode: 'click',
                                autoSelectText: true,
                                blurToSave: true,
                                afterSaveCell: onExtraPointChange,
                            })}
                        />
                    );
                })}
            </Col>
        </Row>
    );
};

export default League;
