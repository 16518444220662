import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Fixture, Layout } from 'shared/components';

const LeagueFixtureCreatePage = () => {
    const { leagueId, fixtureId } = useParams<{ leagueId: string; fixtureId: string }>();
    const { t } = useTranslation('fixtures');

    return (
        <Layout
            pageTitle={t('nav:leaguefixturecreate')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leaguefixturecreate'), active: true },
            ]}
        >
            <Fixture leagueId={leagueId} fixtureId={fixtureId} />
        </Layout>
    );
};

export default LeagueFixtureCreatePage;
