import React, { useEffect, useState } from 'react';
import { uniq } from 'underscore';
import { Container, Row, Button, Col, Form, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import Instructions from './FixtureInstructions';
import FixtureMetadata from './FixtureMetadata';
import FixtureCommonData from './FixtureCommonData';
import FixtureTeamData from './FixtureTeamData';
import { useTranslation, Trans } from 'react-i18next';
import useCreateRecordFixtureSchema from './useSchema';
import { useAuth } from 'context/auth-context';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { fetchData, fetchMultiple } from 'labb/utils';
import { Game, Team, TeamPlayer, TeamPlayerGameRecord, Roster } from 'labb/types';
import { getPositionalById } from 'labb/utils';
import LoadingSpinner from '../LoadingSpinner';

type SubmitPlayer = {
    completion: number;
    deflection: number;
    interception: number;
    cas: number;
    td: number;
    mvp: number;
    injury: number;
};

type SubmitTeamData = {
    id: number;
    name: string;
    userId: string;
    userEmail: string;
    username: string;
    td: number;
    cas: number;
    ff: number;
    tv: number;
    treasurySpent: number;
    comment: string;
    players: Array<SubmitPlayer>;
    oldTreasury: number;
    oldDf: number;
    freePositionalName: string;
};

type SubmitData = {
    id: string;
    status: number;
    gamename: string;
    t1: SubmitTeamData;
    t2: SubmitTeamData;
    userInputId: number;
    userVerifyId: number;
    dateInput: string;
    type: number;
    suddenDeath: number;
};

const initialData = {
    team1: {
        id: 0,
        name: '',
        userId: '',
        userEmail: '',
        username: '',
        td: 0,
        cas: 0,
        ff: 0,
        tv: 0,
        treasurySpent: 0,
        oldTreasury: 0,
        oldDf: 0,
        freePositionalName: '',
        comment: '',
    },
    team2: {
        id: 0,
        name: '',
        userId: '',
        userEmail: '',
        username: '',
        td: 0,
        cas: 0,
        ff: 0,
        tv: 0,
        treasurySpent: 0,
        oldTreasury: 0,
        oldDf: 0,
        freePositionalName: '',
        comment: '',
    },
    userInputId: 0,
    userVerifyId: 0,
    attendance: 0,
    recordType: -1,
    status: 0,
    gamename: '',
    suddenDeath: -1,
};

type InitialData = typeof initialData;

type FixtureGameCombo = {
    game: Game;
    team1: Team;
    team2: Team;
    closedRecord: boolean;
};

type ExtraInfoGamePlayer = {
    name: string;
    totalSpp: number;
    number: number;
    id: number;
};

export type MatchesPlayer = TeamPlayerGameRecord & ExtraInfoGamePlayer;

const SPP_INTERCEPTION = 2;
const SPP_CAS = 2;
const SPP_TD = 3;
const SPP_MVP = 4;

// type, in sync with Game.php
// 0: both players played
// 10: player one played
// 20: player two played
//const GAME_BOTH_PLAYERS = 0;
export const GAME_ONE_PLAYER_T1 = 10;
export const GAME_ONE_PLAYER_T2 = 20;

export const getSppForPlayer = (item: TeamPlayerGameRecord | MatchesPlayer): number => {
    return (
        Number(item.completion) +
        Number(item.deflection) +
        Number(item.interception * SPP_INTERCEPTION) +
        Number(item.cas * SPP_CAS) +
        Number(item.td * SPP_TD) +
        Number(item.mvp * SPP_MVP)
    );
};

type Props = {
    leagueId: string;
    fixtureId: string;
    comissionerHandling?: boolean;
    onClose?: () => void;
};

const Fixture = ({ leagueId, fixtureId, onClose, comissionerHandling }: Props) => {
    const { t } = useTranslation('fixtures');
    const createRecordFixtureSchema = useCreateRecordFixtureSchema(t);
    const history = useHistory();
    const [responseStatus, setResponseStatus] = useState('pending');
    const [fixtureData, setFixtureData] = useState<FixtureGameCombo>(null);
    const { isComissioner } = useAuth();
    const [winnings, setWinnings] = useState({ t1: 0, t2: 0 });
    const [df, setDf] = useState({ t1: 0, t2: 0 });
    const [treasury, setTreasury] = useState({ t1: 0, t2: 0 });
    const [dateInput, setDateInput] = useState('');
    const [dateVerify, setDateVerify] = useState('');
    const [initialFormData, setInitialFormData] = useState(initialData);
    const [autoComment, setAutocomment] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [matchPlayers, setMatchPlayers] = useState({ team1: [], team2: [] });
    const [freePositionals, setFreePositionals] = useState({ t1: false, t2: false });

    const rosterHasFreePositional = (roster: Roster): boolean => {
        return roster.positionals.filter((item) => item.free).length > 0;
    };
    useEffect(() => {
        if (!fixtureData) {
            fetchFixtureData();
        }
    }, []);

    useEffect(() => {
        if (fixtureData) {
            fetchFixtureData();
        }
    }, [fixtureId]);

    const setInitialMatchesPlayer = (
        players: TeamPlayer[],
        matchPlayers: TeamPlayerGameRecord[],
        gameStatus: 0 | 10 | 20, // notPlayed | recorded | verified
        roster: Roster
    ): MatchesPlayer[] => {
        // for verified records return only the players with changes _unless_ a comissioner needs to edit it
        if (gameStatus === 20 && !comissionerHandling) {
            return matchPlayers.map((matchPlayer) => {
                const player = players?.find((player) => player.id === matchPlayer.playerId);
                const { positional: positionalInfo } = getPositionalById(
                    roster,
                    player.positionalId
                );
                return {
                    ...player,
                    ...matchPlayer,
                    positional: positionalInfo.nameEs,
                    totalSpp: getSppForPlayer(matchPlayer),
                };
            }) as MatchesPlayer[];
        }

        const initialPlayers = players
            .filter((player) => player.status === 0)
            .map((player) => {
                const { positional: positionalInfo } = getPositionalById(
                    roster,
                    player.positionalId
                );
                return {
                    id: player.id,
                    number: player.number,
                    name: player.name,
                    positional: positionalInfo.nameEs,
                    completion: 0,
                    deflection: 0,
                    interception: 0,
                    cas: 0,
                    td: 0,
                    mvp: 0,
                    totalSpp: 0,
                    injury: 0,
                    playerId: player.id,
                    leagueId: Number(leagueId),
                    gameId: 0,
                    gamename: '',
                    spp: 0,
                };
            });

        if (gameStatus === 0) {
            return initialPlayers;
        } else if (comissionerHandling) {
            const playersWithChanges = matchPlayers.map((matchPlayer) => {
                const player = players?.find((player) => player.id === matchPlayer.playerId);
                const { positional: positionalInfo } = getPositionalById(
                    roster,
                    player.positionalId
                );
                return {
                    ...player,
                    ...matchPlayer,
                    positional: positionalInfo.nameEs,
                    totalSpp: getSppForPlayer(matchPlayer),
                };
            }) as MatchesPlayer[];
            return [
                ...playersWithChanges,
                ...initialPlayers.filter((player) => {
                    return !playersWithChanges?.find((pp) => Number(player.id) === pp.playerId);
                }),
            ];
        } else if (gameStatus === 10) {
            return initialPlayers.map((player) => {
                const matchPlayer = matchPlayers?.find(
                    (matchPlayer) => Number(player.id) === matchPlayer.playerId
                );
                return { ...player, ...matchPlayer };
            });
        }
    };

    const updateWinnings = (ff1, ff2, td1, td2, type) => {
        if (type === GAME_ONE_PLAYER_T1) {
            // only player one played
            setWinnings({
                t1: (ff1 + ff2 + td1) * 10000 || 0,
                t2: 0,
            });
        } else if (type === GAME_ONE_PLAYER_T2) {
            // only player two played
            setWinnings({
                t1: 0,
                t2: (ff1 + ff2 + td2) * 10000 || 0,
            });
        } else {
            // default: both played
            setWinnings({
                t1: Math.round(((ff1 + ff2) / 2 + td1) * 10000) || 0,
                t2: Math.round(((ff1 + ff2) / 2 + td2) * 10000) || 0,
            });
        }
    };

    const loadInitialValues = (loadData, comissioners, rosters) => {
        setDateInput(loadData.game.dateInput || '');
        setDateVerify(loadData.game.dateVerify || '');
        setDf({ t1: loadData.game.t1.df || 0, t2: loadData.game.t2.df || 0 });
        setTreasury({ t1: loadData.team1.treasury || 0, t2: loadData.team2.treasury || 0 });
        setAutocomment(loadData.game.autoComment || '');
        updateWinnings(
            loadData.game.t1.ff,
            loadData.game.t2.ff,
            loadData.game.t1.td,
            loadData.game.t2.td,
            loadData.game.type
        );
        setUserOptions(
            uniq(
                [
                    {
                        value: Number(loadData.game.t1.coach.id),
                        label: loadData.game.t1.coach.name,
                    },
                    {
                        value: Number(loadData.game.t2.coach.id),
                        label: loadData.game.t2.coach.name,
                    },
                    ...(comissioners.comissioners?.map((item) => ({
                        value: Number(item.code),
                        label: item.usuario,
                    })) || []),
                ],
                'value'
            )
        );

        setMatchPlayers({
            team1: setInitialMatchesPlayer(
                loadData.team1.players,
                loadData.game.t1.players,
                loadData.game.status,
                rosters.team1
            ),
            team2: setInitialMatchesPlayer(
                loadData.team2.players,
                loadData.game.t2.players,
                loadData.game.status,
                rosters.team2
            ),
        });

        setInitialFormData({
            team1: {
                ...loadData.team1,
                userId: loadData.game.t1.coach.id,
                userEmail: loadData.game.t1.coach.email,
                username: loadData.game.t1.coach.name,
                td: Number(loadData.game.t1.td) || 0,
                cas: loadData.game.t1.cas || 0,
                ff: Number(loadData.game.t1.ff) || 0,
                tv: loadData.game.t1.tv ?? loadData.team1.currentTeamValue,
                treasurySpent: Number(loadData.game.t1.treasurySpent) || 0,
                oldTreasury: Number(loadData.game.t1.oldTreasury) || loadData.team1.treasury,
                oldDf: Number(loadData.game.t1.oldDf) || loadData.team1.dedicatedFans,
                freePositionalName: loadData.game.t1.freePositional || '',
                comment: loadData.game.t1.comment || '',
            },
            team2: {
                ...loadData.team2,
                userId: loadData.game.t2.coach.id,
                userEmail: loadData.game.t2.coach.email,
                username: loadData.game.t2.coach.name,
                td: Number(loadData.game.t2.td) || 0,
                cas: loadData.game.t2.cas || 0,
                ff: Number(loadData.game.t2.ff) || 0,
                tv: loadData.game.t2.tv ?? loadData.team2.currentTeamValue,
                treasurySpent: Number(loadData.game.t2.treasurySpent) || 0,
                oldTreasury: Number(loadData.game.t2.oldTreasury) || loadData.team2.treasury,
                oldDf: Number(loadData.game.t2.oldDf) || loadData.team2.dedicatedFans,
                freePositionalName: loadData.game.t2.freePositional || '',
                comment: loadData.game.t2.comment || '',
            },
            userInputId: loadData.game.userInputId || 0,
            userVerifyId: loadData.game.userVerifyId || 0,
            recordType: loadData.game.type !== null ? Number(loadData.game.type) : -1,
            attendance: Number(loadData.game.t1.ff) + Number(loadData.game.t2.ff) || 0,
            status: loadData.game.status,
            gamename: loadData.game.gamename,
            suddenDeath: loadData.game.suddenDeath || '',
        });
    };

    const fetchFixtureData = async () => {
        const fixtureResponse = await fetchData(`/league/${leagueId}/fixture/${fixtureId}`);
        const { data: comissioners } = isComissioner
            ? await fetchData('/user/comissioner/list')
            : { data: { comissioners: [] } };

        const { data, status } = await fetchMultiple([
            `/team/${fixtureResponse.data.game.t1.team.id}`,
            `/team/${fixtureResponse.data.game.t2.team.id}`,
            '/roster/list',
        ]);
        const [team1, team2, rostersData] = data;

        const newData = {
            game: fixtureResponse.data.game,
            team1: team1.team,
            team2: team2.team,
            closedRecord: fixtureResponse.data.game.status === 20,
        };

        const rosters = {
            team1: rostersData.rosters.find((item) => item.id === team1.team.rosterId),
            team2: rostersData.rosters.find((item) => item.id === team2.team.rosterId),
        };

        setFixtureData(newData);
        setFreePositionals({
            t1: rosterHasFreePositional(rosters.team1),
            t2: rosterHasFreePositional(rosters.team2),
        });
        loadInitialValues(newData, comissioners, rosters);
        setResponseStatus(status);
    };

    const onSubmit = async (values: InitialData) => {
        const formPost: SubmitData = {
            id: fixtureId,
            status: values.status,
            gamename: values.gamename,
            userInputId: values.userInputId,
            dateInput,
            suddenDeath: values.team1.td === values.team2.td ? values.suddenDeath : -1,
            userVerifyId: values.userVerifyId || null,
            type: values.recordType,
            t1: {
                ...values.team1,
                players: matchPlayers.team1,
            },
            t2: {
                ...values.team2,
                players: matchPlayers.team2,
            },
        };

        const { status, data: response } = await fetchData(
            '/league/' +
                (comissionerHandling ? 'comissioner/' : '') +
                `${leagueId}/fixture/${fixtureId}/`,
            'post',
            formPost
        );
        if (status === 'success') {
            toast.success(t('recordModifiedSuccess', { msg: response.msg }));
            if (onClose) onClose();
            if (!comissionerHandling) {
                history.push(`/leagues/${leagueId}/fixtures`);
            }
        } else if (status === 'error') {
            toast.error(t('recordModifiedError', { msg: response.msg }));
        }
    };

    return (
        <>
            {responseStatus === 'success' ? (
                <Container data-testid="leaguefixturesview" fluid="lg">
                    <Formik
                        validationSchema={createRecordFixtureSchema}
                        onSubmit={onSubmit}
                        initialValues={initialFormData}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            const { values, touched, errors, handleChange, setFieldValue } = formik;
                            return (
                                <Row className="d-flex justify-content-around">
                                    <Col>
                                        <Form noValidate onSubmit={formik.handleSubmit}>
                                            {!comissionerHandling && (
                                                <Col className="py-3 px-0">
                                                    {fixtureData?.closedRecord ? (
                                                        <Alert variant="success">
                                                            <Trans
                                                                t={t}
                                                                i18nKey="verifiedWarning"
                                                            />
                                                        </Alert>
                                                    ) : (
                                                        <Instructions />
                                                    )}
                                                </Col>
                                            )}
                                            <h2>
                                                {fixtureData.game?.gamename}:{' '}
                                                {fixtureData.team1.name} VS {fixtureData.team2.name}
                                            </h2>
                                            <FixtureMetadata
                                                dateInput={dateInput}
                                                dateVerify={dateVerify}
                                                fixtureData={fixtureData}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                userOptions={userOptions}
                                            />
                                            <FixtureCommonData
                                                df={df}
                                                updateWinnings={updateWinnings}
                                                winnings={winnings}
                                                fixtureData={fixtureData}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                autoComment={autoComment}
                                                comissionerHandling={comissionerHandling}
                                                treasury={treasury}
                                                freePositionals={freePositionals}
                                            />
                                            <FixtureTeamData
                                                fixtureData={fixtureData}
                                                matchPlayers={matchPlayers}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setMatchPlayers={setMatchPlayers}
                                                comissionerHandling={comissionerHandling}
                                            />
                                            <Container className="d-flex p-3 justify-content-end">
                                                <Row>
                                                    {!comissionerHandling && (
                                                        <Button
                                                            onClick={() =>
                                                                history.push(
                                                                    `/leagues/${leagueId}/fixtures`
                                                                )
                                                            }
                                                            className="mr-4"
                                                            variant="danger"
                                                        >
                                                            {t('back')}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        disabled={
                                                            formik.isSubmitting ||
                                                            (fixtureData.game.status === 20 &&
                                                                !comissionerHandling)
                                                        }
                                                        className="m-auto"
                                                        variant="success"
                                                        type="submit"
                                                    >
                                                        {t('save')}
                                                    </Button>
                                                </Row>
                                            </Container>
                                        </Form>
                                    </Col>
                                </Row>
                            );
                        }}
                    </Formik>
                </Container>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export default Fixture;
