import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { fetchData } from 'labb/utils';
import { toast } from 'react-toastify';

const FeedbackBox = () => {
    const { t } = useTranslation('app');
    const [showWidget, setShowWidget] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: 'offset', options: { offset: [10, 10] } }],
    });

    const onSubmit = async (event) => {
        event.preventDefault();
        const { status, data: response } = await fetchData(`/feedback/`, 'post', {
            name: event.target.name.value,
            email: event.target.email.value,
            message: event.target.message.value,
        });
        if (status === 'success') {
            toast.success(t('feedbackSuccess'));
            setShowWidget(false);
        } else if (status === 'error') {
            toast.error(t('feedbackError', { msg: response.msg }));
        }
    };

    return (
        <div>
            <button
                style={{
                    right: '10%',
                    position: 'fixed',
                    bottom: '-2px',
                }}
                className="p-2 px-4 rounded bg-primary shadow text-light"
                ref={setReferenceElement}
                onClick={() => setShowWidget(!showWidget)}
                type="button"
            >
                {t('buttonFeedback')}
            </button>
            {showWidget && (
                <div
                    className="bg-grey p-2 rounded shadow border border-dark"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <Form onSubmit={onSubmit} className="d-flex flex-column p-3">
                        <Form.Group className="mb-2" controlId="name">
                            <Form.Label>{t('nameFeedback')}</Form.Label>
                            <Form.Control required type="name" name="name"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="email">
                            <Form.Label>{t('emailFeedback')}</Form.Label>
                            <Form.Control required type="email" name="email"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="message">
                            <Form.Label>{t('bodyFeedback')}</Form.Label>
                            <Form.Control as="textarea" rows={4} required />
                        </Form.Group>
                        <Row className="justify-between">
                            <Button
                                onClick={() => setShowWidget(!showWidget)}
                                variant="danger"
                                className="d-block py-2 px-5 m-auto rounded shadow text-light"
                            >
                                {t('feedbackClose')}
                            </Button>
                            <Button
                                variant="success"
                                className="d-block py-2 px-5 m-auto rounded shadow text-light"
                                type="submit"
                            >
                                {t('feedbackSubmit')}
                            </Button>
                        </Row>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default FeedbackBox;
