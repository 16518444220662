import React from 'react';
import { ToastContainer } from 'react-toastify';
import { IconContext } from 'react-icons';
import { AuthProvider } from './auth-context';

const AppProviders = ({ children }) => {
    return (
        <>
            <IconContext.Provider value={{ size: '1.5em', style: { verticalAlign: 'middle' } }}>
                <AuthProvider>{children}</AuthProvider>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </IconContext.Provider>
        </>
    );
};

export default AppProviders;
