import axios from 'axios';
import TokenStorage from 'labb/utils/tokenStorage';

export type ResponseStatus = 'idle' | 'success' | 'error' | 'pending';

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.API_HOST || 'http://localhost/api';

axios.interceptors.request.use(
    (config) => {
        if (TokenStorage.isAuthenticated()) {
            config.headers['Authorization'] = `Bearer ${TokenStorage.getToken()}`;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        // TODO force logout if error comes from fetching token
        if (error.config.url == '/api/token/') {
            TokenStorage.clear();

            return new Promise((_resolve, reject) => {
                reject(error);
            });
        }

        // Try request again with new token
        return TokenStorage.getNewToken()
            .then((token) => {
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${token}`;

                return new Promise((resolve, reject) => {
                    axios
                        .request(config)
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                Promise.reject(error);
            });
    }
);

const fetchData = async (
    url: string,
    method: 'delete' | 'post' | 'put' | 'get' = 'get',
    body?: Record<string, any>
): Promise<{ status: ResponseStatus; data: any }> => {
    let status: ResponseStatus = 'idle';
    let data: any = null;

    if (!url) return { data: null, status: 'error' };
    let res: any;

    switch (method) {
        case 'delete': {
            res = await axios.delete(url).catch((error) => {
                if (error.response) {
                    status = 'error';
                    data = error.response.data;
                }
            });
            break;
        }
        case 'post': {
            res = await axios.post(url, body).catch((error) => {
                if (error.response) {
                    status = 'error';
                    data = error.response.data;
                }
            });
            break;
        }
        case 'put': {
            res = await axios.put(url, body).catch((error) => {
                if (error.response) {
                    status = 'error';
                    data = error.response.data;
                }
            });
            break;
        }
        default: {
            res = await axios.get(url).catch((error) => {
                if (error.response) {
                    status = 'error';
                    data = error.response.data;
                }
            });
            break;
        }
    }

    if (status === 'idle') {
        status = 'success';
        data = res?.data;
    }

    return { status, data };
};

export default fetchData;
