import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout, Headline, LabbTable, Caption, TeamLink, LeagueLink } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { fetchData } from 'labb/utils';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { PlayerStatistics } from 'labb/types';

const StatPlayerList = () => {
    const { t } = useTranslation(['stats', 'nav']);
    const [stats, setStats] = useState<PlayerStatistics>(null);

    useEffect(() => {
        if (!stats) {
            fetchStats();
        }
    }, []);

    const fetchStats = async () => {
        const response = await fetchData('/statistic/players');
        if (response.status === 'success') {
            setStats(response.data.stats);
        }
    };

    const playerColumns: ColumnDescription[] = [
        {
            dataField: 'count',
            text: t('total'),
        },
        {
            dataField: 'player.name',
            text: t('player'),
        },
        {
            dataField: 'team',
            text: t('team'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
    ];

    const playerLeagueColumns: ColumnDescription[] = [
        ...playerColumns,
        {
            dataField: 'league_name',
            text: t('league'),
            formatter: (_cell, row) => (
                <LeagueLink leagueId={row.league?.id} leagueName={row.league?.name} />
            ),
        },
    ];

    const bestPlayerColumns: ColumnDescription[] = [
        {
            dataField: 'player.name',
            text: t('player'),
        },
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'completion',
            text: t('completion'),
        },
        {
            dataField: 'deflection',
            text: t('deflection'),
        },
        {
            dataField: 'interception',
            text: t('interception'),
        },
        {
            dataField: 'cas',
            text: t('CAS'),
        },
        {
            dataField: 'td',
            text: t('TD'),
        },
        {
            dataField: 'mvp',
            text: t('MVP'),
        },
        {
            dataField: 'spp',
            text: t('SPP'),
        },
    ];

    return (
        <Layout
            pageTitle={t('playerlist')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:statistics'), link: '#' },
                { label: t('nav:players'), active: true },
            ]}
        >
            <Container data-testid="player-list" fluid="md" className="mb-5">
                <Headline>{t('nav:players')}</Headline>
                <Row>
                    <Col>
                        {!!stats && (
                            <Row className="mb-5">
                                {Object.keys(stats.absolute).map((item) => {
                                    if (!stats.absolute[item]?.length) return null;
                                    return (
                                        <Col key={item} md={8} className="m-auto">
                                            <LabbTable
                                                caption={<Caption text={t(`players10.${item}`)} />}
                                                keyField="player.id"
                                                data={stats.absolute[item]}
                                                columns={playerColumns}
                                            />
                                            <LabbTable
                                                caption={
                                                    <Caption text={t(`players10comp.${item}`)} />
                                                }
                                                keyField="player.id"
                                                data={stats.competition[item]}
                                                columns={playerLeagueColumns}
                                            />
                                            <LabbTable
                                                caption={<Caption text={t(`best.${item}`)} />}
                                                keyField="player.id"
                                                data={[stats.best[item]]}
                                                columns={bestPlayerColumns}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default StatPlayerList;
