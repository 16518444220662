import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Col } from 'react-bootstrap';
import TeamSppInjuriesTable from './FixtureSppInjuriesTable';

// FIXME: pass only what is need to avoid rerenders
type Props = {
    matchPlayers: { team1: any; team2: any };
    setMatchPlayers: any;
    fixtureData: any;
    handleChange: any;
    values: any;
    touched: any;
    errors: any;
    comissionerHandling: boolean;
};

const FixtureTeamData = ({
    fixtureData,
    handleChange,
    values,
    touched,
    errors,
    matchPlayers,
    setMatchPlayers,
    comissionerHandling,
}: Props) => {
    const { t } = useTranslation('fixtures');
    return (
        <>
            <Card className="mb-3 border border-light-blue">
                <Card.Header className="font-weight-bold bg-light-blue">
                    {t('teamData', {
                        team: fixtureData.team1.name,
                    })}
                </Card.Header>
                <Card.Body className="bg-grey">
                    <TeamSppInjuriesTable
                        team="team1"
                        players={matchPlayers}
                        disable={fixtureData.closedRecord && !comissionerHandling}
                        setMatchPlayers={setMatchPlayers}
                        type={values.type || fixtureData.game.type}
                    />
                </Card.Body>
                <Card.Footer>
                    <Form.Label className="font-weight-bold">
                        {t('commentTeam', {
                            team: fixtureData.team1.name,
                        })}
                    </Form.Label>
                    <Form.Control
                        name="team1.comment"
                        as="textarea"
                        autoComplete="off"
                        value={values.team1.comment}
                        onChange={handleChange}
                        isInvalid={touched.team1?.comment && !!errors.team1?.comment}
                        disabled={
                            (fixtureData.closedRecord || fixtureData.game.status === 10) &&
                            !comissionerHandling
                        }
                    />
                </Card.Footer>
            </Card>

            <Card className="mb-3 border border-light-blue">
                <Card.Header className="font-weight-bold bg-light-blue">
                    {t('teamData', {
                        team: fixtureData.team2.name,
                    })}
                </Card.Header>
                <Card.Body className="bg-grey">
                    <Col>
                        <TeamSppInjuriesTable
                            team="team2"
                            players={matchPlayers}
                            disable={fixtureData.closedRecord && !comissionerHandling}
                            setMatchPlayers={setMatchPlayers}
                            type={values.type || fixtureData.game.type}
                        />
                    </Col>
                </Card.Body>
                <Card.Footer>
                    <Form.Label className="font-weight-bold">
                        {t('commentTeam', {
                            team: fixtureData.team2.name,
                        })}
                    </Form.Label>
                    <Form.Control
                        name="team2.comment"
                        as="textarea"
                        autoComplete="off"
                        value={values.team2.comment}
                        onChange={handleChange}
                        isInvalid={touched.team2?.comment && !!errors.team2?.comment}
                        disabled={fixtureData.closedRecord && !comissionerHandling}
                    />
                </Card.Footer>
            </Card>
        </>
    );
};

export default FixtureTeamData;
