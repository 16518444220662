import React from 'react';
import { Container, Col, Image, Form, Button } from 'react-bootstrap';
import { useAuth } from 'context/auth-context';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo.png';
import { useUserSchema } from './useSchema';
import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

const User = () => {
    const auth = useAuth();
    const { t } = useTranslation('login');
    const history = useHistory();
    const userSchema = useUserSchema();

    const handleSubmit = async (values) => {
        const { status, data } = await auth.login(values.user, values.passw);
        if (status === 'success') {
            history.push('/');
        } else {
            toast.error(data?.msg);
        }
    };

    const handleRecover = async (values) => {
        const msg = await auth.recoverPassword(values.user);
        toast.success(msg);
    };

    return (
        <Container fluid>
            <Image className="register-logo" src={logo} alt="Logo LABB" height="100px" />
            <Formik
                validationSchema={userSchema}
                onSubmit={handleSubmit}
                initialValues={{
                    user: '',
                    passw: '',
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <Form>
                        <Form.Group>
                            <Form.Control
                                name="user"
                                type="text"
                                autoComplete="off"
                                value={values.user}
                                isInvalid={touched.user && !!errors.user}
                                onChange={handleChange}
                                placeholder={t('userPlaceholder')}
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="user" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                name="passw"
                                type="password"
                                autoComplete="off"
                                value={values.passw}
                                isInvalid={touched.passw && !!errors.passw}
                                onChange={handleChange}
                                placeholder={t('passwordPlaceholder')}
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="passw" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Col className="mb-3 text-center">
                            <a
                                href="#recovery"
                                onClick={() => {
                                    handleRecover(values);
                                }}
                            >
                                {t('forgotPassword')}
                            </a>
                        </Col>
                        <Button
                            onClick={() => {
                                handleSubmit(values);
                            }}
                            variant="primary"
                            className="btn-block"
                            disabled={isSubmitting}
                        >
                            {t('loginButton')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default User;
