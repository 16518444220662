import React from 'react';
import { useField } from 'formik';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const FormikSwitch = ({ ...props }) => {
    const [field, _meta, helpers] = useField(props.name);
    const { setValue, setTouched, setError } = helpers;

    const setFieldProps = (selectedOptions) => {
        setValue(selectedOptions);
        setTouched(true);
        setError(undefined);
    };

    return (
        <BootstrapSwitchButton
            checked={field.value}
            onChange={(selectedOption) => setFieldProps(selectedOption)}
            {...props}
        />
    );
};

export default FormikSwitch;
