import React, { useState, useMemo, useEffect } from 'react';
import { PositionalSkill, Team } from 'labb/types';
import { fetchData } from 'labb/utils';
import { Button, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorMessage, Formik } from 'formik';
import { FormikSelect } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { usePlayersSchema } from './useSchema';

type Props = {
    team: Team;
    onClose: () => void;
};

const Players = ({ team, onClose }: Props) => {
    const { t } = useTranslation('comissioner');
    const playerSchema = usePlayersSchema();
    const [skills, setSkills] = useState<PositionalSkill[]>(null);
    const [formData, setFormData] = useState({
        id: -1,
        name: '',
        unspentSpp: 0,
        skillId: -1,
        skillValue: [],
    });

    useEffect(() => {
        loadSkills();
    }, []);

    const loadSkills = async () => {
        const response = await fetchData('/skill/list');
        setSkills(response?.data?.skills);
    };

    const playerOptions = useMemo(
        () =>
            team?.players?.map((item) => ({
                value: item.id,
                label: item.name,
            })),
        [team]
    );

    const skillOptions = useMemo(
        () =>
            skills
                ?.filter((item) => item.category != 'T')
                .map((item) => {
                    return {
                        value: item.id,
                        label: `${item.nameEs} (${item.category == 'S' ? 'F' : item.category})`,
                    };
                }),
        [skills]
    );

    const handlePlayerSelect = (item) => {
        const player = team.players.find((i) => i.id === item.value);
        setFormData({
            id: player.id,
            name: player.name,
            unspentSpp: player.unspentSpp,
            skillId: 0,
            skillValue: [],
        });
    };

    const onSubmit = async (values: any) => {
        const formPost = {
            ...values,
        };

        const { status, data } = await fetchData(
            `/team/comissioner/player/${values.id}`,
            'put',
            formPost
        );
        if (status === 'success') {
            toast.success(t('playerModifiedSuccess'));
        } else if (status === 'error') {
            toast.error(t('playerModifiedError', { msg: data.msg }));
        }
    };

    const disable = formData.id === -1;

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <Alert variant="danger">{t('warningPlayer1')}</Alert>
                </Col>
                <Col sm={12}>
                    <Alert variant="danger">{t('warningPlayer2')}</Alert>
                </Col>
            </Row>
            <Row>
                <Col sm>
                    <Col md={12} className="pb-4">
                        <h4>{t('selectPlayer')}</h4>
                        <Select
                            placeholder={t('select')}
                            options={playerOptions}
                            onChange={handlePlayerSelect}
                            isSearchable
                        />
                    </Col>
                </Col>
                <Col>
                    <Formik
                        validationSchema={playerSchema}
                        onSubmit={onSubmit}
                        initialValues={formData}
                        enableReinitialize={true}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            touched,
                            errors,
                        }) => (
                            <Row className="d-flex justify-content-around">
                                <Col>
                                    <Form>
                                        <Container
                                            className="border border-primary"
                                            style={{ backgroundColor: '#f7f7f9' }}
                                        >
                                            <Form.Group className="pt-3 px-0" as={Col}>
                                                <Form.Label className="font-weight-bold">
                                                    {t('name')}
                                                </Form.Label>
                                                <Form.Control
                                                    name="name"
                                                    type="text"
                                                    required
                                                    disabled={disable}
                                                    autoComplete="off"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={touched.name && !!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    <ErrorMessage name="assistants" />
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="pt-3 px-0" as={Col}>
                                                <Form.Label className="font-weight-bold">
                                                    {t('unspentSpp')}
                                                </Form.Label>
                                                <Form.Control
                                                    name="unspentSpp"
                                                    type="number"
                                                    required
                                                    autoComplete="off"
                                                    disabled={disable}
                                                    value={values.unspentSpp}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        touched.unspentSpp && !!errors.unspentSpp
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    <ErrorMessage name="unspentSpp" />
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="font-weight-bold">
                                                    {t('skill')}
                                                </Form.Label>
                                                <FormikSelect
                                                    name="skillId"
                                                    placeholder={t('select')}
                                                    value={values.skillValue}
                                                    disabled={disable}
                                                    onChange={(e: any) =>
                                                        setFormData({
                                                            ...formData,
                                                            skillId: e.value,
                                                            skillValue: e,
                                                        })
                                                    }
                                                    options={skillOptions}
                                                />
                                                {errors.skillId && touched.skillId && (
                                                    <div className="d-block invalid-feedback">
                                                        <ErrorMessage name="skillId" />
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Container>
                                        <Container className="p-3">
                                            <Row className="d-flex justify-content-end">
                                                <Button
                                                    className="m-auto"
                                                    variant="primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onClose();
                                                    }}
                                                >
                                                    {t('back')}
                                                </Button>
                                                <Button
                                                    disabled={isSubmitting}
                                                    className="m-auto"
                                                    variant="success"
                                                    onClick={() => {
                                                        handleSubmit(values);
                                                    }}
                                                >
                                                    {t('save')}
                                                </Button>
                                            </Row>
                                        </Container>
                                    </Form>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default Players;
