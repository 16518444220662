import React from 'react';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';
import { League, TeamLeague } from 'labb/types';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { move } from '../helpers/reorder-and-move';

type Props = {
    conferences: League['conferences'];
    sortedTeams: Array<TeamLeague[]>;
    setSortedTeams: (arg0: any) => void;
};

const Conferences = ({ conferences, sortedTeams, setSortedTeams }: Props) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const { source, destination } = result;
        // ignore moving to same droppableId as the order within a conference does not matter
        if (source.droppableId !== destination.droppableId) {
            const sInd = +source.droppableId;
            const dInd = +destination.droppableId;
            const result = move(
                sortedTeams[sInd],
                sortedTeams[dInd],
                source.index,
                destination.index,
                source.droppableId,
                destination.droppableId
            );
            if (result[sInd].length == 0 || result[dInd].length == 0) {
                return;
            }
            const newSortedTeams = [...sortedTeams];
            newSortedTeams[sInd] = result[sInd];
            newSortedTeams[dInd] = result[dInd];
            setSortedTeams(newSortedTeams.filter((group) => group.length));
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Row>
                {sortedTeams.map((teams, index) => (
                    <Col md={6} key={conferences[index].id}>
                        <Droppable droppableId={`${index}`} type="CONFERENCE">
                            {(dropProvided: DroppableProvided) => (
                                <Card ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                    <Card.Title className="p-2">
                                        {conferences[index].name}
                                    </Card.Title>
                                    <Card.Body>
                                        <ListGroup>
                                            {teams.map((team, index) => (
                                                <Draggable
                                                    key={team.team.id}
                                                    draggableId={String(team.team.id)}
                                                    index={index}
                                                >
                                                    {(dragProvided) => (
                                                        <ListGroup.Item
                                                            className="d-flex space-between align-middle row"
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                            {...dragProvided.dragHandleProps}
                                                        >
                                                            <Col xs={12} md>
                                                                {team.team.name}
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                md
                                                                className="text-muted text-right"
                                                            >
                                                                {team.coach.name}
                                                            </Col>
                                                        </ListGroup.Item>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {dropProvided.placeholder}
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            )}
                        </Droppable>
                    </Col>
                ))}
            </Row>
        </DragDropContext>
    );
};

export default Conferences;
