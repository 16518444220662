import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Layout, LeagueForm } from '../shared/components';
import { useHistory } from 'react-router-dom';

const LeaguesPage = () => {
    const { t } = useTranslation(['leagues', 'nav']);
    const history = useHistory();

    const navigateBack = () => {
        history.push('/leagues/');
    };

    return (
        <Layout
            pageTitle={t('nav:leaguesCreate')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leagues'), link: '/leagues' },
                { label: t('nav:leaguesCreate'), active: true },
            ]}
        >
            <Container className="pt-3 mb-5">
                <Row>
                    <h3 className="font-bb mb-3">{t('create')}</h3>
                </Row>
                <LeagueForm onSuccess={navigateBack} onBack={navigateBack} mode="creation" />
            </Container>
        </Layout>
    );
};

export default LeaguesPage;
