import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    onShowModal: any;
};

const TeamManagement = ({ onShowModal }: Props) => {
    const { t } = useTranslation('teams');

    const managementItems = [
        {
            title: t('historicPlayers'),
            modalType: 'historicPlayers',
        },
        { title: t('historicOrders'), modalType: 'historicOrders' },
        { title: t('teamRecords'), modalType: 'teamRecords' },
    ];

    return (
        <ButtonGroup>
            {managementItems.map((item) => (
                <Button
                    key={item.title}
                    className="border border-primary"
                    variant="light"
                    onClick={() => onShowModal(item.modalType, null)}
                >
                    {item.title}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default TeamManagement;
