import React, { useState } from 'react';
import { Container, Row, Col, ButtonGroup, Button, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Bracket, IRoundProps } from 'react-brackets';
import { useHistory } from 'react-router-dom';
import DragList from './drag-list';
import CustomSeed from './custom-seed';
import createRounds, { Rounds } from './create-rounds';
import { fetchData } from 'labb/utils';
import { reorder } from '../helpers/reorder-and-move';
import { TeamLeague } from 'labb/types';

type Props = {
    leagueId: string;
    teams: TeamLeague[];
    setTeams: (arg0: TeamLeague[]) => void;
    playoffTeams: number;
    previousRound: number;
};

const PlayOffDraw = ({ leagueId, teams, setTeams, playoffTeams, previousRound }: Props) => {
    const { t } = useTranslation(['leagueDraw']);
    const [round, setRound] = useState<Rounds>(playoffTeams as Rounds);
    const history = useHistory();

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(teams, result.source.index, result.destination.index);
        setTeams(items);
    };

    const onSubmit = async () => {
        const r: IRoundProps[] = createRounds(teams, round, t);
        if (!r) return;
        // we only generate one round so we only use r[0]
        const games = r[0].seeds.map((item, index) => {
            return {
                gamename: `${r[0].title}-${index + 1}`,
                t1: {
                    id: item.teams[0].team.id,
                    name: item.teams[0].team.name,
                },
                t2: {
                    id: item.teams[1].team.id,
                    name: item.teams[1].team.name,
                },
            };
        });

        const { status, data: response } = await fetchData(
            `/league/${leagueId}/playoffs/`,
            'post',
            {
                playoffs: {
                    round: previousRound + 1,
                    name: r[0].title,
                    games: games,
                },
            }
        );
        if (status === 'success') {
            toast.success(t('playoffSuccess'));
            history.push(`/leagues/${leagueId}`);
        } else if (status === 'error') {
            toast.error(t('playoffError', { msg: response.msg }));
        }
    };

    return (
        <Container className="pt-3">
            {teams.length > 1 ? (
                <>
                    <Row className="d-flex justify-content-center flex-column">
                        <Col className="text-center p-1 text-muted">{t('roundOf')}</Col>
                        <Col className="d-flex justify-content-center pb-4">
                            <ButtonGroup
                                className="mr-2 border border-primary"
                                aria-label="First group"
                            >
                                {[2, 4, 8, 16, 32].map((item: Rounds) => (
                                    <Button
                                        onClick={() => setRound(item)}
                                        variant={round === item ? 'primary' : 'light'}
                                        disabled={item > teams.length}
                                        key={item}
                                    >
                                        {item}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-muted p-1 text-center">{t('dragToArrange')}</div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <DragList
                                            t={t}
                                            teams={teams}
                                            provided={provided}
                                            round={round}
                                        />
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Col>
                        <Col className="d-flex">
                            <Bracket
                                rounds={createRounds(teams, round, t)}
                                renderSeedComponent={CustomSeed}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <Row className="p-5 justify-content-center">
                    <Col className="p-5" md={8}>
                        <Alert className="text-center" variant="danger">
                            {t('notEnoughTeams')}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className="p-5 justify-content-md-center">
                <Col md={2}>
                    <Button variant="danger" onClick={() => history.push(`/leagues/${leagueId}`)}>
                        {t('back')}
                    </Button>
                </Col>
                <Col md={2}>
                    <Button
                        disabled={teams.length < 2}
                        variant="success"
                        onClick={() => onSubmit()}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default PlayOffDraw;
