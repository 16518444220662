import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Layout, TeamAdmin } from 'shared/components';

const TeamsEdit = () => {
    const { t } = useTranslation(['teams', 'nav']);
    const { teamId } = useParams<{ teamId: string }>();

    return (
        <Layout
            pageTitle={t('editTeam')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:profile'), link: '#' },
                { label: t('nav:teams'), link: '/profile/teams' },
                { label: t('editTeam'), active: true },
            ]}
        >
            <TeamAdmin teamId={teamId} editView />
        </Layout>
    );
};

export default TeamsEdit;
