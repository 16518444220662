import * as yup from 'yup';
import { es } from 'yup-locales';

const useRegisterSchema = (): any => {
    yup.setLocale(es);
    return yup.object().shape({
        newuser: yup.string().min(3).max(15).required(),
        newemail: yup.string().email().max(100).required(),
    });
};

const useUserSchema = (): any => {
    yup.setLocale(es);
    return yup.object().shape({
        user: yup.string().min(3).max(15).required(),
        passw: yup.string().min(2).max(15).required(),
    });
};

export { useUserSchema, useRegisterSchema };
