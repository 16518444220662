export const TEAM_CREATION_CONSTANTS = {
    minNumberOfPlayers: 11,
    initialTreasury: 1000,
    defaultMaxTR: 1000,
    dedicatedFans: {
        max: 6,
        min: 1,
        cost: 10,
    },
    assistants: {
        max: 6,
        min: 0,
        cost: 10,
    },
    cheerleaders: {
        max: 12,
        min: 0,
        cost: 10,
    },
    apothecary: {
        max: 1,
        min: 0,
        cost: 50,
    },
    player: {
        unspentSpp: 0,
        mng: false,
        ni: 0,
    },
};

export const TEAM_EDIT_CONSTANTS = {
    dedicatedFans: {
        max: 6,
        min: 1,
        cost: 10,
    },
    assistants: {
        max: 6,
        min: 0,
        cost: 10,
    },
    cheerleaders: {
        max: 12,
        min: 0,
        cost: 10,
    },
    apothecary: {
        max: 1,
        min: 0,
        cost: 50,
    },
    player: {
        unspentSpp: 0,
        mng: false,
        ni: 0,
    },
};

export const SPP_RANDOM_PRIMARY = [3, 4, 6, 8, 10, 15];
export const SPP_CHOOSE_PRIMARY_OR_RANDOM_SECONDARY = [6, 8, 12, 16, 20, 30];
export const SPP_CHOOSE_SECONDARY = [12, 14, 18, 22, 26, 40];
export const SPP_RANDOM_CHARACTERISTIC = [18, 20, 24, 28, 32, 50];
// A player is not obliged to spend their SPP until they have enough to randomly select a characteristic improvement,
// at which point they must spend some or all of their SPP.
export const SPP_MUST_SPEND = [
    SPP_RANDOM_CHARACTERISTIC[0],
    SPP_RANDOM_CHARACTERISTIC[1],
    SPP_RANDOM_CHARACTERISTIC[2],
    SPP_RANDOM_CHARACTERISTIC[3],
    SPP_RANDOM_CHARACTERISTIC[4],
    SPP_RANDOM_CHARACTERISTIC[5],
];

export const VALIDATION_ERRORS = {
    teamRules: 'validateTeamRules',
    treasury: 'validateTreasury',
    numberOfPlayers: 'validateNumberOfPlayers',
    teamName: 'validateTeamName',
};

export const ADVANCEMENT_COSTS = {
    '0': {
        0: 3,
        10: 6,
        20: 6,
        30: 12,
        40: 18,
    },
    '1': {
        0: 4,
        10: 8,
        20: 8,
        30: 14,
        40: 20,
    },
    '2': {
        0: 6,
        10: 12,
        20: 12,
        30: 18,
        40: 24,
    },
    '3': {
        0: 8,
        10: 16,
        20: 16,
        30: 22,
        40: 28,
    },
    '4': {
        0: 10,
        10: 20,
        20: 20,
        30: 26,
        40: 32,
    },
    '5': {
        0: 15,
        10: 30,
        20: 30,
        30: 40,
        40: 50,
    },
};

// in sync with entity/Advancement.php
export const ADVANCEMENT_TYPE_OPTIONS = {
    0: 'randomPrimary',
    10: 'choosePrimary',
    20: 'randomSecondary',
    30: 'chooseSecondary',
    40: 'randomCharacteristic',
};

export const ADVANCEMENT_TYPE = {
    ...ADVANCEMENT_TYPE_OPTIONS,
    41: 'chooseCharacteristic',
    45: 'chooseCharacteristicSecondary',
    47: 'chooseCharacteristicPrimary',
};

export const ADVANCEMENT_CATEGORIES = ['A', 'G', 'M', 'P', 'S'];

export const ADVANCEMENT_CHARACTERISTICS = {
    30: 'AV',
    40: 'MA',
    50: 'PA',
    70: 'ST',
    80: 'AG',
};

// in sync with entity/Advancement.php
export const CHARACTERISTICS_NAMES = {
    0: 'MAAVUpgrade',
    5: 'MAPAAVUpgrade',
    10: 'AGPAUpgrade',
    15: 'STAGUpgrade',
    20: 'ANYUpgrade',
};
