import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation, Trans } from 'react-i18next';
import { Layout, LabbTable, RuleItem } from 'shared/components';
import { useFetch } from 'labb/utils';

const Rules = () => {
    const { status, datalist } = useFetch([
        '/rules/versions/list',
        '/rules/list',
        '/rules/roster/list',
    ]);
    const { t } = useTranslation(['rules', 'nav']);

    const rulesVersionColumns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'code',
            text: t('code'),
        },
        {
            dataField: 'description',
            text: t('description'),
        },
        {
            dataField: 'rosters',
            text: t('rosters'),
        },
    ];

    return (
        <Layout
            pageTitle={t('nav:rules')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:help'), link: '#' },
                { label: t('nav:rules'), active: true },
            ]}
        >
            <Col className="mb-5">
                <h2 className="font-bb">{t('title')}</h2>
                <span className="lead">{t('rulesIntroMessage')}</span>
            </Col>
            <Col className="mb-5">
                <h3 className="font-bb">{t('titleRulesVersion')}</h3>
                {t('rulesVersionMessage')}
            </Col>
            <Col md={8} className="mb-5 m-auto">
                {status === 'success' && datalist[0]?.rulesversion && (
                    <LabbTable
                        keyField="id"
                        data={datalist[0].rulesversion}
                        columns={rulesVersionColumns}
                    />
                )}
            </Col>
            <Col className="mb-3">
                <h3 className="font-bb">{t('rulesTitle')}</h3>
                <Trans t={t} i18nKey="rulesMessage">
                    rulesMessage_0<a href="mailto:ligalbbav@gmail.com">rulesMessage_1</a>
                </Trans>
            </Col>
            <Row data-testid="rules">
                {datalist?.[1]?.rules.map((rule) => (
                    <Col key={`rules-${rule.id}`} md={6}>
                        <RuleItem
                            title={rule.name}
                            subtitle={t('applicableRulesVersions', {
                                rulesVersions: rule?.rulesVersions?.split(',').join(', '),
                            })}
                            description={rule.description}
                        />
                    </Col>
                ))}
            </Row>
            <Row>
                <Col className="mb-3">
                    <h3 className="font-bb">{t('rosterRulesTitle')}</h3>
                    <span className="lead">{t('rosterRulesIntroMessage')}</span>
                </Col>
            </Row>
            <Row>
                {status === 'success' &&
                    datalist[2]?.rulesRoster.map((rule, index) => {
                        return (
                            <Col key={`rosterRules-${rule.id}-${index}`} md={6}>
                                <RuleItem
                                    title={rule.nameEs}
                                    subtitle={rule.rosters.split(',').join(', ')}
                                    description={rule.description}
                                />
                            </Col>
                        );
                    })}
            </Row>
        </Layout>
    );
};

export default Rules;
