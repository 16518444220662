import React from 'react';
import { ListGroup, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GiLaurels as WinnerIcon, GiDeathSkull as DeadIcon } from 'react-icons/gi';
import { useHistory } from 'react-router-dom';

type Props = {
    slide: any;
    disclaimer: string;
};

const PlayersSlide = ({ slide, disclaimer }: Props) => {
    const history = useHistory();
    const { t } = useTranslation('stats');
    const [item] = slide;

    const renderIcon = (type, position) => {
        if (type === 'dead') return <DeadIcon />;
        if (position === 1) {
            return <WinnerIcon className="text-success" />;
        }
        return <span className="pl-1">{t('position', { count: position })}</span>;
    };

    return (
        <div className="mb-3 d-flex flex-column justify-content-start">
            <h4 className="font-bb">{t(`players3.${item.type}`)}</h4>

            <ListGroup className="border shadow-sm mb-1">
                {item.items?.map(({ player, team, count }, index) => (
                    <ListGroup.Item
                        action
                        onClick={() => history.push(`/teams/${team.id}`)}
                        key={`players3-${team.id}-${index}`}
                        className="d-flex flex-nowrap align-items-center border-bottom truncate px-0"
                    >
                        <Col sm={2} className="pl-0">
                            <Button
                                as="div"
                                size="lg"
                                className="border-0 font-weight-bold bg-white"
                                variant="light"
                            >
                                {renderIcon(item.type, index + 1)}
                            </Button>
                        </Col>
                        <Col xs={8}>
                            <div className="d-flex flex-column">
                                <span
                                    className={`truncate ${index === 0 ? 'font-weight-bold' : ''}`}
                                >
                                    {player.name}&nbsp;
                                </span>
                                <span className="text-small text-muted truncate">{team.name}</span>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <div className="ml-auto lead">{count}&nbsp;</div>
                        </Col>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            {!!disclaimer && <div className="px-1 text-right text-muted">{disclaimer}</div>}
        </div>
    );
};

export default PlayersSlide;
