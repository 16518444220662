import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    leagueId: number;
    gameId: number;
    gamename: string;
};

const MatchLink = ({ leagueId, gameId, gamename }: Props) => {
    return (
        <Link to={`/leagues/${leagueId}/fixtures/${gameId}/record`} target="_blank">
            {gamename}
        </Link>
    );
};

export default MatchLink;
