import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { TFunction, useTranslation } from 'react-i18next';
import { TeamLink, CoachLink, LabbTable, Caption } from 'shared/components';
import { getGameStatusText } from 'labb/utils';
import {
    MdSave,
    MdDoneAll as VerifiedIcon,
    MdDone as RecordedIcon,
    MdDeleteForever,
} from 'react-icons/md';
import { Game } from 'labb/types';
import { fetchData } from 'labb/utils';
import { toast } from 'react-toastify';

type Props = {
    games: Game[];
    title: string;
    leagueId?: string;
    userId?: number;
    showActions?: boolean;
    isComissioner?: boolean;
    showGroupInfo?: boolean;
};

const getStatusIcon = (status: string, t: TFunction<'fixtures'>) =>
    ({
        verified: <VerifiedIcon title={t('verified')} />,
        recorded: <RecordedIcon title={t('recorded')} />,
        notPlayed: <span>{t('notPlayed')}</span>,
    }[status]);

const FixturesView = ({
    games,
    title,
    showActions,
    leagueId,
    isComissioner,
    userId,
    showGroupInfo,
}: Props) => {
    const { t } = useTranslation('fixtures');
    const history = useHistory();

    const hasConferences = Boolean(games.find((item: any) => !!item.conference));

    const deleteRecord = async (id: number) => {
        if (!confirm(t('sureDeleteRecord?'))) {
            return;
        }
        const { status, data } = await fetchData(`/league/${leagueId}/fixture/${id}`, 'delete');
        if (status === 'success') {
            toast.success(t('deleteRecordSuccess'));
            // TODO instead of going out, update the list of games
            history.push(`/leagues/${leagueId}`);
        } else if (status === 'error') {
            toast.error(t('deleteRecordError', { msg: data.msg }));
        }
    };

    const renderActions = (id: number, t1Id: number, t2Id: number, status: number) => {
        return (
            <Row className="d-flex flex-nowrap justify-content-center mx-2">
                {(isComissioner ||
                    userId == t1Id ||
                    userId == t2Id ||
                    (userId && status === 20)) && (
                    <Button
                        className="border-0"
                        size="sm"
                        title={t('openFixture')}
                        onClick={() => history.push(`/leagues/${leagueId}/fixtures/${id}/record`)}
                        variant="outline-success"
                    >
                        <MdSave />
                    </Button>
                )}
                {isComissioner && status === 20 && (
                    <Button
                        size="sm"
                        className="border-0"
                        onClick={() => deleteRecord(id)}
                        title={t('deleteRecord')}
                        variant="outline-danger"
                    >
                        <MdDeleteForever />
                    </Button>
                )}
            </Row>
        );
    };

    const gameColumns: ColumnDescription[] = [
        {
            dataField: 'gamename',
            text: t('gamename'),
            sort: true,
            hidden: !showGroupInfo,
        },
        {
            dataField: 'conference',
            text: t('conference'),
            hidden: showGroupInfo || !hasConferences,
        },
        {
            dataField: 'teamUrl1',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t1.team.id} teamName={row.t1.team.name} />
            ),
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t1.coach.email} coachName={row.t1.coach.name} />
            ),
        },
        {
            dataField: 't1.td',
            text: t('td'),
            headerAlign: 'center',
            align: 'center',
        },
        {
            dataField: 't1.cas',
            text: t('cas'),
        },
        {
            dataField: 't1.tv',
            text: t('cv'),
        },
        {
            dataField: 'teamUrl2',
            text: t('teamName'),
            formatter: (_cell, row) => (
                <TeamLink teamId={row.t2.team.id} teamName={row.t2.team.name} />
            ),
        },
        {
            dataField: 'coachUrl2',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.t2.coach.email} coachName={row.t2.coach.name} />
            ),
        },
        {
            dataField: 't2.td',
            text: t('td'),
        },
        {
            dataField: 't2.cas',
            text: t('cas'),
        },
        {
            dataField: 't2.tv',
            text: t('cv'),
        },
        {
            dataField: 'status',
            text: t('status'),
            formatter: (_cell, row) => getStatusIcon(getGameStatusText(row.status), t),
        },
        {
            dataField: 'actions',
            text: t('actions'),
            hidden: !showActions,
            isDummyField: true,
            formatter: (_cell, row) =>
                renderActions(row.id, row.t1.coach.id, row.t2.coach.id, row.status),
        },
    ];

    if (!games || games.length < 1) return null;

    return (
        <LabbTable
            caption={<Caption text={title} />}
            keyField="id"
            data={games}
            columns={gameColumns}
        />
    );
};

export default FixturesView;
