import React, { useState, useEffect } from 'react';
import { Container, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Layout, Headline, LoadingSpinner } from 'shared/components';
import { fetchData } from 'labb/utils';
import LeaguesListItem from './item';
import { useAuth } from 'context/auth-context';
import { useHistory } from 'react-router';
import { League } from 'labb/types';

export const LEAGUE_STATUS = {
    0: 'notStarted',
    10: 'inProgressRegular',
    11: 'inProgressPlayoff',
    12: 'regularFinished',
    13: 'inProgressPlayoffAfterRegular',
    20: 'finished',
    100: 'cancelled',
};

const LeaguesPage = () => {
    const { t } = useTranslation(['leagues', 'nav']);
    const history = useHistory();
    const { isComissioner } = useAuth();
    const [leagues, setLeagues] = useState<League[]>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!leagues) {
            fetchLeagues();
        }
    }, []);

    const fetchLeagues = async () => {
        const { data, status } = await fetchData('/league/list');
        if (status === 'success' && data?.leagues) {
            setLeagues(data.leagues as League[]);
            setLoading(false);
        }
    };

    const handleDataChange = (data: League[]): void => {
        setLeagues(data);
    };

    const onCreateLeague = () => {
        history.push('/leagues/create');
    };

    const activeLeagues = leagues?.filter((item) => item.status < 20);
    const finishedLeagues = leagues?.filter((item) => item.status >= 20);

    return (
        <Layout
            pageTitle={t('nav:leagues')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leagues'), active: true },
            ]}
        >
            {!loading ? (
                <Container fluid className="container-xxl mb-5">
                    <Headline>{t('leagues')}</Headline>
                    {activeLeagues && (
                        <Col>
                            <LeaguesListItem
                                items={activeLeagues}
                                onlySummary
                                isComissioner={isComissioner}
                                handleDataChange={handleDataChange}
                                caption={t('activeLeagues')}
                            />
                            {isComissioner && (
                                <Button
                                    variant="success"
                                    className="btn-block my-5 col-3 ml-auto"
                                    onClick={onCreateLeague}
                                >
                                    {t('create')}
                                </Button>
                            )}
                        </Col>
                    )}
                    {finishedLeagues && (
                        <Col>
                            <LeaguesListItem
                                items={finishedLeagues}
                                onlySummary
                                isComissioner={isComissioner}
                                handleDataChange={handleDataChange}
                                caption={t('finishedLeagues')}
                            />
                        </Col>
                    )}
                </Container>
            ) : (
                <LoadingSpinner />
            )}
        </Layout>
    );
};

export default LeaguesPage;
