import { TFunction } from 'i18next';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListGroup, Col, Badge } from 'react-bootstrap';

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    margin: `0 0 4px 0`,
    ...draggableStyle,
});

type Props = {
    provided: any;
    teams: any;
    round: number;
    t: TFunction;
};

const DragList = ({ provided, teams, round, t }: Props) => {
    return (
        <ListGroup
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="border border-primary p-1 bg-grey"
        >
            {teams.map((team, index) => {
                const isActive = index < round;
                return (
                    <Draggable key={team.team.id} draggableId={String(team.team.id)} index={index}>
                        {(provided, { isDragging }) => (
                            <ListGroup.Item
                                className={`d-flex ${
                                    isDragging
                                        ? 'bg-primary text-light'
                                        : isActive
                                        ? 'bg-secondary'
                                        : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(provided.draggableProps.style)}
                            >
                                <Col md={1} className="pl-0">
                                    {isActive && <Badge variant="primary">{index + 1}</Badge>}
                                </Col>
                                <Col md={3}>{team.team.name}</Col>
                                <Col md={8} className="d-flex justify-content-end">
                                    <Col>{team.conference}</Col>
                                    {team.standing !== 0 && (
                                        <Col className="text-right">
                                            <span
                                                className={isDragging ? 'text-light' : 'text-muted'}
                                            >
                                                <>{t('standing')}:&nbsp;</>
                                            </span>
                                            {team.standing}
                                        </Col>
                                    )}
                                </Col>
                            </ListGroup.Item>
                        )}
                    </Draggable>
                );
            })}
            {provided.placeholder}
        </ListGroup>
    );
};

export default DragList;
