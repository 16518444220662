import { LeaguesStatistics } from 'labb/types';
import { fetchData } from 'labb/utils';
import { useEffect, useState } from 'react';

const mapPlayersToSlides = (players, dead) => {
    if (!players && !dead) return null;
    const keys = Object.keys(players);
    const playersSlides = keys.map((item) => {
        return { items: [...players[item]], type: item };
    });
    const result = [...playersSlides, { items: [...dead], type: 'dead' }];
    return result.filter((el) => !!el?.items?.length);
};

const useGeneralStatistics = (): {
    winners: LeaguesStatistics['winners'];
    games: LeaguesStatistics['games'];
    players: any;
    loading: boolean;
} => {
    const [leaguesStatistics, setLeaguesStatistics] = useState<LeaguesStatistics>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!leaguesStatistics) {
            fetchList();
        }
        return () => setLeaguesStatistics(null);
    }, []);

    const fetchList = async () => {
        const response = await fetchData('/statistic/leagues');
        if (response.status === 'success') {
            setLeaguesStatistics(response.data.stats);
            setLoading(false);
        }
    };

    const playersSlides = mapPlayersToSlides(leaguesStatistics?.players, leaguesStatistics?.dead);

    return {
        winners: leaguesStatistics?.winners || null,
        games: leaguesStatistics?.games || null,
        players: playersSlides,
        loading,
    };
};

export default useGeneralStatistics;
