import { TeamState } from 'labb/types';
import { fetchData } from 'labb/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useRandomName = (rosterId: number, teamPlayers: TeamState['players']): any => {
    const { t } = useTranslation('teams');
    const [namePool, setNamePool] = useState<Record<string, Array<string>>>();

    useEffect(() => {
        const fetchRandomNames = async () => {
            const { data, status } = await fetchData(`/roster/${rosterId}/names/list`);
            if (status === 'success' && data.playernames) {
                setNamePool(data.playernames);
            }
        };

        if (rosterId) {
            fetchRandomNames();
        }
    }, [rosterId]);

    const pickRandomName = (selectedPosition: number, number: number): string => {
        if (!selectedPosition || !namePool) return;
        const standardName = (number: number) => t('standardPlayerName', { count: number });
        const usedNames = teamPlayers.reduce((acc, item) => [...acc, item.name], []);
        const shuffledPool = namePool[selectedPosition]?.sort(() => 0.5 - Math.random());
        return shuffledPool?.find((name) => !usedNames.includes(name)) ?? standardName(number);
    };

    return {
        pickRandomName,
    };
};

export default useRandomName;
