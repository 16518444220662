import React, { useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { LabbTable } from 'shared/components';
import { createTable } from '../../../helpers';
import { Roster, Team, TeamDispatch } from 'labb/types';
import './styles.scss';

type Props = {
    players: Team['players'];
    currentRoster: Roster;
    editData: number;
    dispatch: TeamDispatch;
    onHide: () => void;
};

const ChangePlayerNumberModal = ({
    players,
    currentRoster,
    editData: playerNumberToChange,
    dispatch,
    onHide,
}: Props) => {
    const { t } = useTranslation('teams');
    const [selected, setSelected] = useState(null);

    const columns: ColumnDescription[] = [
        {
            dataField: 'number',
            text: t('number'),
        },
        {
            dataField: 'name',
            text: t('player'),
            headerAlign: 'left',
            classes: 'text-left font-weight-bold',
        },
        {
            dataField: 'positionalId',
            text: t('position'),
            headerAlign: 'left',
            classes: 'text-left font-weight-bold',
            formatter: (cell: number) => {
                if (cell === 0) return '';
                return currentRoster.positionals?.find(({ positional }) => positional.id === cell)
                    ?.positional.nameEs;
            },
        },
    ];

    const onClose = () => {
        onHide();
        setSelected(null);
    };

    const onSelect = (row) => {
        if (row.number === playerNumberToChange) {
            return;
        }
        if (row.number === selected) {
            setSelected(null);
            return;
        }
        setSelected(row.number);
    };

    const data = createTable(
        players.filter((item) => item.status < 20),
        currentRoster,
        true
    );

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('changeWith', { count: 0 })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <LabbTable
                        keyField="number"
                        columns={columns}
                        data={data}
                        rowClasses={(row) => {
                            if (row.number === playerNumberToChange) return 'swap-initial-item';
                            if (row.number === selected) return 'swap-selected-item';
                        }}
                        selectRow={{
                            mode: 'radio',
                            clickToSelect: true,
                            hideSelectColumn: true,
                            onSelect: onSelect,
                        }}
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={selected === null}
                    variant="success"
                    onClick={() => {
                        dispatch({
                            type: 'swapPlayers',
                            payload: { playersArray: [playerNumberToChange, selected] },
                        });
                        onClose();
                    }}
                >
                    {t('change')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ChangePlayerNumberModal;
