import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getPositionalById, fetchData } from 'labb/utils';
import AdvancementPick from './AdvancementPick';
import CharacteristicPick from './CharacteristicPick';
import AdvancementInstructions from './Instructions';
import {
    ADVANCEMENT_CATEGORIES,
    ADVANCEMENT_CHARACTERISTICS,
    ADVANCEMENT_TYPE_OPTIONS,
    ADVANCEMENT_COSTS,
} from '../../../constants';
import { Roster, TeamPlayer, Team, TeamDispatch } from 'labb/types';

export type Advancement = {
    type: number;
    skillId: number;
    category: string;
    characteristic: number;
};

type Props = {
    editData: TeamPlayer;
    currentRoster: Roster;
    team: Team;
    dispatch: TeamDispatch;
    onHide: () => void;
};

export type SubmitData = {
    type: 0 | 10 | 20 | 30 | 40 | null;
    skillId: number;
    characteristic: number;
    category: string;
    charlist: Array<{ value: number; label: string }>;
};

const HandleAdvancementModal = ({
    team,
    editData: player,
    currentRoster,
    dispatch,
    onHide,
}: Props) => {
    const { t } = useTranslation('teams');
    const [skills, setSkills] = useState(null);
    const [pendingCharacteristicPick, setPendingCharacteristicPick] = useState<Advancement>(null);

    const [submitData, setSubmitData] = useState<SubmitData>({
        type: null,
        skillId: null,
        characteristic: null,
        category: null,
        charlist: [],
    });

    useEffect(() => {
        fetchSkills();
    }, []);

    const fetchSkills = async () => {
        const { data } = await fetchData('/skill/list');
        if (data) setSkills(data.skills);
    };

    const { unspentSpp, name, number } = player || {};

    const isRandomPick = [0, 20].includes(submitData.type);
    const isCharacteristicsPick = submitData.type === 40;
    const disableSkill = isCharacteristicsPick;
    const disableCategory = [10, 30, 40].includes(submitData.type);
    const hasSkillIfMandatory = !disableSkill && !isRandomPick && submitData.skillId;
    const hasCategoryIfMandatory =
        (!disableCategory && !!submitData.category) || submitData.type === 40;

    const disableCharacteristicPickSubmit =
        pendingCharacteristicPick &&
        !submitData.skillId &&
        submitData.characteristic === pendingCharacteristicPick.characteristic;

    const disableSubmit =
        !pendingCharacteristicPick && !hasCategoryIfMandatory && !hasSkillIfMandatory;

    useEffect(() => {
        checkAndUpdateStatus();
    }, [player]);

    const checkAndUpdateStatus = async () => {
        if (!player) return;
        const { status, data } = await fetchData(`/skill/advancement/${player.id}`);
        if (status === 'success') {
            setPendingCharacteristicPick(data?.advancement);
            updateData(data?.advancement);
        }
    };

    const onSubmit = async (values, disableNavigation) => {
        if (!confirm(t('sureAdvancement?'))) {
            return;
        }
        const formPost = {
            advancement: {
                type: values.type,
                skillId: values.skillId,
                characteristic: values.characteristic,
                category: values.category,
            },
        };
        const { status, data: response } = await fetchData(
            `/skill/advancement/${player.id}/`,
            'post',
            formPost
        );
        if (status === 'success') {
            toast.success(t('advancementSuccess'));
            const { data } = await fetchData(`/team/${team.id}`);
            dispatch({
                type: 'reload',
                payload: {
                    team: data.team,
                    treasury: data.team.treasury,
                },
            });
            if (!disableNavigation) {
                onHide();
            }
        } else if (status === 'error') {
            toast.error(t('advancementError', { msg: response.msg }));
        }
    };

    const onCharacteristicsSubmit = async () => {
        await onSubmit(submitData, true);
        await checkAndUpdateStatus();
    };

    const updateData = (pendingPick) => {
        setSubmitData({
            type: pendingPick?.type || null,
            skillId: null,
            characteristic: pendingPick?.characteristic,
            category: null,
            charlist: characteristicOptions.filter((item) =>
                filterCharacteristic(item.value, pendingPick?.characteristic)
            ),
        });
    };

    const characteristicOptions = useMemo(() => {
        return Object.entries(ADVANCEMENT_CHARACTERISTICS).map((pair) => ({
            value: Number(pair[0]),
            label: t(pair[1], { context: 'long' }),
        }));
    }, [ADVANCEMENT_CHARACTERISTICS]);

    const advancementOptions = useMemo(() => {
        return Object.entries(ADVANCEMENT_TYPE_OPTIONS).map((pair) => ({
            value: Number(pair[0]),
            label: t(pair[1]),
            cost: ADVANCEMENT_COSTS[player?.advancements?.length]?.[pair[0]],
        }));
    }, [ADVANCEMENT_TYPE_OPTIONS]);

    const categoryOptions = useMemo(() => {
        if (!skills || !currentRoster || !player?.positionalId) return null;
        const { positional: rosterInfo } = getPositionalById(currentRoster, player.positionalId);
        const options = ADVANCEMENT_CATEGORIES.map((item) => ({ value: item, label: t(item) }));
        return options.filter((item) => {
            if (submitData.type === 0) {
                return rosterInfo?.primary.split('').includes(item.value);
            } else if (submitData.type === 20) {
                return rosterInfo?.secondary.split('').includes(item.value);
            } else {
                return false;
            }
        });
    }, [submitData.type, ADVANCEMENT_CATEGORIES, player, currentRoster, skills]);

    const skillOptions = useMemo(() => {
        if (!skills || !currentRoster || !player?.positionalId) return null;
        const { positional: rosterInfo } = getPositionalById(currentRoster, player.positionalId);
        const playerSkillsIds = player.skills.map((item) => item.id);
        return skills
            ?.filter((item) => item.category !== 'T')
            .filter((item) => !playerSkillsIds.includes(item.id))
            .filter((item) => {
                if (submitData.type === 10) {
                    return rosterInfo?.primary.split('').includes(item.category);
                } else if (submitData.type === 30) {
                    return rosterInfo?.secondary.split('').includes(item.category);
                } else if (submitData.type === 40) {
                    return (
                        rosterInfo?.primary.split('').includes(item.category) ||
                        rosterInfo?.secondary.split('').includes(item.category)
                    );
                } else {
                    return false;
                }
            })
            .map((item) => {
                return {
                    value: item.id,
                    label: `${item.nameEs} (${item.category === 'S' ? 'F' : item.category})`,
                };
            });
    }, [skills, currentRoster, player, submitData.type]);

    const filterCharacteristic = (value: number, characteristic: number): boolean => {
        // in sync with entity/Advancement.php
        if (characteristic === 0) {
            return value === 30 || value === 40;
        } else if (characteristic === 5) {
            return value === 40 || value === 50 || value === 30;
        } else if (characteristic === 10) {
            return value === 60 || value === 50;
        } else if (characteristic === 15) {
            return value === 70 || value === 60;
        }
        return true;
    };

    if (!player || !skills?.length) return null;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('skillsAdmin')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-3 text-center">
                    <Col>
                        <div className="font-weight-bold pb-2">{t('numberLong')}</div>
                        <div>{number}</div>
                    </Col>
                    <Col>
                        <div className="font-weight-bold pb-2">{t('name')}</div>
                        <div>{name}</div>
                    </Col>
                    <Col>
                        <div className="font-weight-bold pb-2">{t('unspentSpp')}</div>
                        <div>{unspentSpp}</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {pendingCharacteristicPick ? (
                            <CharacteristicPick
                                pendingCharacteristicPick={pendingCharacteristicPick}
                                setSubmitData={setSubmitData}
                                submitData={submitData}
                                skillOptions={skillOptions}
                            />
                        ) : (
                            <AdvancementPick
                                setSubmitData={setSubmitData}
                                submitData={submitData}
                                skillOptions={skillOptions}
                                categoryOptions={categoryOptions}
                                player={player}
                                advancementOptions={advancementOptions}
                                disableSkill={disableSkill}
                                isRandomPick={isRandomPick}
                                disableCategory={disableCategory}
                            />
                        )}
                    </Col>
                </Row>
                <AdvancementInstructions />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onHide()}>
                    {t('back')}
                </Button>
                {isCharacteristicsPick && !pendingCharacteristicPick ? (
                    <Button variant="primary" onClick={onCharacteristicsSubmit}>
                        {t('next')}
                    </Button>
                ) : (
                    <Button
                        variant="success"
                        onClick={() => onSubmit(submitData, false)}
                        disabled={disableSubmit || disableCharacteristicPickSubmit}
                    >
                        {t('save')}
                    </Button>
                )}
            </Modal.Footer>
        </>
    );
};

export default HandleAdvancementModal;
