import React, { useEffect, useState } from 'react';
import { Layout, Headline, FixturesView, LoadingSpinner } from 'shared/components';
import { Container, Row, Button, Col, Alert, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { fetchData, useLocalStorage } from 'labb/utils';
import { League } from 'labb/types';
import { useAuth } from 'context/auth-context';

const LeagueFixturesViewPage = () => {
    const { t } = useTranslation(['fixtures']);
    const { leagueId } = useParams<{ leagueId: string }>();
    const [localStorage, setLocalStorage] = useLocalStorage(`leagueFixtureView`, null);
    const history = useHistory();
    const [league, setLeague] = useState<League>(null);
    const { isComissioner, user } = useAuth();
    const [activeKey, setActiveKey] = useState(localStorage?.[leagueId] || 'conferences');

    useEffect(() => {
        if (!league) {
            fetchLeagueData();
        }
    }, []);

    const onChangeKey = (k) => {
        setActiveKey(k);
        setLocalStorage({ ...localStorage, [leagueId]: k });
    };

    const getGamesByGamename = (league: League): any[] => {
        if (!league) return [];
        let mergedGames = [];
        let allGamenames = [];
        if (league.conferences.length) {
            league.conferences.map((item) => {
                allGamenames = item.games
                    .map((g) => g.gamename)
                    .filter((value, index, self) => self.indexOf(value) === index);
                item.games.map((game) => {
                    mergedGames = mergedGames.concat({ ...game, conference: item.name });
                });
            });
        }
        // this function is never called with playoff so we do not even collect the data in that case
        return allGamenames.map((item) => {
            return {
                gamename: item,
                games: mergedGames.filter((game) => game.gamename === item),
            };
        });
    };

    const fetchLeagueData = async () => {
        const response = await fetchData(`/league/${leagueId}`);
        setLeague(response.data.league);
    };

    const gamesByGamename = getGamesByGamename(league);

    return (
        <Layout
            data-testid="leaguefixturesview"
            pageTitle={t('nav:leaguefixtures')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leagues'), link: '/leagues' },
                { label: t('nav:leaguefixtures'), active: true },
            ]}
        >
            {league ? (
                <>
                    <Headline>{t('title', { name: league.name })}</Headline>
                    {league.status === 0 ? (
                        <Row className="p-5 justify-content-center">
                            <Col className="p-5" md={8}>
                                <Alert className="text-center" variant="danger">
                                    {t('leagueNotStarted')}
                                </Alert>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            {!!league.conferences.length && gamesByGamename && (
                                <Row>
                                    <Col md={12}>
                                        <Tabs
                                            id="fixture-tabs"
                                            className="mb-4"
                                            activeKey={activeKey}
                                            onSelect={onChangeKey}
                                        >
                                            <Tab
                                                eventKey="conferences"
                                                title={t('groupByConferences')}
                                            >
                                                <Col
                                                    sm={12}
                                                    className="py-2 justify-content-center w-100 d-flex"
                                                >
                                                    <h3 className="text-center font-bb">
                                                        {t('conferences')}
                                                    </h3>
                                                </Col>
                                                <Col sm={12} data-testid="conferencefixturesview">
                                                    {league.conferences.map((item, index) => (
                                                        <FixturesView
                                                            games={item.games}
                                                            key={index}
                                                            title={item.name}
                                                            leagueId={leagueId}
                                                            isComissioner={isComissioner}
                                                            userId={user?.code}
                                                            showGroupInfo={true}
                                                            showActions={true}
                                                        />
                                                    ))}
                                                </Col>
                                            </Tab>
                                            <Tab eventKey="games" title={t('groupByGames')}>
                                                <Col
                                                    sm={12}
                                                    className="py-2 justify-content-center w-100 d-flex"
                                                >
                                                    <h3 className="text-center font-bb">
                                                        {t('gamenames')}
                                                    </h3>
                                                </Col>
                                                <Col sm={12} data-testid="conferencefixturesview">
                                                    {gamesByGamename.map((item, index) => (
                                                        <FixturesView
                                                            games={item.games}
                                                            key={index}
                                                            title={item.gamename}
                                                            leagueId={leagueId}
                                                            isComissioner={isComissioner}
                                                            userId={user?.code}
                                                            showGroupInfo={false}
                                                            showActions={true}
                                                        />
                                                    ))}
                                                </Col>
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            )}
                            {!!league.playoffs.length && (
                                <Row>
                                    <Col
                                        sm={12}
                                        className="py-2 justify-content-center w-100 d-flex"
                                    >
                                        <h3 className="text-center font-bb">{t('playoffs')}</h3>
                                    </Col>
                                    <Col sm={12} data-testid="conferencefixturesview">
                                        {league.playoffs.map((item, index) => (
                                            <FixturesView
                                                games={item.games}
                                                key={index}
                                                title={item.name}
                                                showActions={true}
                                                leagueId={leagueId}
                                                isComissioner={isComissioner}
                                                userId={user?.code}
                                                showGroupInfo={true}
                                            />
                                        ))}
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </>
            ) : (
                <LoadingSpinner />
            )}
            <Container>
                <Row className="p-5 justify-content-md-center">
                    <Button variant="danger" onClick={() => history.push(`/leagues/${leagueId}`)}>
                        {t('back')}
                    </Button>
                </Row>
            </Container>
        </Layout>
    );
};

export default LeagueFixturesViewPage;
