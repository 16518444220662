import React, { useEffect } from 'react';
import { Card, Row, Col, ListGroup, Container } from 'react-bootstrap';
import scheduleFixtures from './create-rounds';
import { TFunction } from 'i18next';
import { League, TeamLeague } from 'labb/types';
import { move } from '../helpers/reorder-and-move';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DroppableProvided,
    DropResult,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';

type Props = {
    league: League;
    sortedTeams: Array<TeamLeague[]>;
    t: TFunction;
    rounds: any;
    setRounds: (arg0: TeamLeague[]) => void;
};

const DragFixture = ({ fixture, index }) => {
    return (
        <Draggable draggableId={`${fixture.id}`} index={index}>
            {(provided, snapshot) => (
                <ListGroup.Item
                    className={`d-flex ${snapshot.isDragging ? 'bg-light-blue' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Col className="text-right" sm={5}>
                        {fixture.home.team.name}
                    </Col>
                    <Col className="text-center" sm={2}>
                        {'VS'}
                    </Col>
                    <Col className="text-left" sm={5}>
                        {fixture.away.team.name}
                    </Col>
                </ListGroup.Item>
            )}
        </Draggable>
    );
};

const DragList = ({ games, provided }) => {
    return (
        <ListGroup className="w-100" ref={provided.innerRef} {...provided.droppableProps}>
            {games.map((fixture, index) => (
                <DragFixture key={fixture.id} fixture={fixture} index={index} />
            ))}
            {provided.placeholder}
        </ListGroup>
    );
};

const Fixtures = ({ league, sortedTeams, t, rounds, setRounds }: Props) => {
    useEffect(() => {
        setRounds(
            sortedTeams.map((confteams) => scheduleFixtures(confteams, league.returnGames, t))
        );
    }, [sortedTeams]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.type === 'ROUND') {
            const { source, destination } = result;
            const sConferenceInd = source.droppableId.split('conference-')[1];
            const dConferenceInd = destination.droppableId.split('conference-')[1];
            if (sConferenceInd !== dConferenceInd) {
                return;
            }

            const conference = rounds[sConferenceInd];
            const sourceGames = conference[source.index];
            conference[source.index] = conference[destination.index];
            conference[destination.index] = sourceGames;
            const relabeledConference = conference.map((item, index) => {
                return { ...item, title: `Jornada ${index + 1}` };
            });

            const newRounds = [...rounds];
            newRounds[sConferenceInd] = relabeledConference;
            setRounds(newRounds);
        } else {
            const { source, destination } = result;
            const sRoundInd = source.droppableId.split('-')[0];
            const sInd = source.droppableId.split('-')[1];
            const dRoundInd = destination.droppableId.split('-')[0];
            const dInd = destination.droppableId.split('-')[1];
            // ignore moving a fixture between conferences or changing order in same round
            if (sRoundInd !== dRoundInd || sInd === dInd) {
                return;
            }
            const items = move(
                rounds[sRoundInd][sInd].games,
                rounds[dRoundInd][dInd].games,
                source.index - parseInt(sInd) * rounds[sRoundInd][sInd].games.length,
                destination.index - parseInt(dInd) * rounds[dRoundInd][dInd].games.length,
                0,
                1
            );
            if (items[0].length == 0 || items[1].length == 0) {
                return;
            }
            const newRounds = [...rounds];
            newRounds[sRoundInd][sInd].games = items[0];
            newRounds[dRoundInd][dInd].games = items[1];

            setRounds(newRounds);
        }
    };

    return (
        <Row>
            {rounds?.length > 0 &&
                league.conferences.length > 0 &&
                league.conferences.map((conference, index) => (
                    <DragDropContext key={conference.id} onDragEnd={onDragEnd}>
                        <Col md className="pt-3">
                            <h4>{conference.name}</h4>
                            <Droppable droppableId={`conference-${index}`} type="ROUND">
                                {(provided: DroppableProvided) => (
                                    <Container ref={provided.innerRef} {...provided.droppableProps}>
                                        {rounds[index].map((round, idx) => (
                                            <Draggable
                                                key={round.title}
                                                draggableId={round.title}
                                                index={idx}
                                            >
                                                {(
                                                    provided: DraggableProvided,
                                                    snapshot: DraggableStateSnapshot
                                                ) => (
                                                    <Card
                                                        className={`flex-column mb-2 border border-primary ${
                                                            snapshot.isDragging
                                                                ? 'bg-light-blue'
                                                                : ''
                                                        }`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <Card.Body>
                                                            <Card.Title
                                                                className="text-muted"
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {round.title}
                                                            </Card.Title>
                                                            <Droppable
                                                                droppableId={`${index}-${idx}`}
                                                                type="FIXTURE"
                                                            >
                                                                {(
                                                                    dropProvided: DroppableProvided
                                                                ) => (
                                                                    <DragList
                                                                        games={round.games}
                                                                        provided={dropProvided}
                                                                    />
                                                                )}
                                                            </Droppable>
                                                        </Card.Body>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Container>
                                )}
                            </Droppable>
                        </Col>
                    </DragDropContext>
                ))}
        </Row>
    );
};

export default Fixtures;
