import React from 'react';
import { Form } from 'react-bootstrap';

export type Props = {
    label: string;
    value: any;
    name: string;
    touched?: any;
    errors?: any;
    handleChange?: any;
    disabled?: boolean;
    type: string;
    max?: number;
    hidden?: boolean;
};

const FixtureInput = ({
    label,
    value,
    name,
    errors,
    touched,
    disabled,
    handleChange,
    type,
    max,
    hidden,
}: Props) => (
    <Form.Group>
        <Form.Label className="font-weight-bold" hidden={hidden}>
            {label}
        </Form.Label>
        <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            isInvalid={touched && !!errors}
            disabled={disabled}
            min={type === 'number' ? 0 : ''}
            max={type === 'number' ? max : ''}
            hidden={hidden}
        />
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
);
export default FixtureInput;
