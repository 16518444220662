import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from './locales/es.json';

i18n.use(initReactI18next).init({
    resources: {
        es: translationES,
    },
    lng: 'es',
    fallbackLng: 'es',
    interpolation: {
        escapeValue: false,
    },
    react: {
        transSupportBasicHtmlNodes: true,
        defaultTransParent: 'div',
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'li', 'ul'],
    },
});
