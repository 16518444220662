import LoginPage from 'pages/Login';
import ProfilePage from 'pages/Profile';
import MainPage from 'pages/Main';
import RostersPage from 'pages/Rosters';
import RulesPage from 'pages/Rules';
import TermsPage from 'pages/Terms';
import Teams from 'pages/Teams';
import TeamsCreate from 'pages/TeamsCreate';
import TeamsEdit from 'pages/TeamsEdit';
import TeamPublicView from 'pages/TeamPublicView';
import TutorialPage from 'pages/Tutorial';
import DownloadsPage from 'pages/Downloads';
import LeagueViewPage from 'pages/LeagueView';
import LeagueDrawPage from 'pages/LeagueDraw';
import LeaguesPage from 'pages/Leagues';
import LeaguesCreate from 'pages/LeaguesCreate';
import LeagueFixturesViewPage from 'pages/LeagueFixturesView';
import LeagueFixtureCreatePage from 'pages/LeagueFixtureCreate';
import ComissionerPage from 'pages/Comissioner';
import StatCoachListPage from 'pages/StatCoachList';
import StatLeaguePage from 'pages/StatLeagueList';
import StatPlayerPage from 'pages/StatPlayerList';
import StatRosterPage from 'pages/StatRosterList';

const routes = [
    {
        path: '/comissioner',
        component: ComissionerPage,
        isPrivate: true,
    },
    {
        path: '/downloads',
        component: DownloadsPage,
    },
    {
        path: '/leagues/create',
        component: LeaguesCreate,
        isPrivate: true,
    },
    {
        path: '/leagues/:leagueId/draw',
        component: LeagueDrawPage,
        isPrivate: true,
    },
    {
        path: '/leagues/:leagueId/fixtures/:fixtureId/record',
        component: LeagueFixtureCreatePage,
        isPrivate: true,
    },
    {
        path: '/leagues/:leagueId/fixtures',
        component: LeagueFixturesViewPage,
    },
    {
        path: '/leagues/:leagueId',
        component: LeagueViewPage,
    },
    {
        path: '/leagues',
        component: LeaguesPage,
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/profile/teams/:teamId/edit',
        component: TeamsEdit,
        isPrivate: true,
    },
    {
        path: '/profile/teams/create',
        component: TeamsCreate,
        isPrivate: true,
    },
    {
        path: '/profile/teams',
        component: Teams,
        isPrivate: true,
    },
    {
        path: '/profile',
        component: ProfilePage,
        isPrivate: true,
    },
    {
        path: '/rosters',
        component: RostersPage,
    },
    {
        path: '/rules',
        component: RulesPage,
    },
    {
        path: '/stats/coach',
        component: StatCoachListPage,
    },
    {
        path: '/stats/league',
        component: StatLeaguePage,
    },
    {
        path: '/stats/player',
        component: StatPlayerPage,
    },
    {
        path: '/stats/roster',
        component: StatRosterPage,
    },
    {
        path: '/teams/:teamId',
        component: TeamPublicView,
    },
    {
        path: '/terms',
        component: TermsPage,
    },
    {
        path: '/tutorial',
        component: TutorialPage,
    },
    { path: '*', exact: true, component: MainPage },
];

export default routes;
