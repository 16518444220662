import React from 'react';
import { Card } from 'react-bootstrap';

type Props = {
    title: string;
    subtitle?: string;
    description: string;
};

const RuleItem = ({ title, subtitle, description }: Props) => (
    <Card className="mb-3">
        <Card.Body>
            <Card.Title className="text-primary font-weight-bold">{title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
            <Card.Text className="pr-2">{description}</Card.Text>
        </Card.Body>
    </Card>
);

export default RuleItem;
