import { TFunction } from 'react-i18next';

export type Option<T> = { value: T; label: string };

// in sync with src/entity/PlayerGame.php
export const injuryOptions = (t: TFunction<'fixtures'>): Option<number>[] => [
    {
        value: 0,
        label: t('ok'),
    },
    {
        value: 1,
        label: t('mng'),
    },
    {
        value: 2,
        label: t('ni+mng'),
    },
    {
        value: 3,
        label: t('-1av+mng'),
    },
    {
        value: 4,
        label: t('-1ma+mng'),
    },
    {
        value: 5,
        label: t('-1st+mng'),
    },
    {
        value: 6,
        label: t('-1pa+mng'),
    },
    {
        value: 7,
        label: t('-1ag+mng'),
    },
    {
        value: 8,
        label: t('dead'),
    },
];
