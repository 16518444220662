import React from 'react';
import { Col, Button, ListGroupItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    item: {
        id: number;
        date: string;
        text: string;
    };
    isComissioner: boolean;
    deleteNewsItem: any;
    showDelete?: boolean;
};

const NewsItem = ({ item, isComissioner, deleteNewsItem, showDelete = true }: Props) => {
    const { t } = useTranslation('news');
    return (
        <ListGroupItem>
            <Col>
                <span className="font-weight-bold">{item.date}</span>
                <span>&nbsp;-&nbsp;</span>
                <span
                    dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}
                ></span>
            </Col>
            {isComissioner && showDelete && (
                <Col md={4} className="text-right ml-auto">
                    <Button
                        variant="outline-danger"
                        className="border-0"
                        onClick={() => deleteNewsItem(item.id)}
                    >
                        {t('delete')}
                    </Button>
                </Col>
            )}
        </ListGroupItem>
    );
};

export default NewsItem;
