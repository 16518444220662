import { fetchMultiple } from 'labb/utils';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { teamInitialData } from '../reducer';
import { TeamDispatch } from 'labb/types';

const loadTeamData = (
    teamId: string,
    editView: boolean,
    publicView: boolean,
    dispatch: TeamDispatch
): { status: null | 'success' | 'error' } => {
    const { t } = useTranslation('teams');
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (Number(teamId) > 0 || !editView) loadData(teamId);
    }, []);

    const loadData = async (id) => {
        let rosters;
        let team;
        let games;

        const urlsToFetch = [
            '/roster/list',
            editView || publicView ? `/team/${id}` : '',
            editView ? `/team/${id}/games` : '',
        ].filter((el) => !!el);

        const { status, data } = await fetchMultiple(urlsToFetch);

        if (status === 'success') {
            rosters = data[0]?.rosters;
            team = data[1]?.team || null;
            games = data[2]?.games || null;
            dispatch({
                type: 'initialLoad',
                payload: {
                    maxTeamTR: teamInitialData.maxTeamTR,
                    team: team || { ...teamInitialData.team },
                    games,
                    treasury: team?.treasury ?? teamInitialData.treasury,
                    currentRoster: team
                        ? rosters.find((roster) => roster.id === team.rosterId)
                        : null,
                    rosters,
                },
            });
            setStatus('success');
        } else {
            toast.error(t('selectTeamError', { msg: data.msg }));
            setStatus('error');
        }
    };
    return { status };
};

export default loadTeamData;
