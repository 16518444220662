import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => (
    <Helmet>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href="../img/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="../img/favicon/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="../img/favicon/favicon-16x16.png" sizes="16x16" />
        <link rel="manifest" href="../img/favicon/manifest.json" />
        <link rel="mask-icon" href="../img/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
    </Helmet>
);

export default Head;
