import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Card, Button } from 'react-bootstrap';

const AdvancementInstructions = () => {
    const { t } = useTranslation('teams');

    return (
        <Accordion className="py-4">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        {t('instructions')}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Trans t={t} i18nKey="howtoSkills">
                            howtoSkills_0
                            <ol>
                                <li>howtoSkills_1</li>
                                <li>howtoSkills_2</li>
                                <li>howtoSkills_3</li>
                            </ol>
                        </Trans>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default AdvancementInstructions;
