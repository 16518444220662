import { Roster } from 'labb/types';
import { getPositionalById } from 'labb/utils';

type playerCreationRow = {
    position: number;
    ma: string;
    st: string;
    ag: string;
    av: string;
    pa: string;
    skills: any;
    cost: number;
};

const emptyRow: playerCreationRow = {
    position: 0,
    ma: '',
    st: '',
    ag: '',
    av: '',
    pa: '',
    skills: [],
    cost: 0,
};

const getValuesForPosition = (positionalId: number, roster: Roster): playerCreationRow => {
    if (!positionalId) return emptyRow;
    const currentPositional = getPositionalById(roster, positionalId);
    if (!currentPositional) return emptyRow;
    return {
        position: currentPositional.positional.id,
        ma: currentPositional.positional.ma,
        st: currentPositional.positional.st,
        ag: currentPositional.positional.ag,
        av: currentPositional.positional.av,
        pa: currentPositional.positional.pa,
        skills: currentPositional.positional.skills,
        cost: currentPositional.positional.cost,
    };
};

export default getValuesForPosition;
