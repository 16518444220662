import { TFunction } from 'i18next';
import * as yup from 'yup';
import { es } from 'yup-locales';

const useTeamsSchema = (t: TFunction): any => {
    yup.setLocale(es);
    return yup.object().shape({
        name: yup.string().max(100).required(),
        coachId: yup.number().min(1, t('mustSelectCoach')).required(),
        assistants: yup.number().min(0).max(6).required(),
        cheerleaders: yup.number().min(0).max(12).required(),
        apothecary: yup.boolean().required(),
        treasury: yup.number().min(0).required(),
        dedicatedFans: yup.number().min(0).required(),
    });
};

const usePlayersSchema = (): any => {
    yup.setLocale(es);
    return yup.object().shape({
        name: yup.string().max(100).required(),
        skillId: yup.number(),
        unspentSpp: yup.number().min(0),
    });
};

const useProfileSchema = (t: TFunction): any => {
    yup.setLocale(es);
    return yup.object().shape({
        usuario: yup.string().max(15).required(),
        passw: yup.string().max(25),
        passw2: yup
            .string()
            .max(25)
            .oneOf([yup.ref('passw'), null], t('passwordMatch')),
        email: yup.string().max(100).email().required(),
        nivel: yup.string().required().oneOf(['c', 'e'], t('userLevelSelect')),
    });
};

export { usePlayersSchema, useTeamsSchema, useProfileSchema };
