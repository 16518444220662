import { LeagueWinners } from 'labb/types';
import React from 'react';
import { Row, ListGroup, Col, Button } from 'react-bootstrap';
import { IoMdTrophy as TrophyIcon } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

const WinnersSlide: React.FC<{ slide: LeagueWinners[] }> = ({ slide }) => {
    const history = useHistory();
    return (
        <Row className="d-flex flex-column px-2">
            {slide.map((item) => {
                return (
                    <Col key={item.league.name}>
                        <ListGroup className="border mb-3 shadow-sm align-items-center">
                            <ListGroup.Item
                                action={item.league.id > 0}
                                onClick={
                                    item.league.id > 0
                                        ? () => history.push(`/leagues/${item.league.id}`)
                                        : null
                                }
                                variant="primary"
                                className="font-weight-bold truncate w-100"
                            >
                                {item.league.name}
                            </ListGroup.Item>
                            {item.winners.map((winner, index) => (
                                <ListGroup.Item
                                    action={winner.team.id > 0}
                                    onClick={
                                        winner.team.id > 0
                                            ? () => history.push(`/teams/${winner.team.id}`)
                                            : null
                                    }
                                    key={`winners-${index}`}
                                    className="d-flex flex-nowrap border-bottom truncate w-100"
                                >
                                    <Col xs={2}>
                                        <Button
                                            as="div"
                                            size="lg"
                                            className="mr-2 border-0"
                                            variant="outline-light"
                                            style={{
                                                color: winner.position === 1 ? 'gold' : 'silver',
                                            }}
                                        >
                                            <TrophyIcon />
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={10}
                                        className={`d-flex flex-column truncate ${
                                            winner.position === 1 ? 'font-weight-bold' : ''
                                        }`}
                                    >
                                        <span>{winner.team.name}</span>
                                        <span className="text-muted">{winner.coach.name}</span>
                                    </Col>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                );
            })}
        </Row>
    );
};

export default WinnersSlide;
