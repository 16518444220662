const reorder = (list: any[], startIndex: number, endIndex: number): Array<any> => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (
    source: any[],
    destination: any[],
    sIndex: number,
    dIndex: number,
    sId: number | string,
    dId: number | string
): any => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(sIndex, 1);

    destClone.splice(dIndex, 0, removed);

    const result = {};
    result[sId] = sourceClone;
    result[dId] = destClone;

    return result;
};

export { reorder, move };
