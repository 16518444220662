import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Layout, Headline } from 'shared/components';
import Terms from 'assets/docs/terms.md';
import { useAuth } from 'context/auth-context';
import { useHistory } from 'react-router-dom';

const TermsPage = () => {
    const { t } = useTranslation(['nav', 'register']);
    const auth = useAuth();
    const history = useHistory();
    return (
        <Layout
            pageTitle={t('terms')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:help'), link: '#' },
                { label: t('nav:terms'), active: true },
            ]}
        >
            <Headline>{t('terms')}</Headline>
            <Col>
                <Terms />
            </Col>
            {!auth.isUser && (
                <Col>
                    <Button
                        variant="success"
                        className="ml-auto"
                        onClick={() => history.push('/login')}
                    >
                        {t('register:registerButton')}
                    </Button>
                </Col>
            )}
        </Layout>
    );
};

export default TermsPage;
