import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb } from 'react-bootstrap';
import { useAuth } from 'context/auth-context';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem } from 'types';

type Props = { breadcrumbPath: BreadcrumbItem[] };

const Breadcrumbs = ({ breadcrumbPath }: Props) => {
    const { isUser, user } = useAuth();
    const { t } = useTranslation('nav');

    // TODO: move profile link to nav

    const items: BreadcrumbItem[] = [
        ...breadcrumbPath,
        {
            label: t('user', { val: (isUser && user.usuario) || t('guest') }),
            link: '/profile',
            testId: `breadcrumb-user-${(isUser && user.usuario) || 'guest'}`,
            active: false,
        },
    ];

    return (
        <Container fluid className="container-xxl">
            <Breadcrumb
                className="mt-3 bg-white"
                listProps={{ className: 'bg-white border rounded' }}
            >
                {items.map((item) => (
                    <Breadcrumb.Item
                        active={item.active}
                        data-testid={item.testId}
                        key={item.label}
                        linkAs={Link}
                        linkProps={{
                            to: item.link,
                        }}
                    >
                        {item.label}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </Container>
    );
};

export default Breadcrumbs;
