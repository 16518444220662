import { TeamState, Roster } from 'labb/types';

const useTeamPositionalCount = (
    teamPlayers: TeamState['players'] = [],
    currentRoster: Roster,
    filterUnhealthy: boolean
): Record<string, { max: number; count: number }> => {
    if (!currentRoster) return {};
    const qualifiedPlayers = filterUnhealthy
        ? teamPlayers.filter(({ status }) => status < 20)
        : teamPlayers;
    return currentRoster.positionals.reduce((acc, current) => {
        const positionalCount = qualifiedPlayers.filter(({ positionalId }) => {
            return positionalId === current.positional.id;
        }).length;
        return {
            ...acc,
            [current.positional.id]: {
                count: positionalCount,
                max: current.max,
                maxed: positionalCount >= current.max,
            },
        };
    }, {});
};

export default useTeamPositionalCount;
