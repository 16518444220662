import React, { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';
import BootstrapTable, { BootstrapTableProps } from 'react-bootstrap-table-next';

const LabbTable = ({
    classes,
    wrapperClasses,
    headerClasses,
    bodyClasses,
    caption,
    children,
    ...restProps
}: PropsWithChildren<BootstrapTableProps>) => (
    <Card className="mb-3">
        <Card.Body>
            <Card.Title>{caption}</Card.Title>
            <BootstrapTable
                bootstrap4
                hover
                condensed
                striped
                {...restProps}
                classes={`shadow-sm table-align-middle bg-light ${classes}`}
                bodyClasses={`border-0 rounded text-center ${bodyClasses}`}
                headerClasses={`bg-light-blue border-0 border-bottom text-center rounded ${headerClasses}`}
                wrapperClasses={`table-responsive rounded  ${wrapperClasses}`}
            />
        </Card.Body>
        {children}
    </Card>
);

export default LabbTable;
