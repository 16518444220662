import React from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { LabbTable, MatchLink } from 'shared/components';
import { Team, TeamGameAction } from 'labb/types';

type Props = {
    team: Team;
    onHide: () => void;
};

type TableData = TeamGameAction & {
    idx: number;
};

const gameActionToText = (action: number, t: TFunction): string => {
    switch (action) {
        case 0:
            return t('addReroll');
        case 10:
            return t('removeReroll');
        case 20:
            return t('addCheerleader');
        case 30:
            return t('removeCheerleader');
        case 40:
            return t('addAssistant');
        case 50:
            return t('removeAssistant');
        case 60:
            return t('addApothecary');
        case 70:
            return t('removeApothecary');
        case 80:
            return t('buyPlayer');
        case 90:
            return t('removePlayer');
        default:
            return '';
    }
};

const generateTableData = (team: Team): TableData[] => {
    return team.gameActions.map((gameAction, idx) => {
        return {
            ...gameAction,
            idx,
        };
    });
};

const HistoricOrdersModal = ({ team, onHide }: Props) => {
    const { t } = useTranslation('teams');

    const columns: ColumnDescription[] = [
        {
            dataField: 'action',
            text: t('action'),
            formatter: (_cell, row) => gameActionToText(row.action, t),
        },
        {
            dataField: 'cost',
            text: t('cost'),
        },
        {
            dataField: 'playerName',
            text: t('name'),
        },
        {
            dataField: 'match',
            text: t('match'),
            formatter: (_cell, { leagueId, gameId, gamename }) => (
                <MatchLink leagueId={leagueId} gameId={gameId} gamename={gamename} />
            ),
        },
    ];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('historicOrders')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <LabbTable keyField="idx" data={generateTableData(team)} columns={columns} />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    {t('back')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default HistoricOrdersModal;
