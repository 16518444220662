import { TFunction } from 'i18next';
import { TeamLeague } from 'labb/types';
import { IRoundProps } from 'react-brackets';

export type Rounds = 2 | 4 | 8 | 16 | 32;

const getRoundTitle = (round: Rounds) => {
    const titles = {
        2: 'roundOf2',
        4: 'roundOf4',
        8: 'roundOf8',
        16: 'roundOf16',
        32: 'roundOf32',
    };
    return titles[round];
};

const generateRoundOrder = (numPlayers: number): any => {
    const rounds = Math.log(numPlayers) / Math.log(2) - 1;
    let pls = [0, 1];
    for (let i = 0; i < rounds; i++) {
        pls = nextLayer(pls);
    }
    const out: any[] = [];
    for (let i = 0; i < pls.length; i += 2) {
        out.push([pls[i], pls[i + 1]]);
    }
    return out;
};
const nextLayer = (pls: any): any => {
    const out: any[] = [];
    const length = pls.length * 2;
    pls.forEach(function (d: number) {
        out.push(d, length - d - 1);
    });
    return out;
};

const createRounds = (sortedTeams: TeamLeague[], round: Rounds, t: TFunction): IRoundProps[] => {
    if (sortedTeams.length < round) return;
    const roundIndexes = generateRoundOrder(round);
    return [
        {
            title: t(getRoundTitle(round)),
            seeds: roundIndexes.map((roundIndex, index) => {
                return {
                    id: index + 1,
                    teams: [
                        {
                            ...sortedTeams[roundIndex[0]],
                            badge: roundIndex[0] + 1,
                        },
                        {
                            ...sortedTeams[roundIndex[1]],
                            badge: roundIndex[1] + 1,
                        },
                    ],
                };
            }),
        },
    ];
};

export default createRounds;
