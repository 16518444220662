import React, { useState } from 'react';
import { Preuser } from 'labb/types';
import { fetchData } from 'labb/utils';
import { Button, Container, Row } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { LabbTable } from 'shared/components';
import { MdSave, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

type Props = {
    preusers: Preuser[];
    onUpdate: () => void;
};

const Waitinglist = ({ preusers, onUpdate }: Props) => {
    const { t } = useTranslation('comissioner');
    const [list, setList] = useState<Preuser[]>(preusers);

    const addUser = async (newuser: string, newemail: string) => {
        const response = await fetchData('/user/', 'post', { newuser, newemail });
        if (response.status === 'success') {
            setList(response.data.preusers);
            if (onUpdate) onUpdate();
        } else {
            toast.error(t('waitingListAddError', { msg: response.data.msg }));
        }
    };

    const deletePreuser = async (id: number) => {
        const response = await fetchData(`/user/pre/${id}`, 'delete');
        if (response.status === 'success') {
            setList(response.data.preusers);
            if (onUpdate) onUpdate();
        }
    };

    const renderActions = (id: number, name: string, email: string) => {
        return (
            <Row className="d-flex flex-nowrap justify-content-center mx-4">
                <Button
                    className="border-0"
                    size="sm"
                    onClick={() => addUser(name, email)}
                    title={t('confirm')}
                    variant="outline-success"
                >
                    <MdSave />
                </Button>
                <Button
                    className="border-0"
                    size="sm"
                    onClick={() => deletePreuser(id)}
                    title={t('reject')}
                    variant="outline-danger"
                >
                    <MdDelete />
                </Button>
            </Row>
        );
    };
    const columns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'name',
            text: t('userName'),
        },
        {
            dataField: 'email',
            text: t('email'),
        },
        {
            dataField: 'dateSignUp',
            text: t('signUpDate'),
        },
        {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: (_cell, row) => renderActions(row.id, row.name, row.email),
        },
    ];

    return (
        <Container data-testid="waiting-list" fluid="md" className="mb-5">
            <LabbTable keyField="id" data={list} columns={columns} />
        </Container>
    );
};

export default Waitinglist;
