import { Game } from 'labb/types';
import React from 'react';
import { Row, ListGroup, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const TeamItem = ({ item, winner, history, id }) => {
    const validId = id > 0;
    return (
        <ListGroup.Item
            action={validId}
            onClick={validId ? () => history.push(`/teams/${id}`) : null}
            className="border-bottom pl-3 pb-2 d-flex flex-nowrap align-items-center"
        >
            <Col xs={2} className="pl-0">
                <Button as="div" className="mr-3 border border-primary" variant="light">
                    <strong>{item.td}</strong>
                </Button>
            </Col>
            <Col xs={10} className={`d-flex flex-column ${winner ? 'font-weight-bold' : ''}`}>
                <span className="truncate">{item.team.name}</span>
                <span className="text-small truncate text-muted">{item.coach.name}</span>
            </Col>
        </ListGroup.Item>
    );
};

const GameSlide: React.FC<{ slide: Game[] }> = ({ slide }) => {
    const history = useHistory();
    return (
        <Row className="d-flex flex-column px-2">
            {slide.map((item, index) => {
                return (
                    <Col key={`matches-${index}`}>
                        <ListGroup className="border mb-3 shadow-sm">
                            <ListGroup.Item
                                action={item.league.id > 0}
                                onClick={() => history.push(`/leagues/${item.league.id}/fixtures`)}
                                variant="primary"
                                className="truncate"
                            >
                                <span className="font-weight-bold">{item.gamename}</span>
                                <span>&nbsp;-&nbsp;</span>
                                <span>{item.league.name}</span>
                            </ListGroup.Item>
                            <TeamItem
                                item={item.t1}
                                winner={item.t1.td > item.t2.td}
                                history={history}
                                id={item.t1.team.id}
                            />
                            <TeamItem
                                item={item.t2}
                                winner={item.t1.td < item.t2.td}
                                history={history}
                                id={item.t2.team.id}
                            />
                        </ListGroup>
                    </Col>
                );
            })}
        </Row>
    );
};

export default GameSlide;
