import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { LabbTable, MatchLink } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ADVANCEMENT_CHARACTERISTICS, ADVANCEMENT_TYPE } from '../../../constants';
import { injuryOptions } from 'shared/helpers';

type Props = {
    editData: any;
    onHide: () => void;
};

const PlayerRecordModal = ({ editData: player, onHide }: Props) => {
    const { t } = useTranslation('teams');
    const { t: t2 } = useTranslation('fixtures');

    const advancementColumns: ColumnDescription[] = [
        {
            dataField: 'type',
            text: t('type'),
            formatter: (cell: number) => t(ADVANCEMENT_TYPE[cell]),
        },
        {
            dataField: 'skillId',
            text: t('skill'),
            formatter: (cell) => player.skills?.find((skill) => skill.id === cell)?.nameEs || '-',
        },
        {
            dataField: 'characteristic',
            text: t('characteristic'),
            formatter: (cell: number) => {
                return cell ? t(ADVANCEMENT_CHARACTERISTICS[cell], { context: 'long' }) : '-';
            },
        },
        {
            dataField: 'gameId',
            text: t('match'),
            formatter: (_cell, { leagueId, gameId, gamename }) => (
                <MatchLink leagueId={leagueId} gameId={gameId} gamename={gamename} />
            ),
        },
    ];

    const playerGameRecordColumns: ColumnDescription[] = [
        {
            dataField: 'completion',
            text: t('completion'),
        },
        {
            dataField: 'deflection',
            text: t('deflection'),
        },
        {
            dataField: 'interception',
            text: t('interception'),
        },
        {
            dataField: 'cas',
            text: t('cas'),
        },
        {
            dataField: 'td',
            text: t('td'),
        },
        {
            dataField: 'mvp',
            text: t('mvp'),
        },
        {
            dataField: 'injury',
            text: t('injury'),
            formatter: (cell) => {
                return injuryOptions(t2).find((x) => x.value == cell).label;
            },
        },
        {
            dataField: 'spp',
            text: t('totalSpp'),
        },
        {
            dataField: 'gamename',
            text: t('match'),
            formatter: (_cell, { leagueId, gameId, gamename }) => (
                <MatchLink leagueId={leagueId} gameId={gameId} gamename={gamename} />
            ),
        },
    ];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {player?.number} - {player?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    {!!player?.advancements?.length && (
                        <LabbTable
                            caption={t('advancements')}
                            keyField="skillId"
                            columns={advancementColumns}
                            data={player.advancements}
                        />
                    )}
                    {!!player?.playerGameRecords?.length && (
                        <LabbTable
                            caption={t('playerGameRecords')}
                            keyField="gameId"
                            columns={playerGameRecordColumns}
                            data={player.playerGameRecords}
                        />
                    )}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    {t('back')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default PlayerRecordModal;
