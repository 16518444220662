import React from 'react';
import { Row, Form, Button, ListGroup, Card } from 'react-bootstrap';
import { useAuth } from 'context/auth-context';
import { useTranslation } from 'react-i18next';
import { useNews } from 'labb/utils';
import { NewsItem } from 'shared/components';

type Props = {
    leagueNews: any;
    showDelete: boolean;
    leagueId: number;
};

type NewsItem = {
    id: number;
    date: string;
    text: string;
};

const NewsListItem = ({ leagueNews = [], showDelete, leagueId }: Props) => {
    const { t } = useTranslation('news');
    const { isComissioner } = useAuth();
    const { news, newsInput, setNewsInput, addNewsItem, deleteNewsItem } = useNews(
        `/league/${leagueId}/news`,
        leagueNews
    );

    if (!news?.length && !isComissioner) return null;

    return (
        <Card className="my-3">
            <Card.Body>
                <Card.Title>
                    <h3 className="font-bb">{t('leagueNews')}</h3>
                </Card.Title>
                <ListGroup>
                    {news?.map?.((item: NewsItem) => (
                        <NewsItem
                            key={item.id}
                            item={item}
                            isComissioner={isComissioner}
                            deleteNewsItem={deleteNewsItem}
                            showDelete={showDelete}
                        />
                    ))}
                </ListGroup>

                {isComissioner && showDelete && (
                    <Card.Footer>
                        <Row className="d-flex flex-column">
                            <Form className="col-12 p-3">
                                <Form.Group>
                                    <Form.Label className="col-12" htmlFor="email">
                                        {t('newEntry')}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="newstext"
                                        maxLength={2000}
                                        required
                                        autoComplete="off"
                                        value={newsInput}
                                        onChange={(e) => setNewsInput(e.target.value)}
                                    />
                                </Form.Group>
                                <Button
                                    variant="success"
                                    disabled={!newsInput}
                                    className="btn-block my-5 col-3 ml-auto"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addNewsItem();
                                    }}
                                >
                                    {t('add')}
                                </Button>
                            </Form>
                        </Row>
                    </Card.Footer>
                )}
            </Card.Body>
        </Card>
    );
};

export default NewsListItem;
