import { Team, Roster } from 'labb/types';
import React from 'react';
import { Container, Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TeamPlayers from '../../TeamPlayers';

type Props = {
    players: Team['players'];
    currentRoster: Roster;
    onHide: () => void;
};

const HistoricPlayersModal = ({ players, currentRoster, onHide }: Props) => {
    const { t } = useTranslation('teams');

    const tables = [
        {
            title: t('firedPlayers'),
            players: players.filter((item) => item.status === 20),
        },
        {
            title: t('cemetery'),
            players: players.filter((item) => item.status === 30),
        },
        {
            title: t('playersQuit'),
            players: players.filter((item) => item.status === 60),
        },
    ];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('historicPlayers')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    {tables.map(({ players, title }) => {
                        if (!players.length) return null;
                        return (
                            <Row key={title}>
                                <Col>
                                    <h4>{title}</h4>
                                </Col>
                                <TeamPlayers
                                    players={players}
                                    currentRoster={currentRoster}
                                    disableActions
                                />
                            </Row>
                        );
                    })}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    {t('back')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default HistoricPlayersModal;
