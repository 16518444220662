import React from 'react';
import { Container, Row, Nav, Tab } from 'react-bootstrap';
import useFetch from 'labb/utils/useFetch';
import User from './components/User';
import Register from './components/Register';
import Guest from './components/Guest';
import { useTranslation, Trans } from 'react-i18next';
import './styles.scss';

const LoginPage = () => {
    const { t } = useTranslation('login');

    // TODO: consider moving this out to custom hook
    const { status, datalist } = useFetch([
        '/match/labb3/count',
        '/match/count',
        '/team/labb3/count',
        '/team/count',
        '/user/count',
    ]);

    return (
        <Container fluid className="register-body">
            <Tab.Container id="tabs" defaultActiveKey="login">
                <Row className="form-signin bg-light">
                    <Nav
                        variant="pills"
                        className=" mb-3 w-100 justify-content-center"
                        defaultActiveKey="login"
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="login">{t('userTab')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="register">{t('registerTab')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="guest">{t('guestTab')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="container-fluid">
                        <Tab.Pane eventKey="login">
                            <User />
                        </Tab.Pane>
                        <Tab.Pane eventKey="register">
                            <Register />
                        </Tab.Pane>
                        <Tab.Pane eventKey="guest">
                            <Guest />
                        </Tab.Pane>
                    </Tab.Content>
                </Row>
            </Tab.Container>

            <div className="statusMsg alert alert-danger mt-4 text-center" role="alert">
                <span></span>
            </div>

            <div className="register-footer bg-light text-center d-flex p-4">
                <div className="text-center w-100 h-100">
                    {status === 'success' && (
                        <Trans t={t} className="m-0" i18nKey="stats">
                            Matches:{' '}
                            {{
                                matchesCount:
                                    Number(datalist[0]?.count || 0) +
                                    Number(datalist[1]?.count || 0),
                            }}{' '}
                            | Teams:{' '}
                            {{
                                teamsCount:
                                    Number(datalist[2]?.count || 0) +
                                    Number(datalist[3]?.count || 0),
                            }}{' '}
                            | Coaches: {{ userCount: datalist[4]?.count }} | Since: 10/04/1999
                        </Trans>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default LoginPage;
