import React, { useEffect, useState } from 'react';
import { Container, Row, Tabs, Tab, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchMultiple } from 'labb/utils';
import { ResponseStatus } from 'labb/utils/fetchData';
import { Layout } from 'shared/components';
import PlayOffDraw from './playoff';
import RegularDraw from './regular';
import { League, TeamLeague } from 'labb/types';

const LeagueDrawPage = () => {
    const { t } = useTranslation(['leagueDraw']);
    const { leagueId } = useParams<{ leagueId: string }>();
    const [activeKey, setKey] = useState<'regular' | 'playoff'>('regular');
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>('pending');
    const [teams, setTeams] = useState<TeamLeague[]>(null);
    const [league, setLeague] = useState<League>(null);
    // disable draw mode completely when leagues are in progress (regular) or finished or cancelled
    const disableAll = [10, 20, 100].includes(league?.status);
    const playoffRound = league?.playoffs?.length - 1;

    useEffect(() => {
        if (!teams) {
            fetchTeamsData();
        }
    }, []);

    const fetchTeamsData = async () => {
        const response = await fetchMultiple([`/league/${leagueId}/teams`, `/league/${leagueId}`]);
        // only valid status to draw a league are:
        // 0 (new, either regular or playoff)
        // 12 (regular finished -> show playoff)
        // 11 or 13 (playoff in progress -> draw next round)
        setTeams(response.data[0].teams);
        if (
            response.data[1].league.type !== 0 &&
            ((response.data[1].league.type === 20 && response.data[1].league.status === 0) ||
                response.data[1].league.status === 11 ||
                response.data[1].league.status === 12 ||
                response.data[1].league.status === 13)
        ) {
            setKey('playoff');
        }
        setLeague(response.data[1].league);
        setResponseStatus(response.status);
    };

    return (
        <Layout
            pageTitle={t('nav:leagueDraw')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leagues'), link: '/leagues' },
                { label: t('nav:leagueDraw'), active: true },
            ]}
        >
            <Container className="mb-5" data-testid="leaguedraw">
                <Row>
                    <Col>
                        <h3 className="font-bb my-4">{t('leagueDraw')}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Tabs
                            id="draw-tabs"
                            activeKey={activeKey}
                            onSelect={(k: 'playoff' | 'regular') => setKey(k)}
                        >
                            <Tab
                                eventKey="regular"
                                title={t('regular')}
                                disabled={activeKey === 'playoff' || disableAll}
                            >
                                {responseStatus === 'success' && (
                                    <RegularDraw league={league} teams={teams} />
                                )}
                            </Tab>
                            <Tab
                                eventKey="playoff"
                                title={t('playoff')}
                                disabled={activeKey === 'regular' || disableAll}
                            >
                                {responseStatus === 'success' && (
                                    <PlayOffDraw
                                        leagueId={leagueId}
                                        teams={teams}
                                        setTeams={setTeams}
                                        playoffTeams={Math.min(league.playoffTeams, teams.length)}
                                        previousRound={playoffRound}
                                    />
                                )}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};
export default LeagueDrawPage;
