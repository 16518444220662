import React, { useRef, useEffect } from 'react';
import { ListGroup, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TeamReducerState } from 'labb/types';

type Props = {
    doUndo: () => void;
    history: TeamReducerState['history'];
};

const TeamUndoHistory = ({ history, doUndo }: Props) => {
    const actionHistoryEndRef = useRef(null);
    const { t } = useTranslation('teams');

    const disabledUndo = history.length < 1;

    useEffect(() => {
        scrollToBottom();
    }, [history]);

    const renderActionsHistory = () => {
        const actionsHistory = [...history];
        return actionsHistory.map((item, index) => (
            <ListGroup.Item style={{ minHeight: '2.5rem' }} key={index}>
                {/* FIXME there are some labels that require a value e.g. 'addPlayer' and we don't have it here so we show wrong label */}
                {t(item)}
            </ListGroup.Item>
        ));
    };

    const scrollToBottom = () => {
        actionHistoryEndRef.current?.scrollIntoView({ behaviour: 'smooth' });
    };

    return (
        <Card className="shadow-sm">
            <Card.Body>
                <Card.Text className="font-weight-bold">{t('history')}</Card.Text>
                <ListGroup
                    style={{
                        height: '8rem',
                        overflow: 'auto',
                    }}
                    className="list-group-striped horizontal"
                >
                    {renderActionsHistory()}
                    <div ref={actionHistoryEndRef}></div>
                </ListGroup>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
                <Button
                    onClick={doUndo}
                    disabled={disabledUndo}
                    className="m-auto"
                    variant="primary"
                >
                    {t('undo')}
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default TeamUndoHistory;
