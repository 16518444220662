import React, { useState } from 'react';
import { Layout, Headline } from 'shared/components';
import { ListGroup, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Registration from 'assets/docs/registration.md';
import CreateTeam from 'assets/docs/createTeam.md';
import EditTeam from 'assets/docs/editTeam.md';
import SignUpLeague from 'assets/docs/signUpLeague.md';
import CreateFixture from 'assets/docs/createFixture.md';
import ManageSpp from 'assets/docs/manageSpp.md';
import './styles.scss';
import TutorialModal from './components/TutorialModal';

const TUTORIAL_PAGES = {
    registration: <Registration />,
    createTeam: <CreateTeam />,
    editTeam: <EditTeam />,
    signUpLeague: <SignUpLeague />,
    createFixture: <CreateFixture />,
    manageSpp: <ManageSpp />,
};

const TutorialPage = () => {
    const { t } = useTranslation('tutorial');
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (title: string) => {
        setModalTitle(title);
        setShow(true);
    };

    const renderModalBody = (item) => TUTORIAL_PAGES[item];

    return (
        <Layout
            pageTitle={t('nav:tutorial')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:help'), link: '#' },
                { label: t('nav:tutorial'), active: true },
            ]}
        >
            <Headline>{t('title')}</Headline>
            <Col className="mt-3" md={4}>
                <ListGroup>
                    {Object.keys(TUTORIAL_PAGES).map((item) => {
                        return (
                            <ListGroup.Item action key={item} onClick={() => handleShow(item)}>
                                {t(item)}
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </Col>
            <TutorialModal show={show} onHide={handleClose} title={t(modalTitle)}>
                {renderModalBody(modalTitle)}
            </TutorialModal>
        </Layout>
    );
};

export default TutorialPage;
