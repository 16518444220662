import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Headline } from 'shared/components';
import { Row, Col, ListGroup } from 'react-bootstrap';

const DownloadsPage = () => {
    const { t } = useTranslation('downloads');
    return (
        <Layout
            pageTitle={t('nav:downloads')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:help'), link: '#' },
                { label: t('nav:downloads'), active: true },
            ]}
        >
            <Headline>{t('title')}</Headline>
            <Row>
                <Col className="mt-3" md={4}>
                    <ListGroup>
                        <ListGroup.Item
                            action
                            href="/downloads/Normativa_XX_LBBAV.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('rules')}
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup>
                        <ListGroup.Item
                            action
                            href="/downloads/Historia_LABB.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('history')}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Layout>
    );
};

export default DownloadsPage;
