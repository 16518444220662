import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LeaguesListItem from '../Leagues/item';
import { Headline, Layout, WinnersListItem, LoadingSpinner } from 'shared/components';
import ConferenceListItem from './components/Conference/item';
import PlayoffListItem from './components/Playoff/item';
import { fetchData } from 'labb/utils';
import NewsListItem from './components/News/item';
import { useAuth } from 'context/auth-context';

const LeagueViewPage = () => {
    const { t } = useTranslation(['leagues', 'nav']);
    const history = useHistory();
    const { leagueId } = useParams<{ leagueId: string }>();

    const [league, setLeague] = useState(null);
    const { isComissioner } = useAuth();

    useEffect(() => {
        if (Number(leagueId) < 0) history.push('/');
        if (!league) {
            fetchLeagueData();
        }
    }, []);

    const fetchLeagueData = async () => {
        const response = await fetchData(`/league/${leagueId}`);
        setLeague(response?.data?.league);
    };

    return (
        <Layout
            data-testid="leagueview"
            pageTitle={league?.name || t('leagueView')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:leagues'), link: '/leagues' },
                { label: league?.name || t('leagueView'), active: true },
            ]}
        >
            {league ? (
                <>
                    <Headline>{league.name}</Headline>
                    <LeaguesListItem
                        items={[league]}
                        isComissioner={isComissioner}
                        classNames="pb-3"
                    />
                    <NewsListItem
                        leagueNews={league.news}
                        showDelete={league.status < 20}
                        leagueId={league.id}
                    />
                    <ConferenceListItem
                        conferences={league.conferences}
                        extraPoints={league.extraPoints}
                        leagueStatus={league.status}
                    />
                    <PlayoffListItem items={league.playoffs} />
                    <WinnersListItem items={league.winners} />
                </>
            ) : (
                <LoadingSpinner />
            )}
        </Layout>
    );
};
export default LeagueViewPage;
