import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ListGroup, Col, Row } from 'react-bootstrap';
import { fetchData } from 'labb/utils';
import { useTranslation } from 'react-i18next';
import { Team, TeamDispatch } from 'labb/types';
import TeamDisplayValue from '../TeamDisplayValue';

type Props = { team: Team; dispatch: TeamDispatch; allowEdit: boolean };

const TeamLeaguePicker = ({ team, dispatch, allowEdit }: Props) => {
    const { t } = useTranslation('teams');
    const [leagueOptions, setLeagueOptions] = useState();

    useEffect(() => {
        fetchOpenLeagues();
    }, []);

    const fetchOpenLeagues = async () => {
        const { data } = await fetchData('/league/list/open');
        if (data.leagues) {
            const leagueOptions = createLeagueOptions(data.leagues);
            setLeagueOptions(leagueOptions);
        }
    };

    const createLeagueOptions = (openLeagues) => {
        const removeLeage = { value: null, label: t('noLeague') };
        const mappedLeagues = openLeagues.map(({ id, name }) => ({ value: id, label: name }));
        return team.league?.id ? [...new Set([removeLeage, ...mappedLeagues])] : mappedLeagues;
    };

    const selectedLeague = { value: team?.league?.id, label: team?.league?.name };

    return (
        <ListGroup.Item>
            <Row className="d-flex justify-content-between align-items-center">
                <Col sm={12} className="mb-2">
                    <span>{t('league')}</span>
                </Col>
                <Col data-test-id="edit-league" sm={12} className="d-flex justify-content-end px-0">
                    {allowEdit ? (
                        <Select
                            className="w-100"
                            onChange={(option) =>
                                dispatch({
                                    type: 'updateLeague',
                                    payload: { name: option.label, id: option.value },
                                })
                            }
                            options={leagueOptions}
                            value={selectedLeague}
                            styles={{
                                option: (styles, { data }) => ({
                                    ...styles,
                                    color: data.label === t('noLeague') ? 'red' : undefined,
                                }),
                            }}
                            placeholder={t('noLeague')}
                        />
                    ) : (
                        <TeamDisplayValue value={team?.league?.name} />
                    )}
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default TeamLeaguePicker;
