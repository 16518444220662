import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Layout, LoadingSpinner } from 'shared/components';
import useGeneralStatistics from './use-general-statistics';
import News from './components/News';
import StatisticsCarousel from './components/StatisticsCarousel';

const Main = () => {
    const { t } = useTranslation(['nav', 'stats']);
    const { winners, games, players, loading } = useGeneralStatistics();
    return (
        <Layout
            withJumbotron
            pageTitle={t('main')}
            breadcrumbPath={[{ label: t('main'), active: true }]}
            data-testid="main"
        >
            {!loading ? (
                <Row>
                    <Col sm={12} md={6} xl={3} className="order-1">
                        <StatisticsCarousel
                            title={t('stats:recentMatches')}
                            items={games}
                            type="games"
                            itemsPerSlide={4}
                        />
                    </Col>
                    <Col sm={12} xl={4} className="order-4 order-xl-2">
                        <Row>
                            <Col md={6} xl={12}>
                                <StatisticsCarousel items={players} type="players" />
                            </Col>
                            <Col md={6} xl={12}>
                                <News />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} xl={5} className="order-3">
                        <StatisticsCarousel
                            title={t('stats:leagueWinners')}
                            items={winners}
                            type="winners"
                            itemsPerSlide={4}
                        />
                    </Col>
                </Row>
            ) : (
                <LoadingSpinner />
            )}
        </Layout>
    );
};

export default Main;
