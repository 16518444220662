import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Button } from 'react-bootstrap';
import {
    LabbTable,
    NamePositionalLabel,
    AdvancementLabel,
    ValueCostLabel,
} from 'shared/components';
import {
    MdAdd as AddIcon,
    MdDeleteForever as RemoveIcon,
    MdRepeat as ChangeIcon,
    MdTrendingUp as LevelUpIcon,
    MdContentCopy as CopyIcon,
    MdEdit as EditIcon,
} from 'react-icons/md';
import { IoBandageOutline as BandageIcon } from 'react-icons/io5';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { TeamReducerState, TeamDispatch, Team } from 'types';
import { createTable, getValuesForPosition } from '../../helpers';
import { VALIDATION_ERRORS, ADVANCEMENT_COSTS } from '../../constants';

type Props = {
    players: Team['players'];
    currentRoster: TeamReducerState['currentRoster'];
    errors?: TeamReducerState['errors'];
    dispatch?: TeamDispatch;
    onShowModal?: any;
    editView?: boolean;
    publicView?: boolean;
    createView?: boolean;
    disableActions?: boolean;
    pickRandomName?: any;
    positionalCount?: any;
};

const TeamPlayers = ({
    editView,
    publicView,
    createView,
    dispatch,
    onShowModal,
    players,
    currentRoster,
    errors,
    disableActions,
    pickRandomName,
    positionalCount,
}: Props) => {
    const { t } = useTranslation('teams');
    const [tableData, setTableData] = useState(
        createTable(players, currentRoster, createView || editView)
    );
    const hasErrors = errors?.includes(VALIDATION_ERRORS.numberOfPlayers);

    useEffect(() => {
        setTableData(createTable(players, currentRoster, createView || editView));
    }, [players]);

    const shouldShowCopy = (row, rowIndex) => {
        const hasName = !!row.name;
        const positionalNotMaxed = !positionalCount[row.positionalId]?.maxed;
        const nextRowEmpty = !tableData[rowIndex + 1]?.name;
        const isLastRow = rowIndex === 15;
        return hasName && positionalNotMaxed && nextRowEmpty && !isLastRow;
    };

    const playerCanSpendSpp = (unspentSpp: number, skills): boolean => {
        const numberSkills = skills.filter((item) => item.type === 'acquired')?.length || 0;
        return unspentSpp >= 0 && unspentSpp >= ADVANCEMENT_COSTS[numberSkills]?.[0]; // check if you can spend the cheapest advance at least
    };
    const renderActions = (cell, row, rowIndex) => {
        return (
            <Row className="d-flex flex-nowrap justify-content-end mx-4">
                {cell.add && (
                    <Button
                        className="border-0"
                        size="sm"
                        onClick={() => onShowModal('addPlayer', row)}
                        title={t('addPlayer', { count: row.number })}
                        variant="outline-primary"
                    >
                        <AddIcon />
                    </Button>
                )}
                {cell.change && editView && (
                    <Button
                        size="sm"
                        className="border-0"
                        onClick={() => onShowModal('changeNumber', row.number)}
                        title={t('swapPlayers')}
                        variant="outline-primary"
                    >
                        <ChangeIcon />
                    </Button>
                )}
                {cell.advancement && editView && (
                    <Button
                        size="sm"
                        className="border-0"
                        disabled={
                            row.advancements?.length === '6' ||
                            !playerCanSpendSpp(row.unspentSpp, row.skills)
                        }
                        onClick={() => onShowModal('advancement', row)}
                        title={t('advancements')}
                        variant="outline-primary"
                    >
                        <LevelUpIcon />
                    </Button>
                )}
                {createView && row.name && (
                    <Button
                        size="sm"
                        title={t('editPlayer', { count: row.number })}
                        className="border-0"
                        variant="outline-primary"
                        onClick={() => onShowModal('editName', row)}
                    >
                        <EditIcon />
                    </Button>
                )}
                {createView && shouldShowCopy(row, rowIndex) && (
                    <Button
                        size="sm"
                        title={t('clonePlayer', { count: row.number })}
                        className="border-0"
                        variant="outline-primary"
                        onClick={() =>
                            dispatch({
                                type: 'addPlayer',
                                payload: {
                                    number: row.number + 1,
                                    name: pickRandomName(row.positionalId, row.number + 1),
                                    positionalId: row.positionalId,
                                    cost: row.hiringFee,
                                },
                            })
                        }
                    >
                        <CopyIcon />
                    </Button>
                )}
                {cell.remove && (
                    <Button
                        size="sm"
                        className="border-0"
                        onClick={() => {
                            if (editView) {
                                return dispatch({
                                    type: 'firePlayer',
                                    payload: { number: row.number },
                                });
                            }
                            return dispatch({
                                type: 'removePlayer',
                                payload: { number: row.number, cost: row.hiringFee },
                            });
                        }}
                        title={t('deletePlayer', { count: row.number })}
                        variant="outline-danger"
                    >
                        <RemoveIcon />
                    </Button>
                )}
            </Row>
        );
    };

    const renderSkills = (skills) => {
        if (!skills?.length) return null;
        const acquiredSkills = skills
            .filter((item) => item.type === 'acquired')
            .map((item) => item.nameEs)
            .join(', ');

        const rosterSkills = skills
            .filter((item) => item.type === 'base')
            .map((item) => item.nameEs)
            .join(', ');

        return (
            <>
                <span className="font-weight-bold">{acquiredSkills && `${acquiredSkills}`}</span>
                {acquiredSkills && rosterSkills && <span className="font-weight-bold">{', '}</span>}
                <span className="text-muted">{rosterSkills}</span>
            </>
        );
    };

    const renderModifiedValue = (
        value: string,
        positionalId: any,
        stat: string,
        lessIsBetter?: boolean
    ) => {
        if (!value) return null;
        let modification = 'none';
        const rosterValue = getValuesForPosition(positionalId, currentRoster)[stat];

        const modificationClass = {
            increase: 'font-weight-bold',
            decrease: 'font-weight-bold text-danger',
            none: 'text-muted',
        };

        const cleanValue = Number(String(value)?.split('+')?.[0]);
        const cleanRosterValue = Number(String(rosterValue)?.split('+')?.[0]);

        if (cleanValue !== cleanRosterValue) {
            const isBetter = lessIsBetter
                ? cleanValue < cleanRosterValue
                : cleanValue > cleanRosterValue;
            modification = isBetter ? 'increase' : 'decrease';
        }

        return <span className={modificationClass[modification]}>{value}</span>;
    };

    const columns: ColumnDescription[] = [
        {
            dataField: 'number',
            text: t('number'),
            classes: 'px-3',
        },
        {
            dataField: 'name',
            text: t('player'),
            headerAlign: 'left',
            classes: 'text-left',
            formatter: (cell, row) => (
                <NamePositionalLabel
                    onClick={
                        editView && (!!row.advancements?.length || !!row.playerGameRecords?.length)
                            ? () => onShowModal('playerRecord', row)
                            : null
                    }
                    positionalId={row.positionalId}
                    roster={currentRoster}
                    name={cell}
                />
            ),
        },
        {
            dataField: 'ma',
            text: t('MA'),
            formatter: (cell, row) => renderModifiedValue(cell, row.positionalId, 'ma'),
        },
        {
            dataField: 'st',
            text: t('ST'),
            formatter: (cell, row) => renderModifiedValue(cell, row.positionalId, 'st'),
        },
        {
            dataField: 'ag',
            text: t('AG'),
            formatter: (cell, row) => renderModifiedValue(cell, row.positionalId, 'ag', true),
        },
        {
            dataField: 'pa',
            text: t('PA'),
            formatter: (cell, row) => renderModifiedValue(cell, row.positionalId, 'pa', true),
        },
        {
            dataField: 'av',
            text: t('AV'),
            formatter: (cell, row) => renderModifiedValue(cell, row.positionalId, 'av'),
        },
        {
            dataField: 'skills',
            classes: 'text-small text-left px-4',
            headerClasses: 'text-left px-4',
            text: t('skills'),
            formatter: renderSkills,
        },
        {
            dataField: 'ni',
            text: t('ni'),
            formatter: (cell) => cell || ' ',
            hidden: createView,
        },
        {
            dataField: 'mng',
            text: t('mng'),
            hidden: createView,
            formatter: (cell, row) => {
                if (!row.name) return;
                return cell ? <BandageIcon /> : ' ';
            },
        },
        {
            dataField: 'advancements',
            text: t('numberOfAdvancements'),
            hidden: createView,
            formatter: (cell, row) => {
                if (row.positionalId)
                    return (
                        <AdvancementLabel
                            advancementCount={cell?.length}
                            unspentSpp={row.unspentSpp}
                            skills={row.skills}
                        />
                    );
            },
        },
        {
            dataField: 'cv',
            text: t('cv(cost)'),
            formatter: (cell, row) => <ValueCostLabel cv={cell} hiringFee={row.hiringFee} />,
        },
        {
            dataField: 'matchesPlayed',
            text: t('matchesPlayed'),
            hidden: !editView,
        },
        {
            dataField: 'actions',
            text: t('Actions'),
            headerClasses: 'text-right pr-4',
            formatter: renderActions,
            hidden: publicView || disableActions,
        },
    ];

    return (
        <Container fluid>
            <Row>
                <Col>
                    <LabbTable
                        headerClasses={hasErrors ? 'bg-danger' : ''}
                        keyField="number"
                        data={tableData}
                        columns={columns}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TeamPlayers;
