import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

type Props = {
    component: React.ComponentType<any>;
    path: string;
    isPrivate?: boolean;
    exact: boolean;
};

const AppRoute = ({ component: Component, path, isPrivate, ...rest }: Props) => {
    const userDetails = useAuth();
    return (
        <Route
            path={path}
            render={(props) =>
                isPrivate && !userDetails.user ? (
                    <Redirect to={{ pathname: '/login' }} />
                ) : (
                    <Component {...props} />
                )
            }
            {...rest}
        />
    );
};

export default AppRoute;
