import { PlayerCreationData, TeamCreationPostData, TeamState } from 'types';

const mapCreateFormToPost = (
    teamData: TeamState,
    treasury: number,
    teamValue: number,
    maxTeamTR: number
): TeamCreationPostData => {
    const mappedPlayers: PlayerCreationData[] = teamData.players
        .map(({ number, name, positionalId }) => {
            if (!number || !name || !positionalId) return null;
            return {
                number,
                name,
                positionalId,
            };
        })
        .filter((el) => el !== null);
    return {
        ...teamData,
        treasury,
        teamValue,
        currentTeamValue: teamValue,
        apothecary: !!teamData.apothecary,
        players: mappedPlayers,
        maxTeamTR: maxTeamTR,
    };
};

export default mapCreateFormToPost;
