import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    leagueId: string;
    leagueName: string;
};

const LeagueLink = ({ leagueId, leagueName }: Props) => {
    return (
        <Link to={`/leagues/${leagueId}`} target="_blank">
            {leagueName}
        </Link>
    );
};

export default LeagueLink;
