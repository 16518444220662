import React, { useState, useEffect } from 'react';
import { League as LeagueType } from 'labb/types';
import { Modal, Alert, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetchData } from 'labb/utils';
import Team from './Team';
import Players from './Players';
import League from './League';
import Coach from './Coach';
import Fixtures from './Fixtures';
import { LoadingSpinner } from 'shared/components';

type Props = {
    selected: any;
    coachs: any;
    onClose: any;
    setSelected: any;
};

const EditModal = ({ selected, coachs, onClose, setSelected, ...props }: Props) => {
    const { t } = useTranslation(['comissioner', 'nav']);
    const [team, setTeam] = useState(null);
    const [league, setLeague] = useState<LeagueType>(null);

    useEffect(() => {
        if (selected?.type === 'league') {
            fetchLeague(selected.id);
        }
        if (selected?.type === 'team') {
            fetchTeam(selected.id);
        }
    }, [selected]);

    const fetchLeague = async (id) => {
        const response = await fetchData(`/league/${id}`);
        if (response.status === 'success') {
            setLeague(response.data.league);
        } else {
            toast.error(t('selectLeagueError', { msg: response.data.msg }));
        }
    };

    const fetchTeam = async (id) => {
        const response = await fetchData(`/team/${id}`);
        if (response.status === 'success') {
            setTeam(response.data.team);
        } else {
            toast.error(t('selectTeamError', { msg: response.data.msg }));
        }
    };

    if (!selected) return null;

    const renderSelected = () => {
        switch (selected.type) {
            case 'team':
                if (!team) return <LoadingSpinner />;
                return (
                    <Tabs defaultActiveKey="team" id="team-tabs" className="mb-3">
                        <Tab eventKey="team" title={t('team')}>
                            <Team team={team} coachs={coachs.current} onClose={onClose} />
                        </Tab>
                        <Tab eventKey="players" title={t('players')}>
                            <Players team={team} onClose={onClose} />
                        </Tab>
                    </Tabs>
                );
            case 'coach':
                return <Coach coach={selected} setSelected={setSelected} onClose={onClose} />;
            case 'league':
                if (!league) return <LoadingSpinner />;
                return (
                    <Tabs defaultActiveKey="league" id="league-tabs" className="mb-3">
                        <Tab eventKey="league" title={t('teams')}>
                            <League league={league} onClose={onClose} />
                        </Tab>
                        <Tab eventKey="fixture" title={t('fixtures')}>
                            <Fixtures league={league} onClose={onClose} />
                        </Tab>
                    </Tabs>
                );
            default:
                return null;
        }
    };
    return (
        <Modal
            {...props}
            show={!!selected}
            size="xl"
            className="modal-fullscreen-lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>{selected.name}</div>
                    <h5 className="text-muted">{t(selected.type)}</h5>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Alert variant="danger">{t('warningMessage')}</Alert>
                {renderSelected()}
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;
