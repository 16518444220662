import React from 'react';
import { Card, Row, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getGameStatusText } from 'labb/utils';
import Select from 'react-select';
import FixtureInput from './FixtureInput';

// FIXME: pass only what is need to avoid rerenders
type Props = {
    dateInput: string;
    dateVerify: string;
    fixtureData: any;
    values: any;
    errors: any;
    touched: any;
    userOptions: Array<{ label: string; value: number }>;
    setFieldValue: any;
};

const FixtureMetadata = ({
    fixtureData,
    values,
    errors,
    touched,
    dateInput,
    dateVerify,
    setFieldValue,
    userOptions,
}: Props) => {
    const { t } = useTranslation('fixtures');

    const isPlayoff = (gamename: string): boolean => {
        // FIXME this is a hack to detect if a game is playoff or not
        return !gamename.includes('ornada');
    };

    const recordTypeOptions = [
        {
            value: 0,
            label: t('bothPlay'),
        },
        {
            value: 20,
            label: t('oneDoesNotPlay', { team: fixtureData.team1.name }),
        },
        {
            value: 10,
            label: t('oneDoesNotPlay', { team: fixtureData.team2.name }),
        },
    ];

    return (
        <Card className="mb-3 bg-grey">
            <Card.Body>
                <Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label className="font-weight-bold">{t('recordType')}</Form.Label>
                        <Select
                            name="recordType"
                            placeholder={t('selectRecordType')}
                            value={recordTypeOptions.find(
                                (option) => option.value === values.recordType
                            )}
                            onChange={(option) => {
                                setFieldValue('recordType', option.value);
                                setFieldValue('type', option.value);
                            }}
                            isDisabled={fixtureData.closedRecord || fixtureData.game.status === 10}
                            options={recordTypeOptions}
                        />
                        {errors?.recordType && touched?.recordType && (
                            <div className="d-block invalid-feedback">{errors?.recordType}</div>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label className="font-weight-bold">{t('status')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="status"
                            value={t(getGameStatusText(fixtureData.game.status)) as string}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label className="font-weight-bold">{t('userInput')}</Form.Label>
                        <Select
                            placeholder={t('selectUserInput')}
                            value={userOptions.find(
                                (option) => option.value === Number(values.userInputId)
                            )}
                            onChange={(option) => {
                                setFieldValue('userInputId', option.value);
                            }}
                            isDisabled={fixtureData.closedRecord || fixtureData.game.status === 10}
                            options={userOptions}
                            isSearchable
                        />
                        {errors.userInputId && touched.userInputId && (
                            <div className="d-block invalid-feedback">{errors.userInputId}</div>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label className="font-weight-bold">{t('userVerify')}</Form.Label>
                        <Select
                            placeholder={t('selectUserVerify')}
                            defaultValue={values.userVerifyValue}
                            value={userOptions.find(
                                (option) => option.value === Number(values.userVerifyId)
                            )}
                            onChange={(option) => {
                                setFieldValue('userVerifyId', option.value);
                            }}
                            isDisabled={fixtureData.closedRecord || fixtureData.game.status === 0}
                            options={userOptions}
                            isSearchable
                        />
                        {errors.userVerifyId && touched.userVerifyId && (
                            <div className="d-block invalid-feedback">{errors.userVerifyId}</div>
                        )}
                    </Form.Group>
                    {isPlayoff(fixtureData.game.gamename) && (
                        <Form.Group as={Col} md={12} controlId="suddenDeathType">
                            <Form.Label className="font-weight-bold">{t('suddenDeath')}</Form.Label>
                            <br />
                            <Form.Check
                                inline
                                label={fixtureData.team1.name}
                                name="group-sudden-death"
                                type="radio"
                                checked={values.suddenDeath === 1}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFieldValue('suddenDeath', 1);
                                    }
                                }}
                                disabled={
                                    fixtureData.closedRecord ||
                                    fixtureData.game.t1.td !== fixtureData.game.t2.td ||
                                    values.team1.td !== values.team2.td ||
                                    !isPlayoff(fixtureData.game.gamename)
                                }
                            />
                            <Form.Check
                                inline
                                label={fixtureData.team2.name}
                                name="group-sudden-death"
                                type="radio"
                                checked={values.suddenDeath === 2}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFieldValue('suddenDeath', 2);
                                    }
                                }}
                                disabled={
                                    fixtureData.closedRecord ||
                                    fixtureData.game.t1.td !== fixtureData.game.t2.td ||
                                    values.team1.td !== values.team2.td ||
                                    !isPlayoff(fixtureData.game.gamename)
                                }
                            />
                        </Form.Group>
                    )}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col md={6}>
                        <FixtureInput
                            type="string"
                            label={t('dateInput')}
                            name="dateInput"
                            value={dateInput}
                            disabled
                        />
                    </Col>
                    <Col md={6}>
                        <FixtureInput
                            type="string"
                            label={t('dateVerify')}
                            name="dateVerify"
                            value={dateVerify}
                            disabled
                        />
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default FixtureMetadata;
