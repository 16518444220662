import { League } from 'labb/types';
import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import { LeagueForm } from '../shared/components';

type Props = {
    show: boolean;
    league: League;
    onHide: () => void;
    onSuccess: (arg) => void;
    onDelete: (arg) => void;
};

const EditLeagueModal = ({ show, league, onHide, onDelete, onSuccess, ...props }: Props) => {
    return (
        <Modal
            {...props}
            show={show}
            onHide={onHide}
            size="lg"
            className="modal-fullscreen-sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{league?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <LeagueForm
                        league={league}
                        onSuccess={onSuccess}
                        onBack={onHide}
                        onDelete={onDelete}
                        mode="edit"
                    />
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default EditLeagueModal;
