import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { CoachLink, TeamLink, LabbTable } from 'shared/components';
import { Winner } from 'labb/types';

type Props = {
    items: Winner[];
};

const WinnersListItem = ({ items }: Props) => {
    const { t } = useTranslation('leagues');

    const columns: ColumnDescription[] = [
        {
            dataField: 'position',
            text: t('number'),
            headerAlign: 'center',
            align: 'center',
        },
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            headerAlign: 'center',
            align: 'center',
            isDummyField: true,
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'coachUrl',
            text: t('coachName'),
            headerAlign: 'center',
            align: 'center',
            isDummyField: true,
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
        },
    ];

    if (!items || items.length < 1) return null;

    return (
        <Row className="p-3 d-flex flex-column">
            <h3 className="font-bb mb-3 text-center">{t('winners')}</h3>
            <LabbTable keyField="position" data={items} columns={columns} />
        </Row>
    );
};

export default WinnersListItem;
