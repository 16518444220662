import { TeamDispatch } from 'labb/types';
import React from 'react';
import { ListGroup, Col, Button, ButtonGroup } from 'react-bootstrap';
import { FaChevronUp as AddIcon, FaChevronDown as RemoveIcon } from 'react-icons/fa';
import { TeamDisplayValue } from '..';

type itemType = {
    label: string;
    value: number | string;
    max?: boolean;
    min?: boolean;
    addAction?: string;
    removeAction?: string;
    cost?: number;
    refund?: number;
    allowEdit?: boolean;
    error?: boolean;
    hidden?: boolean;
};

const TeamListItem = ({ item, dispatch }: { item: itemType; dispatch?: TeamDispatch }) => {
    const AddButton = ({ max, cost, action }) => (
        <Button
            role="increase"
            onClick={() =>
                dispatch({
                    type: action,
                    payload: { cost },
                })
            }
            disabled={max}
            className="m-auto border-0"
            size="sm"
            variant="outline-primary"
        >
            <AddIcon />
        </Button>
    );

    const RemoveButton = ({ min, refund, action }) => (
        <Button
            role="decrease"
            onClick={() =>
                dispatch({
                    type: action,
                    payload: { refund },
                })
            }
            disabled={min || !action}
            className="m-auto border-0"
            size="sm"
            variant="outline-danger"
        >
            <RemoveIcon />
        </Button>
    );

    if (item.hidden) return null;

    return (
        <ListGroup.Item
            key={item.label}
            data-test-id={item.label}
            className="d-flex align-items-center"
        >
            <Col className="pl-0">
                <div className={`${item.error ? 'text-danger' : ''}`}>{item.label}</div>
            </Col>
            <Col className="d-flex justify-content-end pr-0">
                <ButtonGroup>
                    <TeamDisplayValue value={item.value} />
                    {item.allowEdit && (
                        <>
                            <AddButton max={item.max} action={item.addAction} cost={item.cost} />
                            <RemoveButton
                                min={item.min}
                                action={item.removeAction}
                                refund={item.refund}
                            />
                        </>
                    )}
                </ButtonGroup>
            </Col>
        </ListGroup.Item>
    );
};

export default TeamListItem;
