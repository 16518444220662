import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Row, Card, Container } from 'react-bootstrap';
import { Option } from 'types';

type Props = {
    setSubmitData: any;
    submitData: any;
    player: any;
    skillOptions: Option<number>[];
    advancementOptions: { label: string; value: number; cost: number }[];
    categoryOptions: Option<string>[];
    disableSkill: boolean;
    isRandomPick: boolean;
    disableCategory: boolean;
};

const AdvancementPick = ({
    submitData,
    setSubmitData,
    skillOptions = [],
    player,
    categoryOptions,
    advancementOptions,
    disableSkill,
    isRandomPick,
    disableCategory,
}: Props) => {
    const { t } = useTranslation('teams');

    const noAdvancementPicked = submitData.type === null;

    return (
        <Card className="border-primary">
            <Card.Body>
                <Container>
                    <Row className="w-100 flex-column d-flex pb-4">
                        <h6 className="font-weight-bold">{t('advancements')}</h6>
                        <Select
                            name="type"
                            placeholder={t('select')}
                            options={advancementOptions}
                            getOptionLabel={(option) => `${option.label} - ${option.cost}PE`}
                            isOptionDisabled={(option) => option.cost > player.unspentSpp}
                            onChange={(option) => {
                                setSubmitData({
                                    characteristic: null,
                                    skillId: null,
                                    type: option.value,
                                    category: null,
                                    charlist: [],
                                });
                            }}
                        />
                    </Row>
                    {!disableSkill && (
                        <Row className="w-100 flex-column d-flex pb-4">
                            <h6 className="font-weight-bold">{t('skills')}</h6>
                            <Select
                                name="skillId"
                                placeholder={isRandomPick ? t('random') : t('select')}
                                options={skillOptions}
                                value={skillOptions?.filter(
                                    (option) => option.value === submitData.skillId
                                )}
                                isSearchable
                                isDisabled={isRandomPick || noAdvancementPicked}
                                onChange={(option) => {
                                    setSubmitData({
                                        ...submitData,
                                        skillId: option.value,
                                    });
                                }}
                            />
                        </Row>
                    )}
                    {!disableCategory && (
                        <Row className="w-100 flex-column d-flex pb-4">
                            <h6 className="font-weight-bold">{t('skillcategory')}</h6>
                            <Select
                                name="category"
                                placeholder={t('select')}
                                options={categoryOptions}
                                value={categoryOptions.find(
                                    (option) => option.value === submitData.category
                                )}
                                isDisabled={noAdvancementPicked}
                                onChange={(option) => {
                                    setSubmitData({
                                        ...submitData,
                                        category: option.value,
                                    });
                                }}
                            />
                        </Row>
                    )}
                </Container>
            </Card.Body>
        </Card>
    );
};

export default AdvancementPick;
