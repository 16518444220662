import React from 'react';
import { Roster } from 'labb/types';
import { getPositionalById } from 'labb/utils';

type Props = {
    name: string;
    positionalId: number;
    roster: Roster;
    onClick?: () => any;
};

const Wrapper = ({ children, onClick }) => {
    return onClick ? (
        <a href="#" role="button" onClick={onClick}>
            {children}
        </a>
    ) : (
        <div>{children}</div>
    );
};

const NamePositionalLabel = ({ name, positionalId, roster, onClick }: Props) => {
    const positional = getPositionalById(roster, positionalId);

    return (
        <Wrapper onClick={onClick}>
            <div className="text-nowrap">{name}</div>
            {positional && (
                <div className="text-small text-muted">
                    {positional.positional.exclusive ? '♦ ' : ''}
                    {positional.positional.nameEs}
                </div>
            )}
        </Wrapper>
    );
};

export default NamePositionalLabel;
