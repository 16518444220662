import React, { PropsWithChildren, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FeedbackBox from '../FeebackBox';
import Navbar from '../Navbar';
import Jumbotron from '../Jumbotron';
import Breadcrumbs from '../Breadcrumbs';
import Head from '../Head';
import Footer from '../Footer';
import { BreadcrumbItem } from 'types';

type Props = PropsWithChildren<{
    breadcrumbPath: BreadcrumbItem[];
    pageTitle: string;
    withJumbotron?: boolean;
}>;

const Layout = ({ withJumbotron, breadcrumbPath, pageTitle, children }: Props) => {
    const { t } = useTranslation('nav');
    useEffect(() => {
        document.title = `LABB | ${t(pageTitle)}`;
    }, [pageTitle]);

    return (
        <Container fluid className="d-flex flex-column h-100 p-0 m-0">
            <Head />
            <Navbar />
            {withJumbotron && <Jumbotron />}
            <Breadcrumbs breadcrumbPath={breadcrumbPath} />
            <Container fluid className="container-xxl mb-5">
                {children}
            </Container>
            <Footer />
            <FeedbackBox />
        </Container>
    );
};

export default Layout;
