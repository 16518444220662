import React from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    text: string;
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
};

const ConfirmModal = ({ show, onHide, onSubmit, text, ...props }: Props) => {
    const { t } = useTranslation('teams');

    return (
        <Modal
            {...props}
            show={show}
            onHide={onHide}
            size="lg"
            className="modal-fullscreen-sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('confirm')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">{text}</Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onHide()}>
                    {t('back')}
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                    {t('save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
