import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import useFetch from 'labb/utils/useFetch';
import { useTranslation } from 'react-i18next';
import { Layout, Headline, LoadingSpinner } from 'shared/components';
import TeamList from './TeamList';
import { Rosters, Team } from 'labb/types';
import { fetchData } from 'labb/utils';
import { useAuth } from 'context/auth-context';

const TeamsPage = () => {
    const { t } = useTranslation(['teams', 'nav']);
    const { user } = useAuth();
    const [teams, setTeams] = useState<Array<Team>>([]);

    const groupedTeams = useMemo(
        () => ({
            active: teams.filter?.(({ league }) => !!league) || [],
            nonActive: teams.filter?.(({ league }) => !league) || [],
        }),
        [teams]
    );

    useEffect(() => {
        const fetchTeams = async () => {
            const { data, status } = await fetchData(`/team/${user?.code}/list`);
            if (status === 'success' && data?.teams) {
                setTeams(data.teams as Team[]);
            }
        };
        fetchTeams();
    }, []);

    const fetchRosters = useCallback(() => useFetch('/roster/list'), []);
    const rosters: Rosters = fetchRosters().datalist?.rosters;

    return (
        <Layout
            pageTitle={t('teams')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:profile'), link: '#' },
                { label: t('nav:teams'), active: true },
            ]}
        >
            {rosters?.length ? (
                <>
                    <Headline>{t('title')}</Headline>
                    <Col className="my-5">
                        <Link className="btn btn-success" role="button" to="/profile/teams/create">
                            {t('newTeam')}
                        </Link>
                    </Col>
                    <Col>
                        {!!groupedTeams.active?.length && (
                            <Row className="mb-4 d-flex flex-column">
                                <Col className="mb-2 text-primary">
                                    <h2>{t('teamsInCompetition')}</h2>
                                </Col>
                                <TeamList
                                    teams={groupedTeams.active}
                                    setTeams={setTeams}
                                    rosters={rosters}
                                />
                            </Row>
                        )}
                        {!!groupedTeams.nonActive?.length && (
                            <Row className="mb-4 d-flex flex-column">
                                <Col className="mb-2 text-primary">
                                    <h2>{t('teamsNotInCompetition')}</h2>
                                </Col>
                                <TeamList
                                    teams={groupedTeams.nonActive}
                                    setTeams={setTeams}
                                    rosters={rosters}
                                />
                            </Row>
                        )}
                    </Col>
                </>
            ) : (
                <LoadingSpinner />
            )}
        </Layout>
    );
};

export default TeamsPage;
