import { TeamLeague } from 'labb/types';
import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { CoachLink, LabbTable, TeamLink } from '../shared/components';

type Props = {
    show: boolean;
    teams: TeamLeague[];
    onHide: () => void;
};

const TeamListModal = ({ show, teams, onHide, ...props }: Props) => {
    const { t } = useTranslation('leagues');
    const teamColumns: ColumnDescription[] = [
        {
            dataField: 'teamUrl',
            text: t('teamName'),
            formatter: (_cell, row) => <TeamLink teamId={row.team.id} teamName={row.team.name} />,
        },
        {
            dataField: 'coachUrl1',
            text: t('coachName'),
            formatter: (_cell, row) => (
                <CoachLink coachEmail={row.coach.email} coachName={row.coach.name} />
            ),
        },
    ];
    return (
        <Modal
            {...props}
            show={show}
            onHide={onHide}
            size="lg"
            className="modal-fullscreen-sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('teamList')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <LabbTable keyField="team.id" data={teams} columns={teamColumns} />
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default TeamListModal;
