import React, { useState } from 'react';
import { Button, Card, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { shuffle, chunk } from 'underscore';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchData } from 'labb/utils';
import { League, TeamLeague } from 'labb/types';
import Fixtures from './fixtures';
import Conferences from './conferences';
import { LabbAlert } from 'shared/components';

type Props = {
    league: League;
    teams: TeamLeague[];
};

const RegularDraw = ({ league, teams }: Props) => {
    const { t } = useTranslation(['leagueDraw']);
    const [sortedTeams, setSortedTeams] = useState(
        chunk(shuffle(teams), teams.length / league.conferences.length)
    );
    const [activeKey, setKey] = useState<'fixtures' | 'conferences'>('conferences');
    const history = useHistory();

    const [rounds, setRounds] = useState(null);

    const onSubmit = async () => {
        const fixtures = [];
        if (!rounds) return;
        league.conferences.map((conference, index) => {
            const games = [];
            rounds[index].map((round) => {
                round.games.map((game) => {
                    games.push({
                        gamename: round.title,
                        t1: {
                            id: game.home.team.id,
                            name: game.home.team.name,
                        },
                        t2: {
                            id: game.away.team.id,
                            name: game.away.team.name,
                        },
                    });
                });
            });
            fixtures.push({
                id: conference.id,
                games,
            });
        });
        const { status, data: response } = await fetchData(`/league/${league.id}/season/`, 'post', {
            conferences: fixtures,
        });
        if (status === 'success') {
            toast.success(t('regularSuccess'));
            history.push(`/leagues/${league.id}`);
        } else if (status === 'error') {
            toast.error(t('regularError', { msg: response.msg }));
        }
    };

    return (
        <Container className="pt-3">
            <Tabs
                id="draw-regular-conferences"
                variant="pills"
                className="mb-2"
                activeKey={activeKey}
                onSelect={(k: 'conferences' | 'fixtures') => setKey(k)}
            >
                <Tab eventKey="conferences" title={t('conferences')}>
                    {league && league.conferences.length > 0 && !!teams.length ? (
                        <Conferences
                            sortedTeams={sortedTeams}
                            setSortedTeams={setSortedTeams}
                            conferences={league.conferences}
                        />
                    ) : (
                        <LabbAlert message={t('notEnoughTeams')} />
                    )}
                </Tab>
                <Tab eventKey="fixtures" title={t('fixtures')}>
                    {league && !!teams.length ? (
                        <Fixtures
                            t={t}
                            sortedTeams={sortedTeams}
                            league={league}
                            rounds={rounds}
                            setRounds={setRounds}
                        />
                    ) : (
                        <LabbAlert message={t('notEnoughTeams')} />
                    )}
                </Tab>
            </Tabs>
            <Card className="mt-3 border">
                <Card.Body>
                    <Row>
                        <Col>{t('drawRegularLegend')}</Col>
                    </Row>
                </Card.Body>
            </Card>
            <Container className="d-flex p-3 justify-content-end">
                <Row>
                    <Col>
                        <Button
                            variant="danger"
                            onClick={() => history.push(`/leagues/${league.id}`)}
                        >
                            {t('back')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={teams.length < 2}
                            variant="success"
                            onClick={() => onSubmit()}
                        >
                            {t('save')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default RegularDraw;
