import React from 'react';
import { useAuth } from 'context/auth-context';
import { Container, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useRegisterSchema } from './useSchema';
import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

const Register = () => {
    const { t } = useTranslation('register');
    const auth = useAuth();
    const registerPreuser = useRegisterSchema();

    const handleSubmit = async (values) => {
        const response = await auth.register(values.newuser, values.newemail);
        if (response.status === 'success') {
            toast.success(response.data.msg);
        } else {
            toast.error(response.data.msg);
        }
    };

    return (
        <Container fluid>
            <h3 className="font-bb text-primary text-center mb-3">{t('title')}</h3>
            <Trans t={t} i18nKey="registerMessage">
                registerMessage_0 <Link to="/terms">registerMessage_1</Link> registerMessage_2
                <Link to="/rules">registerMessage_3</Link> registerMessage_4
                <a href="https://www.bloodbowl.com/" target="_blank" rel="noreferrer">
                    registerMessage_5
                </a>
                registerMessage_6
            </Trans>
            <Formik
                validationSchema={registerPreuser}
                onSubmit={handleSubmit}
                initialValues={{
                    newuser: '',
                    newemail: '',
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <Form className="col-12">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="newuser"
                                autoComplete="off"
                                value={values.newuser}
                                isInvalid={touched.newuser && !!errors.newemail}
                                onChange={handleChange}
                                placeholder={t('newUserPlaceholder')}
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="newuser" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="email"
                                name="newemail"
                                autoComplete="off"
                                value={values.newemail}
                                isInvalid={touched.newemail && !!errors.newemail}
                                onChange={handleChange}
                                placeholder={t('emailPlaceholder')}
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="newemail" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Col className="text-center">
                            <Link to="/terms">{t('terms')}</Link>
                        </Col>
                        <Col className="text-center">
                            <a href="mailto:ligalbbav@gmail.com">{t('contact')}</a>
                        </Col>
                        <Button
                            variant="primary"
                            className="btn-block"
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit(values);
                            }}
                        >
                            {t('registerButton')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default Register;
