import React from 'react';
import { Layout, FormikSelect } from 'shared/components';
import { useAuth } from 'context/auth-context';
import { Container, Row, Form, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, ErrorMessage } from 'formik';
import useProfileSchema from './useSchema';
import { toast } from 'react-toastify';

const Profile = () => {
    const { t } = useTranslation('profile');
    const auth = useAuth();
    const history = useHistory();
    const userProfileSchema = useProfileSchema(t);
    const { isComissioner } = useAuth();

    const handleSubmit = async (values) => {
        const response = await auth.updateProfile(
            auth.user.code,
            values.user,
            values.passw,
            values.email,
            values.userLevel
        );
        if (response.status === 'success') {
            toast.success(t('upateProfileSuccess'));
            history.push('/profile');
        } else {
            toast.error(t('updateProfileError'));
        }
    };

    const handleDelete = async (e) => {
        if (confirm(t('sureDelete'))) {
            e.preventDefault();
            const response = await auth.deleteUser(auth.user.code);
            if (response.status === 'success') {
                toast.success(t('userDeleteSuccess'));
                auth.logout?.();
                history.push('/login');
            } else {
                toast.error(t('userDeleteError'));
            }
        }
    };

    const userLevelOptions = [
        {
            id: 'c',
            label: t('comissioner'),
        },
        {
            id: 'e',
            label: t('coach'),
        },
    ];

    return (
        <Layout
            pageTitle={t('profile')}
            breadcrumbPath={[
                { label: t('nav:main'), link: '/' },
                { label: t('nav:profile'), link: '#' },
            ]}
        >
            <Container className="pt-3 mb-5">
                <Formik
                    initialValues={{
                        user: auth.user.usuario,
                        passw: '',
                        passw2: '',
                        email: auth.user.email,
                        userLevel: auth.user.nivel,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={userProfileSchema}
                >
                    {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => {
                        return (
                            <Row className="d-flex justify-content-around">
                                <Col md={7}>
                                    <Form className="col-12">
                                        <Form.Group as={Row}>
                                            <Form.Label>{t('user')}</Form.Label>
                                            <Form.Control
                                                name="user"
                                                type="text"
                                                autoComplete="off"
                                                value={values.user}
                                                isInvalid={touched.user && !!errors.user}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <ErrorMessage name="user" />
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label>{t('password')}</Form.Label>
                                            <Form.Control
                                                name="passw"
                                                type="password"
                                                autoComplete="off"
                                                value={values.passw}
                                                isInvalid={touched.passw && !!errors.passw}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <ErrorMessage name="passw" />
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label className="align-self-center">
                                                {t('retry')}
                                            </Form.Label>
                                            <Form.Control
                                                name="passw2"
                                                type="password"
                                                autoComplete="off"
                                                value={values.passw2}
                                                isInvalid={touched.passw2 && !!errors.passw2}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <ErrorMessage name="passw2" />
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label htmlFor="email">{t('email')}</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                autoComplete="off"
                                                value={values.email}
                                                isInvalid={touched.email && !!errors.email}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <ErrorMessage name="email" />
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label>{t('level')}</Form.Label>
                                            <FormikSelect
                                                className="w-100"
                                                name="userLevel"
                                                value={userLevelOptions.filter(
                                                    (option) => option.id === values.userLevel
                                                )}
                                                disabled={!isComissioner}
                                                onChange={(option: any) =>
                                                    setFieldValue('userLevel', option.id)
                                                }
                                                options={userLevelOptions}
                                            />
                                        </Form.Group>
                                        <Button
                                            variant="success"
                                            className="btn-block my-5 col-3 ml-auto"
                                            onClick={() => {
                                                handleSubmit(values);
                                            }}
                                        >
                                            {t('update')}
                                        </Button>
                                    </Form>
                                    <Row style={{ backgroundColor: '#f7f7f9' }} className="p-4">
                                        <Trans t={t} i18nKey="termsMessage" parent="p">
                                            termsMessage_0 <Link to="/terms">termsMessage_1</Link>{' '}
                                            termsMessage_2
                                        </Trans>
                                        <Button
                                            variant="danger"
                                            className="mt-3 ml-auto col-4"
                                            onClick={handleDelete}
                                        >
                                            {t('deleteUser')}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    }}
                </Formik>
            </Container>
        </Layout>
    );
};

export default Profile;
