import React from 'react';
import { Container, Navbar, Image } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { TeamAdmin } from 'shared/components';

const TeamPublicView = () => {
    const history = useHistory();
    const { teamId } = useParams<{ teamId: string }>();
    if (Number(teamId) < 0) history.push('/');

    return (
        <>
            <Navbar bg="light-blue" variant="light">
                <Container fluid className="container-xxl">
                    <Navbar.Brand as={Link} to="/">
                        <Image src={logo} height="40px" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Container fluid className="container-xxl mb-5">
                <TeamAdmin teamId={teamId} publicView />
            </Container>
        </>
    );
};

export default TeamPublicView;
