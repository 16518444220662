import React from 'react';
import { Button } from 'react-bootstrap';

const TeamDisplayValue: React.FC<{ value: any }> = ({ value }) => (
    <Button
        className="border-0 text-right"
        variant="outline-dark"
        disabled
        style={{ opacity: '100%' }}
    >
        <span className="font-weight-bold">{value}</span>
    </Button>
);

export default TeamDisplayValue;
