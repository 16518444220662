import { TFunction } from 'i18next';
import * as yup from 'yup';
import { es } from 'yup-locales';

const useCreateRecordFixtureSchema = (t: TFunction): any => {
    yup.setLocale(es);
    return yup.object().shape({
        recordType: yup.number().min(0, t('selectRecordType')).required(),
        userInputId: yup.number().min(1, t('selectUserInput')).required(),
        status: yup.number(),
        userVerifyId: yup.number().when('status', {
            is: 10,
            then: yup.number().min(1, t('selectUserVerify')).required(),
            otherwise: yup.number().min(0, t('selectUserVerify')).notRequired(),
        }),
        team1: yup.object().shape({
            td: yup.number().min(0).integer().required(),
            cas: yup.number().min(0).integer().required(),
            ff: yup.number().min(0).integer().required(),
            tv: yup.number().min(0).integer().required(),
            comment: yup.string().notRequired(),
        }),
        team2: yup.object().shape({
            td: yup.number().min(0).integer().required(),
            cas: yup.number().min(0).integer().required(),
            ff: yup.number().min(0).integer().required(),
            tv: yup.number().min(0).integer().required(),
            comment: yup.string().notRequired(),
        }),
        // TODO: validate players?
    });
};

export default useCreateRecordFixtureSchema;
