import React, { useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Card, Row, Container } from 'react-bootstrap';
import { Advancement, SubmitData } from './index';
import { CHARACTERISTICS_NAMES } from '../../../constants';
import { Option } from 'types';

type Props = {
    pendingCharacteristicPick: Advancement;
    setSubmitData: (arg0: SubmitData) => void;
    submitData: SubmitData;
    skillOptions: Option<number>[];
};

const CharacteristicPick = ({
    pendingCharacteristicPick: { characteristic },
    submitData,
    setSubmitData,
    skillOptions,
}: Props) => {
    const { t } = useTranslation('teams');
    const initialChar = useRef(characteristic);

    const disableCharacteristicPicker = !!submitData.skillId;
    const disableSkillIdPicker =
        submitData?.characteristic === 20 || submitData.characteristic !== initialChar.current;

    return (
        <Card className="border-primary">
            <Card.Body>
                <Card.Title>
                    <h4>{t('characteristicUpgrade')}</h4>
                </Card.Title>
                <Card.Subtitle>
                    {t(CHARACTERISTICS_NAMES[characteristic]) || t('unknown!')}
                </Card.Subtitle>
                <Container fluid className="pt-3">
                    <Row className="w-100 flex-column d-flex pb-4">
                        <h6 className="font-weight-bold">{t('characteristic')}</h6>
                        <Select
                            name="characteristic"
                            placeholder={t('select')}
                            value={submitData.charlist.filter(
                                (option) => option.value === submitData.characteristic
                            )}
                            isClearable
                            options={submitData.charlist}
                            isDisabled={disableCharacteristicPicker}
                            onChange={(e, { action }) => {
                                setSubmitData({
                                    ...submitData,
                                    characteristic:
                                        action === 'clear' ? initialChar.current : e.value,
                                    skillId: null,
                                });
                            }}
                        />
                    </Row>
                    <Row className="w-100 flex-column d-flex pb-4">
                        <h6 className="font-weight-bold">{t('skills')}</h6>
                        <Select
                            name="skillId"
                            placeholder={t('selectIfNotCharacteristic')}
                            value={skillOptions.filter(
                                (option) => option.value === submitData.skillId
                            )}
                            options={skillOptions}
                            isDisabled={disableSkillIdPicker}
                            isClearable
                            isSearchable
                            onChange={(e, { action }) => {
                                setSubmitData({
                                    ...submitData,
                                    skillId: action === 'clear' ? null : e.value,
                                });
                            }}
                        />
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default CharacteristicPick;
