import React from 'react';
import { Container, Spinner } from 'react-bootstrap';

const LoadingSpinner: React.FC = () => (
    <Container
        style={{ minHeight: '300px' }}
        className="d-flex justify-content-center align-items-center"
    >
        <Spinner animation="border" variant="primary" />
    </Container>
);

export default LoadingSpinner;
