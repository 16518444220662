import { TFunction } from 'i18next';
import * as yup from 'yup';
import { es } from 'yup-locales';

const useCreateLeagueSchema = (t: TFunction): any => {
    yup.setLocale(es);
    return yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        type: yup.number().required().oneOf([0, 10, 20]),
        maxTeams: yup.number().min(2).integer().required(),
        cvmin: yup.number().positive().integer().required(),
        cvmax: yup.number().positive().integer().required(),
        ptwin: yup.number().positive().integer().required(),
        ptloss: yup.number().min(0).integer().required(),
        ptdraw: yup.number().min(0).integer().required(),
        comissionerId: yup.number().min(1, t('selectComissioner')).required(),
        rulesVersionId: yup.number().min(1, t('selectRulesVersion')).required(),
        returnGames: yup.boolean().when('type', {
            is: 0 || 10,
            then: yup.boolean().required(),
        }),
        playoffTeams: yup
            .number()
            .when('type', {
                is: 10 || 20,
                then: yup.number().min(2).required(),
            })
            .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: t('invalidPlayoffTeams'),
                test: function (value) {
                    return value <= this.parent.maxTeams;
                },
            }),
    });
};

export default useCreateLeagueSchema;
