import React from 'react';
import { Container, Col, Button } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Guest = () => {
    const history = useHistory();
    const { t } = useTranslation('guest');

    return (
        <Container fluid>
            <h3 className="font-bb text-primary text-center mb-3">{t('welcome')}</h3>
            <Trans t={t} i18nKey="guestMessage">
                guestMessage_0
                <Link to="/rules">guestMessage_1</Link> guestMessage_2
                <a href="https://www.bloodbowl.com/" target="_blank" rel="noreferrer">
                    guestMessage_3
                </a>
                guestMessage_4
            </Trans>
            <Col className="text-center pb-3">
                <Link to="/tutorial">{t('tutorial')}</Link>
            </Col>
            <Button
                variant="primary"
                type="submit"
                className="btn-block"
                onClick={() => {
                    history.push('/');
                }}
            >
                {t('guestButton')}
            </Button>
        </Container>
    );
};

export default Guest;
