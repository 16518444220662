import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Form, ListGroup, Button, Card, InputGroup } from 'react-bootstrap';
import { MdAddCircle as AddIcon, MdDelete as RemoveIcon } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Select from 'react-select';
import useLeagueSchema from './useSchema';
import { FormikSwitch } from 'shared/components';
import { League } from 'labb/types';
import { fetchMultiple, fetchData } from 'labb/utils';
import { LEAGUE_STATUS } from '../../../Leagues';

const initialData = {
    id: 0,
    name: '',
    description: '',
    maxTeams: 10,
    cvmin: 1000,
    cvmax: 1500,
    ptwin: 3,
    ptloss: 0,
    ptdraw: 1,
    returnGames: false,
    type: 0, // 0 'regular' | 10 'combined' | 20 'playoff'
    playoffTeams: 0,
    comissionerId: 0,
    rulesVersionId: 0,
};

type Props = {
    league?: League;
    onSuccess: (arg) => any;
    onBack: () => void;
    onDelete?: (id: number) => void;
    mode: 'creation' | 'edit';
};

const leagueTypeToString = {
    0: 'regular',
    10: 'combined',
    20: 'playoff',
};

const FormLeagueItem = ({ league, onBack, onSuccess, onDelete, mode = 'edit' }: Props) => {
    const { t } = useTranslation('leagues');
    const [conferences, setConferences] = useState(
        league?.conferences?.map((item) => item.name) || ['']
    );
    const [extraPoints, setExtraPoints] = useState(
        league
            ? { enabled: !!league.extraPoints, value: league.extraPoints || '' }
            : { enabled: false, value: '' }
    );
    const leagueSchema = useLeagueSchema(t);
    const [formData, setFormData] = useState(initialData);
    const [comissioners, setComissioners] = useState({
        options: null,
        selected: null,
    });
    const [rules, setRules] = useState({ options: null, selected: null, info: null });
    const [leagueStatus, setLeagueStatus] = useState(league?.status || 0);

    const leagueOptions = useMemo(
        () =>
            Object.entries(LEAGUE_STATUS).map((item) => ({
                value: Number(item[0]),
                label: t(item[1]),
            })),
        []
    );

    useEffect(() => {
        if (!comissioners.options || !rules.options) {
            fetchOptions();
        }
    }, []);

    useEffect(() => {
        if (league) {
            loadFormData();
        } else {
            setFormData(initialData);
            setExtraPoints({ enabled: false, value: '' });
        }
    }, [league]);

    const fetchOptions = async () => {
        const response = await fetchMultiple(['/user/comissioner/list', '/rules/versions/list']);
        if (response.status === 'success') {
            const comissionersOptions = response.data[0].comissioners.map((item) => ({
                value: item.code,
                label: item.usuario,
            }));

            const rulesOptions = response.data[1].rulesversion.map((item) => ({
                value: item.id,
                label: item.code,
            }));

            let selectedComissioner;
            let selectedRules;

            if (league) {
                selectedComissioner = comissionersOptions.filter(
                    (option) => league.comissionerId === option.value
                )?.[0];
                selectedRules = rulesOptions.filter(
                    (option) => league.rulesVersionId === option.value
                )?.[0];
            }

            setComissioners({
                options: comissionersOptions,
                selected: selectedComissioner,
            });
            setRules({
                info: response.data[1]?.rulesversion,
                options: rulesOptions,
                selected: selectedRules,
            });
        }
    };

    const loadFormData = () => {
        setFormData({ ...league });
    };

    const renderLeagueType = (type, setFieldValue) =>
        ([0, 10, 20] as const).map((item) => (
            <ListGroup.Item
                as={Button}
                key={item}
                variant="light"
                active={type === item}
                onClick={() => {
                    setFieldValue('type', item);
                    setConferences(['']);
                }}
            >
                {t(leagueTypeToString[item])}
            </ListGroup.Item>
        ));

    const renderRulesVersionInfo = (data) => {
        if (!data.selected || !data.info.length) return null;
        const [currentRules] = data.info.filter((item) => item.id === data.selected.value);
        if (!currentRules) return null;
        return (
            <Card>
                <Card.Body>
                    <Card.Title className="text-center">{currentRules.code}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                        {currentRules.description}
                    </Card.Subtitle>
                    <Card.Text>
                        <span className="d-block font-weight-bold">{t('rosters')}</span>
                        {currentRules.rosters.split(',').join(', ')}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    const handleEditConferences = (i, { target }) => {
        const items = [...conferences];
        items[i] = target.value;
        setConferences(items);
    };

    const onSubmit = async (values: any) => {
        if (mode === 'edit') {
            if (!confirm(t('sureEditLeague?'))) {
                return;
            }
        }

        const requestMethod = mode === 'edit' ? 'put' : 'post';
        const requestUrl = mode === 'edit' ? `/league/${values.id}` : '/league/';

        const formPost = {
            ...values,
            playoffTeams: values.playoffTeams,
            extraPoints: values.extraPoints.enabled ? values.extraPoints.value : null,
            conferences:
                values.type === 20
                    ? []
                    : values.conferences
                          .map((item, index) => ({
                              number: index + 1,
                              name: item,
                          }))
                          .slice(0, values.conferences.length),
        };

        const { status, data: response } = await fetchData(requestUrl, requestMethod, formPost);
        if (status === 'success') {
            toast.success(t('leagueSuccess', { context: mode }));
            onSuccess(response.leagues);
        } else if (status === 'error') {
            toast.error(t('leagueError', { context: mode, msg: response.msg }));
        }
    };

    const renderConferences = (maxTeams: number) => {
        const nonDivisable = maxTeams % conferences.length !== 0;
        return (
            <Form.Group as={Col}>
                <Form.Label className="font-weight-bold"> {t('conferences')}</Form.Label>
                {conferences.map((item, i) => (
                    <Col key={i}>
                        <InputGroup>
                            <InputGroup.Text>{i + 1}</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={item || ''}
                                onChange={(e) => handleEditConferences(i, e)}
                            />
                            {i === conferences.length - 1 && (
                                <>
                                    <Button
                                        onClick={() => setConferences([...conferences, ''])}
                                        variant="outline-success"
                                    >
                                        <AddIcon />
                                    </Button>
                                    <Button
                                        disabled={conferences.length <= 1}
                                        onClick={() =>
                                            setConferences([...conferences.slice(0, -1)])
                                        }
                                        variant="outline-danger"
                                    >
                                        <RemoveIcon />
                                    </Button>
                                </>
                            )}
                        </InputGroup>
                    </Col>
                ))}
                {nonDivisable && (
                    <Row className="text-danger">
                        <Col className="p-2 text-center">{t('nonDivisible')}</Col>
                    </Row>
                )}
            </Form.Group>
        );
    };

    return (
        <Formik
            validationSchema={leagueSchema}
            onSubmit={(values) => {
                onSubmit({
                    ...values,
                    extraPoints,
                    comissionerId: comissioners.selected?.value,
                    rulesVersionId: rules.selected?.value,
                    status: mode === 'edit' ? leagueStatus : 0,
                    conferences,
                });
            }}
            initialValues={formData}
            enableReinitialize={true}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                touched,
                errors,
                setFieldValue,
            }) => {
                return (
                    <Row className="d-flex justify-content-around">
                        <Col md={mode === 'edit' ? 12 : 7}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Container
                                    className="border border-primary"
                                    style={{ backgroundColor: '#f7f7f9' }}
                                >
                                    <Form.Group className="pt-3 px-0" as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('name')}
                                        </Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            required
                                            autoComplete="off"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="px-0" as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('description')}
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            isInvalid={touched.description && !!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label className="font-weight-bold">
                                                {t('rulesVersion')}
                                            </Form.Label>
                                            <Select
                                                placeholder={t('select')}
                                                value={rules.selected}
                                                onChange={(option) => {
                                                    setFieldValue('rulesVersionId', option.value);
                                                    setRules({
                                                        ...rules,
                                                        selected: option,
                                                    });
                                                }}
                                                options={rules.options}
                                            />
                                            {errors.rulesVersionId && touched.rulesVersionId && (
                                                <div className="d-block invalid-feedback">
                                                    {errors.rulesVersionId}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label className="font-weight-bold">
                                                {t('commisioner')}
                                            </Form.Label>
                                            <Select
                                                placeholder={t('select')}
                                                value={comissioners.selected}
                                                onChange={(option) => {
                                                    setFieldValue('comissionerId', option.value);
                                                    setComissioners({
                                                        ...comissioners,
                                                        selected: option,
                                                    });
                                                }}
                                                options={comissioners.options}
                                            />
                                            {errors.comissionerId && touched.comissionerId && (
                                                <div className="d-block invalid-feedback">
                                                    {errors.comissionerId}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="py-3">
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('maxteams')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="maxTeams"
                                                value={values.maxTeams}
                                                onChange={handleChange}
                                                min={2}
                                                isInvalid={touched.maxTeams && !!errors.maxTeams}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.maxTeams}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('cvmin')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="cvmin"
                                                min={0}
                                                value={values.cvmin}
                                                onChange={handleChange}
                                                isInvalid={touched.cvmin && !!errors.cvmin}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.cvmin}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('cvmax')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="cvmax"
                                                min={0}
                                                value={values.cvmax}
                                                onChange={handleChange}
                                                isInvalid={touched.cvmax && !!errors.cvmax}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.cvmax}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('ptwin')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="ptwin"
                                                min={0}
                                                value={values.ptwin}
                                                onChange={handleChange}
                                                isInvalid={touched.ptwin && !!errors.ptwin}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ptwin}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('ptloss')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="ptloss"
                                                min={0}
                                                value={values.ptloss}
                                                onChange={handleChange}
                                                isInvalid={touched.ptloss && !!errors.ptloss}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ptloss}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label className="font-weight-bold">
                                                {t('ptdraw')}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="ptdraw"
                                                min={0}
                                                value={values.ptdraw}
                                                onChange={handleChange}
                                                isInvalid={touched.ptdraw && !!errors.ptdraw}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ptdraw}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label className="font-weight-bold d-block">
                                                {t('leagueType')}
                                            </Form.Label>
                                            <ListGroup
                                                horizontal
                                                className="d-flex justify-content-start"
                                            >
                                                {renderLeagueType(values.type, setFieldValue)}
                                            </ListGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    {values.type !== 20 && (
                                        <>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('extraPoints')}
                                                    </Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Checkbox
                                                            onChange={(e) =>
                                                                setExtraPoints({
                                                                    ...extraPoints,
                                                                    enabled: e.target.checked,
                                                                })
                                                            }
                                                            checked={extraPoints.enabled}
                                                            aria-label="extraPoints"
                                                        />
                                                        <Form.Control
                                                            value={extraPoints.value}
                                                            disabled={!extraPoints.enabled}
                                                            onChange={(e) =>
                                                                setExtraPoints({
                                                                    ...extraPoints,
                                                                    value: e.target.value,
                                                                })
                                                            }
                                                            placeholder={t('enterExtraPoints')}
                                                            aria-label="Text input with checkbox"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group
                                                    as={Row}
                                                    className="d-flex justify-content-center"
                                                >
                                                    <Col>
                                                        <Form.Label className="font-weight-bold">
                                                            {t('returnGame')}
                                                        </Form.Label>
                                                        <Col>
                                                            <FormikSwitch
                                                                name="returnGames"
                                                                width={85}
                                                                onlabel={t('yes')}
                                                                offlabel={t('no')}
                                                            />
                                                        </Col>
                                                    </Col>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                {renderConferences(values.maxTeams)}
                                            </Form.Row>
                                        </>
                                    )}
                                    {values.type > 0 && (
                                        <>
                                            <Form.Row>
                                                <Form.Group as={Col} md={4}>
                                                    <Form.Label className="font-weight-bold">
                                                        {t('playoffTeams')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="playoffTeams"
                                                        min={2}
                                                        value={values.playoffTeams}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            touched.playoffTeams &&
                                                            !!errors.playoffTeams
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.playoffTeams}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                        </>
                                    )}
                                    {mode === 'edit' && (
                                        <Form.Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label className="font-weight-bold">
                                                    {t('state')}
                                                </Form.Label>
                                                <Select
                                                    name="leagueStatus"
                                                    placeholder={t('select')}
                                                    value={leagueOptions.find(
                                                        (option) => option.value === leagueStatus
                                                    )}
                                                    onChange={(option) =>
                                                        setLeagueStatus(option.value)
                                                    }
                                                    isDisabled={leagueStatus >= 20}
                                                    options={leagueOptions.filter(
                                                        (option) =>
                                                            option.value === 12 ||
                                                            option.value == 20
                                                    )}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    )}
                                </Container>
                                <Container className="d-flex p-3 justify-content-end">
                                    <Row>
                                        {!!league && (
                                            <Col>
                                                <Button
                                                    disabled={league && league.status >= 20}
                                                    variant="warning"
                                                    className="m-auto"
                                                    onClick={() => onDelete(values.id)}
                                                >
                                                    {t('delete')}
                                                </Button>
                                            </Col>
                                        )}
                                        <Col>
                                            <Button
                                                onClick={onBack}
                                                className="m-auto"
                                                variant="danger"
                                            >
                                                {t('back')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    (values.type < 20 &&
                                                        values.maxTeams % conferences.length !==
                                                            0) ||
                                                    isSubmitting ||
                                                    (league &&
                                                        (league.status == 20 ||
                                                            league.status == 100))
                                                }
                                                className="m-auto"
                                                variant="success"
                                            >
                                                {t('save')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Col>
                        <Col md={mode === 'edit' ? 12 : 4}>
                            {rules.selected && renderRulesVersionInfo(rules)}
                        </Col>
                    </Row>
                );
            }}
        </Formik>
    );
};

export default FormLeagueItem;
