import React from 'react';
import { Col, Row, Alert } from 'react-bootstrap';

const LabbAlert = ({ message }: { message: string }) => (
    <Row className="p-5 justify-content-center">
        <Col className="p-5" md={8}>
            <Alert className="text-center" variant="danger">
                {message}
            </Alert>
        </Col>
    </Row>
);

export default LabbAlert;
