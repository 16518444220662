import { Roster, TeamCreationPostData } from 'types';
import { TEAM_CREATION_CONSTANTS, VALIDATION_ERRORS } from '../constants';

const checkTeamRule = (teamData, currentRoster) => {
    if (currentRoster.rulesExclusive && !teamData.teamRuleId) return [VALIDATION_ERRORS.teamRules];
    return [];
};

const checkTeamName = (name: string) => {
    if (!name) return [VALIDATION_ERRORS.teamName];
    return [];
};

const checkTreasury = (treasury: number) => {
    if (treasury < 0) return [VALIDATION_ERRORS.treasury];
    return [];
};

const checkPlayers = (players: TeamCreationPostData['players']) => {
    let numberOfPlayers = 0;
    const errors = [];
    players.forEach(({ name, positionalId }) => {
        if (name && positionalId) {
            numberOfPlayers += 1;
        }
    });

    if (numberOfPlayers < TEAM_CREATION_CONSTANTS.minNumberOfPlayers)
        errors.push(VALIDATION_ERRORS.numberOfPlayers);
    return errors;
};

const validateTeamCreation = (
    teamData: TeamCreationPostData,
    treasury: number,
    currentRoster: Roster
): string[] => {
    const treasuryErrors = checkTreasury(treasury);
    const playersErrors = checkPlayers(teamData.players);
    const teamNameErrors = checkTeamName(teamData.name);
    const teamRuleErrors = checkTeamRule(teamData, currentRoster);

    return [...treasuryErrors, ...playersErrors, ...teamNameErrors, ...teamRuleErrors].filter(
        (el) => el !== null
    );
};

export default validateTeamCreation;
