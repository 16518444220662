import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    teamId: string;
    teamName: string;
};

const TeamLink = ({ teamId, teamName }: Props) => {
    return (
        <Link to={`/teams/${teamId}`} target="_blank">
            {teamName}
        </Link>
    );
};

export default TeamLink;
