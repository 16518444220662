import React from 'react';

type Props = {
    coachEmail: string;
    coachName: string;
};

const CoachLink = ({ coachEmail, coachName }: Props) => {
    return <a href={`mailto:${coachEmail}`}>{coachName}</a>;
};

export default CoachLink;
