import React from 'react';
import { Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { Badge } from 'react-bootstrap';

const CustomSeed = ({ seed, breakpoint }: IRenderSeedProps): any => {
    return (
        <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
            <SeedItem>
                <div className="bg-light border border-primary">
                    <SeedTeam className="text-dark bg-grey">
                        <Badge variant="primary">{seed.teams[0]?.badge}</Badge>{' '}
                        {seed.teams[0]?.team?.name || 'NO TEAM '} {/* FIXME */}
                        {seed.teams[0]?.conference !== '' ? ` - ` + seed.teams[0]?.conference : ''}
                    </SeedTeam>
                    <SeedTeam className="text-dark bg-white">
                        <Badge variant="primary">{seed.teams[1]?.badge}</Badge>
                        {seed.teams[1]?.team?.name || 'NO TEAM '}
                        {seed.teams[1]?.conference !== '' ? ` - ` + seed.teams[1]?.conference : ''}
                    </SeedTeam>
                </div>
            </SeedItem>
        </Seed>
    );
};

export default CustomSeed;
