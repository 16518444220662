import React, { useState } from 'react';
import { Container, Form, ListGroup, Col, Button, Pagination, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNews } from 'labb/utils';
import { useAuth } from 'context/auth-context';
import { NewsItem, LoadingSpinner } from 'shared/components';
import { chunk } from 'underscore';

type NewsItem = {
    id: number;
    date: string;
    text: string;
};

const NEWS_PER_PAGE = 3;

const MainNews = () => {
    const { t } = useTranslation('news');
    const { isComissioner } = useAuth();
    const {
        news = [],
        newsInput,
        setNewsInput,
        addNewsItem,
        deleteNewsItem,
        loading,
    } = useNews('/news');
    const [pageIndex, setPageIndex] = useState(0);

    const paginatedNews = chunk(news, NEWS_PER_PAGE);

    const renderPagination = () => (
        <Pagination>
            <Pagination.Prev
                disabled={pageIndex === 0}
                onClick={() => setPageIndex(pageIndex - 1)}
            />
            <Pagination.Next
                disabled={pageIndex === paginatedNews.length - 1}
                onClick={() => setPageIndex(pageIndex + 1)}
            />
        </Pagination>
    );

    return (
        <Container fluid className="mb-3 bg-white p-3 border rounded shadow-sm">
            <Row className="align-items-center p-3">
                <Col>
                    <h4 className="font-bb">{t('news')}</h4>
                </Col>
                <Col className="d-flex justify-content-end"> {renderPagination()}</Col>
            </Row>
            {!loading ? (
                <ListGroup variant="flush">
                    {paginatedNews?.[pageIndex]?.map?.((item: NewsItem) => (
                        <NewsItem
                            key={item.id}
                            item={item}
                            isComissioner={isComissioner}
                            deleteNewsItem={deleteNewsItem}
                        />
                    ))}
                </ListGroup>
            ) : (
                <LoadingSpinner />
            )}
            {isComissioner && (
                <Form className="col-12 mt-3">
                    <Form.Group>
                        <Form.Label htmlFor="email">{t('newEntry')}</Form.Label>
                        <Col sm={12}>
                            <Form.Control
                                as="textarea"
                                name="newstext"
                                type="text"
                                maxLength={2000}
                                required
                                autoComplete="off"
                                value={newsInput}
                                onChange={(e) => setNewsInput(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Button
                        variant="success"
                        disabled={!newsInput}
                        className="btn-block my-5 col-3 ml-auto"
                        onClick={addNewsItem}
                    >
                        {t('add')}
                    </Button>
                </Form>
            )}
        </Container>
    );
};

export default MainNews;
