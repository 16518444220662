import axios from 'axios';
import TokenStorage from 'labb/utils/tokenStorage';

export type ResponseStatus = 'idle' | 'success' | 'error' | 'pending';

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.API_HOST || 'http://localhost/api';

axios.interceptors.request.use(
    (config) => {
        if (TokenStorage.isAuthenticated()) {
            config.headers['Authorization'] = `Bearer ${TokenStorage.getToken()}`;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        // TODO force logout if error comes from fetching token
        if (error.config.url == '/api/token/') {
            TokenStorage.clear();

            return new Promise((_resolve, reject) => {
                reject(error);
            });
        }

        // Try request again with new token
        return TokenStorage.getNewToken()
            .then((token) => {
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${token}`;

                return new Promise((resolve, reject) => {
                    axios
                        .request(config)
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                Promise.reject(error);
            });
    }
);

const fetchMultiple = async (
    urlArray: string[]
): Promise<{ status: ResponseStatus; data: any }> => {
    let data: any = null;

    const requests = urlArray.map((urlString) => axios.get(urlString));
    data = await axios
        .all(requests)
        .then(
            axios.spread((...responses) => {
                return responses.map((item) => item.data);
            })
        )
        .catch((error) => {
            return { status: 'error', data: error?.response?.data };
        });

    if (data) {
        return { status: 'success', data };
    }
};

export default fetchMultiple;
