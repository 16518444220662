import React from 'react';
import { TeamDispatch, TeamReducerState } from 'types';
import { ListGroup, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TeamListItem, TeamLeaguePicker, TeamExclusiveRules } from '..';
import { VALIDATION_ERRORS } from '../../constants';
import { useCoachName } from '../../helpers';

type Props = {
    state: TeamReducerState;
    userName: string;
    dispatch: TeamDispatch;
    editView: boolean;
    publicView: boolean;
    createView: boolean;
};

const TeamSummary = ({
    state: { currentRoster, team, errors },
    dispatch,
    userName,
    editView,
    publicView,
    createView,
}: Props) => {
    const { t } = useTranslation('teams');
    const { coachName } = useCoachName(team.coachId, userName);
    const exclusiveRuleError = errors?.includes(VALIDATION_ERRORS.teamRules);

    const summaryItems = [
        {
            label: t('coach'),
            value: coachName,
        },
        {
            label: t('teamName'),
            value: team.name,
            error: errors?.includes('validateName'),
        },
        {
            label: t('roster'),
            value: currentRoster.nameEs,
        },
    ];
    return (
        <Card className="shadow-sm">
            <ListGroup className="list-group-striped" variant="flush">
                {summaryItems.map((item) => (
                    <TeamListItem key={`summary-${item.label}`} item={item} />
                ))}
                {(editView || publicView) && (
                    <TeamLeaguePicker dispatch={dispatch} team={team} allowEdit={editView} />
                )}
                {createView && (
                    <TeamExclusiveRules
                        dispatch={dispatch}
                        currentRoster={currentRoster}
                        error={exclusiveRuleError}
                    />
                )}
            </ListGroup>
        </Card>
    );
};

export default TeamSummary;
