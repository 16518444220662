import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    Container,
    Row,
    Button,
    Form,
    InputGroup,
    Modal,
    OverlayTrigger,
    Tooltip,
    Col,
} from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { getValuesForPosition, checkDuplicatedName } from '../../../helpers';
import { LabbTable } from 'shared/components';
import { Roster, TeamReducerState, TeamDispatch } from 'types';
import './styles.scss';

type Props = {
    currentRoster: Roster;
    team: TeamReducerState['team'];
    editData: any;
    disableExclusives: boolean;
    dispatch: TeamDispatch;
    onHide: () => void;
    pickRandomName: (arg0, arg1) => string;
    positionalCount: any;
};

const AddPlayerModal = ({
    currentRoster,
    editData,
    dispatch,
    disableExclusives,
    team,
    onHide,
    pickRandomName,
    positionalCount,
}: Props) => {
    const { t } = useTranslation('teams');
    const [duplicatedName, setDuplicatedName] = useState(false);
    const [playerName, setPlayerName] = useState('');
    const [selectedPosition, setSelectedPosition] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(0);

    const renderPositionalName = (cell, row) => (
        <span>
            {cell}
            {row.exclusive ? '\u00A0♦' : '\u00A0'}
        </span>
    );

    const positionalColumns: ColumnDescription[] = [
        {
            dataField: 'position',
            text: t('position'),
            classes: 'font-weight-bold text-left',
            headerAlign: 'left',
            formatter: renderPositionalName,
        },
        { dataField: 'ma', text: t('MA') },
        { dataField: 'st', text: t('ST') },
        { dataField: 'ag', text: t('AG') },
        { dataField: 'pa', text: t('PA') },
        { dataField: 'av', text: t('AV') },
        { dataField: 'skills', text: t('skills'), classes: 'text-left', headerAlign: 'left' },
        { dataField: 'cost', text: t('cost') },
        { dataField: 'currentmax', text: t('current/max') },
    ];

    const positionalData = currentRoster.positionals.map(({ positional, max }) => {
        return {
            ...getValuesForPosition(positional.id, currentRoster),
            id: positional.id,
            position: positional.nameEs,
            exclusive: positional.exclusive,
            currentmax: `${positionalCount[positional.id].count}/${max}`,
            current: positionalCount[positional.id].count,
            skills: positional.skills?.map((item) => item.nameEs).join(', '),
            max,
        };
    });

    useEffect(() => {
        if (editData) {
            setPlayerName(editData.name);
            setSelectedPosition(editData.positionalId);
            setSelectedNumber(editData.number);
        }
    }, [editData]);

    useEffect(() => {
        if (checkDuplicatedName(playerName, team)) {
            setDuplicatedName(true);
        } else {
            setDuplicatedName(false);
        }
    }, [playerName]);

    const onSavePlayer = () => {
        if (!playerName || !selectedPosition || duplicatedName) {
            showError();
        } else {
            const { cost } = getValuesForPosition(selectedPosition, currentRoster);
            dispatch({
                type: 'addPlayer',
                payload: {
                    name: playerName,
                    number: selectedNumber,
                    positionalId: selectedPosition,
                    cost,
                },
            });
            onHide();
        }
    };

    const showError = () => {
        if (!playerName) toast.error(t('missingName'));
        if (duplicatedName) toast.error(t('duplicatedName'));
        if (!selectedPosition) toast.error(t('missingPosition'));
    };

    const renderRandomNameButton = () => {
        if (!selectedPosition) {
            return (
                <OverlayTrigger
                    trigger="click"
                    overlay={<Tooltip id="tooltip-disabled">{t('firstPickPosition')}</Tooltip>}
                >
                    <span className="d-inline-block">
                        <Button
                            variant="outline-primary"
                            disabled
                            style={{ pointerEvents: 'none' }}
                        >
                            {t('randomName')}
                        </Button>
                    </span>
                </OverlayTrigger>
            );
        }
        return (
            <Button
                variant="primary"
                disabled={!selectedPosition}
                onClick={() => setPlayerName(pickRandomName(selectedPosition, editData.number))}
            >
                {t('randomName')}
            </Button>
        );
    };

    const rowClasses = ({ current, exclusive, max }) => {
        const shouldDisable = current >= max || (exclusive && disableExclusives);
        const classes = shouldDisable ? 'text-muted' : 'cursor-pointer';
        return classes;
    };

    const onSelect = ({ max, exclusive, current, id }) => {
        const shouldDisable = current >= max || (exclusive && disableExclusives);
        if (shouldDisable) return false;
        setSelectedPosition(id);
        return true;
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('addPlayer', { count: editData?.number })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mb-4">
                    <Row>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text className="bg-primary text-light">
                                    {t('playerName')}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Player Name"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                            />
                        </InputGroup>
                    </Row>
                </Container>
                <LabbTable
                    keyField="id"
                    columns={positionalColumns}
                    data={positionalData}
                    rowClasses={rowClasses}
                    classes="text-right"
                    selectRow={{
                        mode: 'radio',
                        clickToSelect: true,
                        hideSelectColumn: true,
                        selected: [selectedPosition],
                        onSelect: onSelect,
                        classes: 'selected-row',
                    }}
                />
                {!!currentRoster.positionalsExclusive && (
                    <Row className="d-flex justify-content-end">
                        <Col md={4} className="text-right">
                            <span className="text-muted font-weight-bold">{t('exclusives')}</span>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                {renderRandomNameButton()}
                <Button variant="success" onClick={onSavePlayer}>
                    {t('save')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default AddPlayerModal;
