import React from 'react';
import { useTranslation } from 'react-i18next';
import { SPP_MUST_SPEND } from '../TeamAdmin/constants';

// TODO: add tooltip when player must spend spp

const ADVANCEMENT_TEXT = {
    0: 'rookie',
    1: 'experienced',
    2: 'veteran',
    3: 'emergingStar',
    4: 'star',
    5: 'superStar',
    6: 'legend',
};

const playerMustSpendSpp = (unspentSpp: number, skills): boolean => {
    const numberSkills = skills.filter((item) => item.type === 'acquired')?.length || 0;
    return unspentSpp >= SPP_MUST_SPEND[numberSkills];
};

type Props = {
    advancementCount: number;
    unspentSpp: number;
    skills?: string;
};

const AdvancementLabel = ({ advancementCount, unspentSpp, skills }: Props) => {
    if (!advancementCount && !unspentSpp) return null;
    const mustSpentSpp = playerMustSpendSpp(unspentSpp, skills);
    const { t } = useTranslation('teams');
    return (
        <div>
            <div className={mustSpentSpp ? 'font-weight-bold text-danger' : ''}>
                {t('Spp', { count: unspentSpp })}
            </div>
            <div className="text-muted">
                <span>{t(ADVANCEMENT_TEXT[advancementCount])}</span>{' '}
                <span style={{ fontSize: '0.6rem' }}>
                    {t('advancementCount', { count: advancementCount || 0 })}
                </span>
            </div>
        </div>
    );
};

export default AdvancementLabel;
