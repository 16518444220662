import React, { useState, useEffect } from 'react';
import { CoachRedux, Team } from 'labb/types';
import { fetchData, fetchMultiple } from 'labb/utils';
import Select from 'react-select';
import { Col, Container, Form, Row, ListGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'shared/components';
import { useProfileSchema } from './useSchema';
import { ErrorMessage, Formik } from 'formik';

type Props = {
    coach: CoachRedux;
    setSelected: any;
    onClose: () => void;
};

const CoachEdit = ({ coach, setSelected, onClose }: Props) => {
    const { t } = useTranslation('comissioner');
    const [teams, setTeams] = useState<Team[]>(null);
    const [formData, setFormData] = useState(null);
    const userProfileSchema = useProfileSchema(t);

    useEffect(() => {
        loadData(coach.id);
    }, [coach.id]);

    const loadData = async (id) => {
        const response = await fetchMultiple([`/team/${id}/list`, `/user/${id}`]);
        if (response.status === 'success') {
            const { teams } = response.data[0];
            const { user } = response.data[1];
            setTeams(teams);
            setFormData({
                code: user.code,
                email: user.email,
                nivel: user.nivel,
                usuario: user.usuario,
                passw: '',
            });
        } else {
            toast.error(t('selectTeamError', { msg: response.data.msg }));
        }
    };

    const onSubmit = async (values: any) => {
        const formPost = {
            ...values,
        };

        const response = await fetchData(`/user/comissioner/${coach.id}`, 'put', formPost);
        if (response.status === 'success') {
            toast.success(t('upateProfileSuccess'));
            onClose();
        } else {
            toast.error(t('updateProfileError', { msg: response.data.msg }));
        }
    };

    const handleDelete = async (e) => {
        if (confirm(t('sureDelete'))) {
            e.preventDefault();
            const response = await fetchData(`/user/${coach.id}`, 'delete');
            if (response.status === 'success') {
                toast.success(t('userDeleteSuccess'));
                onClose();
            } else {
                toast.error(t('userDeleteError', { msg: response.data.msg }));
            }
        }
    };

    if (!formData) {
        return <LoadingSpinner />;
    }

    const levelOptions = [
        { value: 'c', label: t('comissioner') },
        { value: 'e', label: t('coach') },
    ];

    return (
        <Container>
            <Container>
                <Row>
                    <Col>
                        <h4>{t('coachDetails')}</h4>
                    </Col>
                </Row>
                <Formik
                    initialValues={formData}
                    onSubmit={onSubmit}
                    validationSchema={userProfileSchema}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, handleChange, isSubmitting, values, touched, errors }) => (
                        <Form>
                            <Container
                                className="border border-primary p-4"
                                style={{ backgroundColor: '#f7f7f9' }}
                            >
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('name')}
                                        </Form.Label>
                                        <Form.Control
                                            name="usuario"
                                            type="text"
                                            autoComplete="off"
                                            value={values.usuario}
                                            onChange={handleChange}
                                            isInvalid={touched.usuario && !!errors.usuario}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <ErrorMessage name="usuario" />
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('password')}
                                        </Form.Label>
                                        <Form.Control
                                            name="passw"
                                            type="password"
                                            autoComplete="off"
                                            value={values.passw}
                                            onChange={handleChange}
                                            isInvalid={touched.passw && !!errors.passw}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <ErrorMessage name="passw" />
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('repeatPassword')}
                                        </Form.Label>
                                        <Form.Control
                                            name="passw2"
                                            type="password"
                                            autoComplete="off"
                                            value={values.passw2}
                                            onChange={handleChange}
                                            isInvalid={touched.passw2 && !!errors.passw2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <ErrorMessage name="passw2" />
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('email')}
                                        </Form.Label>
                                        <Form.Control
                                            name="email"
                                            type="text"
                                            autoComplete="off"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <ErrorMessage name="email" />
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="font-weight-bold">
                                            {t('level')}
                                        </Form.Label>
                                        <Select
                                            options={levelOptions}
                                            name="nivel"
                                            onChange={(option) => {
                                                setFormData({
                                                    ...formData,
                                                    nivel: option.value,
                                                });
                                            }}
                                            value={levelOptions.find(
                                                (option) => formData.nivel === option.value
                                            )}
                                        />
                                        {errors.nivel && touched.nivel && (
                                            <div className="d-block invalid-feedback">
                                                <ErrorMessage name="nivel" />
                                            </div>
                                        )}
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="p-3">
                                <Row className="d-flex justify-content-end">
                                    <Button
                                        className="btn-block my-5 col-3 ml-auto"
                                        variant="primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onClose();
                                        }}
                                    >
                                        {t('back')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        className="btn-block my-5 col-3 ml-auto"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            handleSubmit(values);
                                        }}
                                    >
                                        {t('update')}
                                    </Button>
                                    <Button
                                        variant="danger"
                                        className="btn-block my-5 col-3 ml-auto"
                                        onClick={handleDelete}
                                    >
                                        {t('deleteUser')}
                                    </Button>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </Container>
            {teams.length > 0 && (
                <Container className="px-0 pt-4">
                    <Row>
                        <Col>
                            <h4>{t('teamList')}</h4>
                        </Col>
                    </Row>
                    <ListGroup className="comissioner-list-group">
                        {teams?.map((team) => (
                            <ListGroup.Item
                                className="d-flex justify-content-between"
                                action
                                onClick={() => setSelected({ ...team, type: 'team' })}
                                key={`${team.id}`}
                            >
                                <div>
                                    <div>{team.name}</div>
                                </div>
                                <div>
                                    <span className="text-muted text-small">id: {team.id}</span>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Container>
            )}
        </Container>
    );
};

export default CoachEdit;
